import React, { FormEvent } from 'react';
import {
  ReactFacebookLoginInfo,
  ReactFacebookFailureResponse,
} from 'react-facebook-login';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Header, Footer, Modal } from 'src/components';
import { FormData, FormErrors, OnChangeFormInput } from 'src/hooks';
import {
  CallChangePasswordContent,
  ChangePasswordContent,
  ConfirmEmailForgotPasswordContent,
  ForgotPasswordContent,
  LoginContent,
  RegisterConfirmContent,
  RegisterContent,
  TermsContent,
} from '../../../components';
import { ShouldShowModal, ShouldShowModalProps } from '../../../types';
import ClientNotification from 'src/model/ClientNotification';
import { LogoutContent } from '../../../components/LogoutContent';
import { CheckEmailContent } from '../../../components/CheckEmailContent';
import { ChangeClientDataContent } from '../../../components/ChangeClientDataContent';

interface StateProps {
  state: boolean;
  homepage: boolean;
  notifications: ClientNotification[];
  visibleModal: boolean;
  titleModal: string | React.ReactNode;
  shouldShowModal: ShouldShowModal;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  formDataLogin: FormData;
  formErrorsLogin: FormErrors;
  enableLoginPassword: boolean;
  onChangeFormInputLogin: OnChangeFormInput;
  onChangeCEP: (value: string) => void;
  document: string;
  formDataChangeClientData: FormData;
  formErrorsChangeClientData: FormErrors;
  onChangeFormInputChangeClientData: OnChangeFormInput;
  formDataRegister: FormData;
  formErrorsRegister: FormErrors;
  onChangeFormInputRegister: OnChangeFormInput;
  formDataForgotPassword: FormData;
  formErrorsForgotPassword: FormErrors;
  onChangeFormInputForgotPassword: OnChangeFormInput;
  formDataRegisterConfirm: FormData;
  formErrorsRegisterConfirm: FormErrors;
  onChangeFormInputRegisterConfirm: OnChangeFormInput;
  formDataChangePassword: FormData;
  formErrorsChangePassword: FormErrors;
  onChangeFormInputChangePassword: OnChangeFormInput;
  emailConfirmation: string;
  email: string;
  name: string;
  children: React.ReactNode;
}

interface DispathProps {
  onGoTo: (href: string) => void;
  handleOnSignIn: () => void;
  handleOnSignUp: () => void;
  handleOnLogout: () => void;
  onSubmitLogout: () => void;
  onCloseModal: () => void;
  handleOnCart: () => void;
  onToggleModal: () => void;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  onCheckCPF: () => void;
  onSubmitLogin: (e: FormEvent) => void;
  onGoToLoginGoogle: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onSubmitGoogle: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGoogleError: (error: any) => void;
  onGoToLoginFacebook: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onSubmitFacebook: (
    userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => void;
  onGoToLoginAppleId: () => void;
  onSubmitRegister: (e: FormEvent) => void;
  onGoToRegisterAppleId: () => void;
  onSubmitForgotPassword: (e: FormEvent) => void;
  onConfirmEmail: () => void;
  onChangePersonData: () => void;
  onSubmitChangeClientData: (e: FormEvent) => void;
  onSubmitChangePassword: (e: FormEvent) => void;
  onSubmitRegisterConfirm: (e: FormEvent) => void;
  onSubmitResendCode: (e: FormEvent) => void;
  onSubmitResendEmail: (e: FormEvent) => void;
}

type AuthWrapperContainerProps = StateProps & DispathProps;

export const AuthWrapperContainer = ({
  state,
  homepage,
  notifications,
  onGoTo,
  handleOnSignIn,
  handleOnSignUp,
  handleOnLogout,
  onSubmitLogout,
  onCloseModal,
  handleOnCart,
  visibleModal,
  onToggleModal,
  titleModal,
  onShouldShowModal,
  shouldShowModal,
  shouldShowPasswordToText,
  shouldShowPasswordToText2,
  formDataLogin,
  formErrorsLogin,
  onChangeFormInputLogin,
  enableLoginPassword,
  onCheckCPF,
  onSubmitLogin,
  onTogglePasswordToText,
  onTogglePasswordToText2,
  onGoToLoginGoogle,
  onSubmitGoogle,
  onGoogleError,
  onGoToLoginFacebook,
  onSubmitFacebook,
  onGoToLoginAppleId,
  formDataRegister,
  formErrorsRegister,
  onChangeFormInputRegister,
  onChangeCEP,
  onSubmitRegister,
  onGoToRegisterAppleId,
  formDataForgotPassword,
  formErrorsForgotPassword,
  onChangeFormInputForgotPassword,
  onSubmitForgotPassword,
  formDataRegisterConfirm,
  formErrorsRegisterConfirm,
  onChangeFormInputRegisterConfirm,
  onSubmitRegisterConfirm,
  onConfirmEmail,
  onChangePersonData,
  onSubmitChangeClientData,
  document,
  formDataChangeClientData,
  onChangeFormInputChangeClientData,
  formErrorsChangeClientData,
  formDataChangePassword,
  formErrorsChangePassword,
  onChangeFormInputChangePassword,
  onSubmitChangePassword,
  emailConfirmation,
  name,
  email,
  children,
  onSubmitResendCode,
  onSubmitResendEmail,
}: AuthWrapperContainerProps) => {
  return (
    <>
      <div className="wrapper w-full">
        <Header
          homepage={homepage}
          notifications={notifications}
          goTo={onGoTo}
          handleOnSignIn={handleOnSignIn}
          handleOnSignUp={handleOnSignUp}
          handleOnCart={handleOnCart}
          handleOnLogout={handleOnLogout}
        />
        <main className="overflow-hidden">{children}</main>
        <Footer
          handleOnSignIn={handleOnSignIn}
          handleOnSignUp={handleOnSignUp}
          goTo={onGoTo}
        />
      </div>
      <Modal
        visible={visibleModal}
        title={titleModal}
        onSetVisible={onToggleModal}
        actions={[]}
      >
        {
          {
            [ShouldShowModal.LOGIN]: (
              <LoginContent
                state={state}
                shouldShowPasswordToText={shouldShowPasswordToText}
                shouldShowPasswordToText2={shouldShowPasswordToText2}
                formData={formDataLogin}
                formErrors={formErrorsLogin}
                onChangeFormInput={onChangeFormInputLogin}
                enableLoginPassword={enableLoginPassword}
                onCheckCPF={onCheckCPF}
                onSubmit={onSubmitLogin}
                onTogglePasswordToText={onTogglePasswordToText}
                onGoToGoogle={onGoToLoginGoogle}
                onSubmitGoogle={onSubmitGoogle}
                onGoogleError={onGoogleError}
                onGoToFacebook={onGoToLoginFacebook}
                onSubmitFacebook={onSubmitFacebook}
                onGoToAppleId={onGoToLoginAppleId}
                onShouldShowModal={onShouldShowModal}
              />
            ),
            [ShouldShowModal.REGISTER]: (
              <RegisterContent
                state={state}
                shouldShowPasswordToText={shouldShowPasswordToText}
                shouldShowPasswordToText2={shouldShowPasswordToText2}
                formData={formDataRegister}
                formErrors={formErrorsRegister}
                onChangeFormInput={onChangeFormInputRegister}
                onChangeCEP={onChangeCEP}
                onSubmit={onSubmitRegister}
                onTogglePasswordToText={onTogglePasswordToText}
                onTogglePasswordToText2={onTogglePasswordToText2}
                onGoToGoogle={onGoToLoginGoogle}
                onSubmitGoogle={onSubmitGoogle}
                onGoogleError={onGoogleError}
                onGoToFacebook={onGoToLoginFacebook}
                onSubmitFacebook={onSubmitFacebook}
                onGoToAppleId={onGoToRegisterAppleId}
                onShouldShowModal={onShouldShowModal}
              />
            ),
            [ShouldShowModal.TERMS]: <TermsContent />,
            [ShouldShowModal.FORGOT_PASSWORD]: (
              <ForgotPasswordContent
                state={state}
                formData={formDataForgotPassword}
                formErrors={formErrorsForgotPassword}
                onChangeFormInput={onChangeFormInputForgotPassword}
                onSubmit={onSubmitForgotPassword}
              />
            ),
            [ShouldShowModal.CHECk_EMAIL]: (
              <CheckEmailContent
                email={email}
                onConfirmEmail={onConfirmEmail}
                onChangePersonData={onChangePersonData}
              />
            ),
            [ShouldShowModal.CONFIRM_EMAIL_FORGOT_PASSWORD]: (
              <ConfirmEmailForgotPasswordContent
                email={email}
                onToggle={onToggleModal}
                onSubmitResendEmail={onSubmitResendEmail}
              />
            ),
            [ShouldShowModal.CHANGE_CLIENT_DATA]: (
              <ChangeClientDataContent
                onSubmit={onSubmitChangeClientData}
                document={document}
                formData={formDataChangeClientData}
                onChange={onChangeFormInputChangeClientData}
                formErrors={formErrorsChangeClientData}
                state={state}
              />
            ),
            [ShouldShowModal.CALL_CHANGE_PASSWORD]: (
              <CallChangePasswordContent
                name={name}
                onShouldShowModal={onShouldShowModal}
              />
            ),
            [ShouldShowModal.CHANGE_PASSWORD]: (
              <ChangePasswordContent
                state={state}
                shouldShowPasswordToText={shouldShowPasswordToText}
                formData={formDataChangePassword}
                formErrors={formErrorsChangePassword}
                onChangeFormInput={onChangeFormInputChangePassword}
                onSubmit={onSubmitChangePassword}
                onTogglePasswordToText={onTogglePasswordToText}
              />
            ),
            [ShouldShowModal.CONFIRM_REGISTER]: (
              <RegisterConfirmContent
                state={state}
                formData={formDataRegisterConfirm}
                formErrors={formErrorsRegisterConfirm}
                onChangeFormInput={onChangeFormInputRegisterConfirm}
                onSubmit={onSubmitRegisterConfirm}
                emailConfirmation={emailConfirmation}
                onSubmitResendCode={onSubmitResendCode}
              />
            ),
            [ShouldShowModal.LOGOUT]: (
              <LogoutContent
                onCancelConfirm={onCloseModal}
                onOkConfirm={onSubmitLogout}
              />
            ),
          }[shouldShowModal]
        }
      </Modal>
    </>
  );
};
