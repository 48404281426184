import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';

export const PoliticsContainer = () => {
  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full container mx-auto pt-[54px] pb-[100px] font-dmsans">
        <div>
          <h1 className="text-primary-500 text-[37px] font-bold mb-[70px]">
            Políticas da Empresa
          </h1>
          <h2 className="text-[28px] font-normal mb-[20px] text-neutro-b-400">
            Evento
          </h2>
          <p className="text-neutro-w-600 mb-[20px]">
            O produtor do evento é o único responsável pela realização,
            cancelamento ou adiamento do evento, bem como a restituição do valor
            de face do ingresso.
          </p>
          <p className="text-neutro-w-600 mb-[20px]">
            Em casos de cancelamento ou troca de data e/ou horário do EVENTO é
            direito do cliente solicitar a devolução do valor referente aos
            ingressos do evento em questão.
          </p>
          <p className="text-neutro-w-600 mb-[20px]">
            Não serão devolvidos os valores referentes à taxa de serviço
            (conveniência).
          </p>
          <p className="text-neutro-w-600 mb-[70px]">
            Os clientes devem seguir orientações específicas de cada evento, de
            responsabilidade do produtor.
          </p>

          <h2 className="text-[28px] font-normal mb-[20px] text-neutro-b-400">
            Política de Cancelamento de Ingressos
          </h2>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            1. Direito de Cancelamento
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-10">
              Em conformidade com o Artigo 49 do Código de Defesa do Consumidor
              (CDC), garantimos o direito ao cancelamento de pedidos e o
              subsequente reembolso dos valores pagos para ingressos adquiridos
              através da nossa plataforma online. O cancelamento pode ser
              solicitado dentro do prazo de sete dias, contados a partir da data
              da aquisição do ingresso. Este direito é garantido aos
              consumidores independentemente do motivo do cancelamento,
              assegurando uma compra segura e transparente.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            2. Eventos Próximos
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-10">
              Se o evento estiver marcado para ocorrer em menos de sete dias a
              partir da data da compra, o cancelamento poderá ser solicitado com
              até 48 horas de antecedência à data do evento. Cancelamentos no
              dia do evento não serão aceitos devido à impossibilidade de
              revenda dos ingressos. Recomendamos que os clientes fiquem atentos
              às datas e prazos para evitar transtornos.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            3. Procedimento de Cancelamento
          </h5>
          <p className="text-neutro-w-600 mb-[20px]">
            Para solicitar o cancelamento do pedido, siga os seguintes passos:
          </p>

          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Abra nosso aplicativo: Disponível na loja de aplicativos do seu
              smartphone.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Acesse a área de ingressos: Localize a seção específica para os
              ingressos adquiridos.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Acesse Ver Detalhes: Selecione a opção de cancelamento e preencha
              as informações necessárias.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-10">
              Confirmação: Aguarde a confirmação do recebimento da solicitação.
              Todas as solicitações serão analisadas, processadas e respondidas
              dentro do prazo de até três dias úteis, desde que o evento ainda
              não tenha ocorrido.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            4. Prazo de Reembolso
          </h5>
          <p className="text-neutro-w-600 mb-[20px]">
            Os prazos de reembolso variam conforme o método de pagamento
            utilizado:
          </p>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Cartão de Crédito: Os reembolsos serão realizados entre 30 e 40
              dias após a confirmação do cancelamento. A transação será
              estornada pelo banco emissor do cartão, e os valores serão
              creditados na fatura atual ou na subsequente, dependendo do
              momento do cancelamento em relação ao fechamento da fatura.
              Observe que o prazo para efetivação do cancelamento pode variar de
              acordo com o banco emissor do cartão.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              PIX: Os reembolsos serão efetuados entre 20 e 30 dias após a
              confirmação do cancelamento. O valor será creditado diretamente na
              conta bancária utilizada para o pagamento.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            5. Considerações Finais
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Confirmação de Cancelamento: Você receberá um e-mail de
              confirmação assim que o pedido de cancelamento for aprovado.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Informações Adicionais: Certifique-se de que todas as informações
              fornecidas no momento da solicitação de cancelamento estão
              corretas para evitar atrasos no processamento do reembolso.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Política de Não Comparecimento: Não comparecimento ao evento sem a
              solicitação prévia de cancelamento dentro dos prazos especificados
              não dará direito a reembolso.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Contato para Suporte: Caso encontre dificuldades ou tenha dúvidas
              sobre o processo, entre em contato com nosso serviço de
              atendimento ao cliente através do e-mail
              suporte@bancadoingresso.com.br ou pelo telefone 17 99783-1112,
              nosso prazo de resposta é de até 48 horas após o chamado.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            Agradecemos a compreensão e estamos à disposição para qualquer
            dúvida através do nosso serviço de atendimento ao cliente. Seu
            feedback é importante para nós e ajuda a melhorar nossos serviços.
          </h5>

          <h2 className="text-[28px] font-normal mb-[20px] text-neutro-b-400">
            Transferência de Ingressos
          </h2>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            A transferência de ingressos está sujeita às seguintes condições:
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              O favorecido deve possuir uma conta na Banca do Ingresso já
              validada anteriormente para receber o ingresso transferido.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Os ingressos só podem ser transferidos até 3 horas antes do início
              do evento.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Após a transferência, o ingresso estará disponível apenas na conta
              do favorecido.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-10">
              Cada ingresso só pode ser transferido uma única vez.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            Procedimento de Transferência:
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Para transferir um ingresso, o cliente deve acessar sua conta na
              Banca do Ingresso e selecionar a opção de transferência de
              ingressos na aba ingresso do aplicativo.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              O cliente deverá inserir o CPF da pessoa para quem deseja
              transferir o ingresso.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Após a confirmação da transferência, o ingresso será
              automaticamente removido da conta do cliente disponibilizado na
              conta do favorecido.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-10">
              A transferência só será concluída se o favorecido possuir uma
              conta válida na Banca do Ingresso.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            Responsabilidade:
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              A Banca do Ingresso não se responsabiliza por eventuais problemas
              decorrentes da transferência de ingressos, incluindo erros no
              preenchimento dos dados do favorecido.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-10">
              É de responsabilidade do cliente garantir que a transferência seja
              realizada dentro do prazo estabelecido e seguindo corretamente o
              procedimento descrito.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            Cancelamento de Transferência:
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-10">
              Não é permitido o cancelamento da transferência de ingressos após
              sua conclusão.
            </li>
          </ul>
          <h5 className="text-[18px] text-neutro-b-400 mb-[20px]">
            Disposições Gerais
          </h5>
          <ul className="list-disc pl-6">
            <li className="text-[16px] text-neutro-w-600 mb-2">
              Todas as transferências de ingressos estão sujeitas à
              disponibilidade e capacidade do evento.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              O cliente deve estar ciente de que, uma vez transferido, o
              ingresso não poderá ser revertido para sua conta original.
            </li>
            <li className="text-[16px] text-neutro-w-600 mb-2">
              A Banca do Ingresso reserva-se o direito de revisar e modificar
              estas políticas de transferência de ingressos a qualquer momento,
              conforme necessário.
            </li>
          </ul>
        </div>
      </div>
    </AuthWrapper>
  );
};
