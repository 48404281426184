import React, { ReactNode, useState } from 'react';
import { IconWarning } from 'src/assets/icons/IconWarning';
import { Button } from 'src/components';
import { CardValidationResponse } from 'src/model/CardValidationResponse';
import logoAmex from 'src/assets/images/logo_amex.png';
import logoDinersClub from 'src/assets/images/logo_diners_club.png';
import logoDiscover from 'src/assets/images/logo_discover.png';
import logoJcb from 'src/assets/images/logo_jcb.png';
import logoMaster from 'src/assets/images/logo_mastercard.png';
import logoVisa from 'src/assets/images/logo_visa.png';
import logoElo from 'src/assets/images/logo_elo.png';
import CardType from 'src/model/CardType';
import CardValidationResponseStatus from 'src/model/CardValidationResponseStatus';
import { IconError } from 'src/assets/icons';
import exemploFatura from './../../../assets/images/exemploFatura.jpg';

interface StateProps {
  cardValidationResponses: CardValidationResponse[];
}

interface DispatchProps {
  onChangeInputValidate: (index: number, value: string) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

type Props = StateProps & DispatchProps;

export const ValidateCreditCardContent: React.FC<Props> = ({
  cardValidationResponses,
  onChangeInputValidate,
  onConfirm,
  onCancel,
}): JSX.Element => {
  const [currentScreen, setCurrentScreen] = useState<number>(1);

  const getCardLogo = (cardType: CardType): string => {
    let logo = logoMaster;
    switch (cardType) {
      case CardType.MASTER_CARD:
        logo = logoMaster;
        break;
      case CardType.VISA:
        logo = logoVisa;
        break;
      case CardType.AMERICAN_EXPRESS:
        logo = logoAmex;
        break;
      case CardType.DISCOVER:
        logo = logoDiscover;
        break;
      case CardType.DINERS_CLUB:
        logo = logoDinersClub;
        break;
      case CardType.JCB:
        logo = logoJcb;
        break;
      case CardType.ELO:
        logo = logoElo;
        break;
      default:
        break;
    }
    return logo;
  };

  const showConfirm = (): boolean => {
    let show = false;
    cardValidationResponses.forEach((data) => {
      if (
        data.status === CardValidationResponseStatus.APPROVED ||
        data.status === CardValidationResponseStatus.AWAITING_VALIDATION
      ) {
        show = true;
      }
    });
    return show;
  };

  const renderError = (messages: string[]): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconError height="30px" className="self-center text-[#E64F49]" />
      {messages && messages.length > 0 && (
        <div className="ml-[20px] text-left text-[#E64F49]">
          {messages.map((message) => (
            <p key={`messageError-${message}`} className="text-sm">
              {message}
            </p>
          ))}
        </div>
      )}
    </div>
  );

  const renderNotBelong = (): ReactNode => (
    <>
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <IconError height="30px" className="self-center text-[#E64F49]" />
        <div className="ml-[20px] text-left text-[#E64F49]">
          <p className="text-sm">Esse cartão não está vinculado a você</p>
        </div>
      </div>
      <Button
        type="button"
        title="Cancelar"
        buttonStyle="outlined"
        size="lg"
        className="w-full mb-[25px] mt-[25px]"
        onClick={onCancel}
      />
    </>
  );

  const renderAwaitingValidation = (): ReactNode => (
    <>
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <IconWarning height="30px" className="self-center text-[#FFE249]" />
        <div className="ml-[20px] text-left">
          <p className="text-sm">Existe processo de validação pendente</p>
        </div>
      </div>
      <Button
        type="button"
        title="Cancelar"
        buttonStyle="outlined"
        size="lg"
        className="w-full mb-[25px] mt-[25px]"
        onClick={onCancel}
      />
    </>
  );

  const renderValidated = (): ReactNode => (
    <>
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <IconWarning height="30px" className="self-center text-[#FFE249]" />
        <div className="ml-[20px] text-left">
          <p className="text-sm">Este cartão já se encontra validado</p>
        </div>
      </div>
      <Button
        type="button"
        title="Cancelar"
        buttonStyle="outlined"
        size="lg"
        className="w-full mb-[25px] mt-[25px]"
        onClick={onCancel}
      />
    </>
  );

  // const renderApproved = (
  //   index: number,
  //   data: CardValidationResponse
  // ): ReactNode => (
  //   <div
  //     key={`Check-Validating-credit-card-${data.card.id}`}
  //     className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
  //   >
  //     <div className={`relative`}>
  //       <input
  //         aria-invalid={'false'}
  //         value={data.value as string}
  //         placeholder="Digite aqui R$ 0,00 "
  //         maxLength={8}
  //         onChange={(e) => onChangeInputValidate(index, e.target.value)}
  //         className={`placeholder-gray-900 relative appearance-none text-center h-[40px] border border-[red] rounded w-[200px] py-2 px-3 text-input text-base leading-tight focus:outline-none focus:shadow-outline}`}
  //       />
  //       {data.errorInput && (
  //         <p className="text-red-500 text-xs">{data.errorInput}</p>
  //       )}
  //     </div>
  //   </div>
  // );

  const renderApproved = (
    index: number,
    data: CardValidationResponse,
    screenNumber: number,
    goToNextScreen: () => void
  ): React.ReactNode => {
    const renderScreen = () => {
      switch (screenNumber) {
        case 1:
          return (
            <div key={`Screen1-${data.card.id}`}>
              <div>
                <h4 className="font-dmsans text-neutro-b-500 mb-[10px] text-[19px] sm:text-[25px]">
                  Vamos iniciar a validação do seu cartão.
                </h4>
                <p className="font-dmsans  font-normal text-neutro-w-600 mb-[10px] text-[20px] sm:text-[20px]">
                  <b className="font-dmsans text-red-500">
                    Você precisará ter acesso a sua fatura.
                  </b>
                </p>
                <p className="font-dmsans  font-normal text-neutro-w-600 mb-[10px] text-[18px] sm:text-[20px]">
                  <b className="font-dmsans text-neutro-b-500">
                    Seguindo cuidadosamente cada passo.
                  </b>
                </p>
              </div>
              <div className={`mt-[50px]`}>
                <Button
                  type="button"
                  title="Proximo"
                  buttonStyle="primary"
                  size="lg"
                  className="w-full mb-[25px]"
                  onClick={goToNextScreen}
                />
              </div>
            </div>
          );
        case 2:
          return (
            <div key={`Screen1-${data.card.id}`}>
              <div>
                <h4 className="font-dmsans text-neutro-b-500 mb-[5px] text-[19px] sm:text-[25px]">
                  Acesse sua fatura e procure por:{' '}
                  <b className="font-dmsans text-red-500">
                    Pag*Bancadoingresso R$ XX,xx.{' '}
                  </b>
                </h4>
                <div>
                  <p className="font-dmsans font-normal text-neutro-w-600 mb-[5px] text-[20px] sm:text-[20px]">
                    <small className="font-dmsans text-neutro-b-500">
                      Imagem inlustrativa.
                    </small>
                  </p>
                  {/* <p className="font-dmsans font-normal text-neutro-w-600 mb-[5px] text-[20px] sm:text-[20px]">
                    <small className="font-dmsans text-neutro-b-500">
                      Esse valor pode variar e conter centavos.
                    </small>
                  </p> */}
                </div>
                <div className="flex justify-center items-center">
                  <img
                    src={exemploFatura}
                    alt="Exemplo de Fatura"
                    className="max-w-full w-[400px] h-auto"
                  />
                </div>
                <p className="font-dmsans  font-normal text-neutro-w-600 mb-[5px] text-[18px] sm:text-[20px]">
                  <b className="font-dmsans text-neutro-b-500">
                    Grave esse valor cobrado para o proximo passo .
                  </b>
                </p>
              </div>
              <div className={`mt-[20px]`}>
                <Button
                  type="button"
                  title="Proximo"
                  buttonStyle="primary"
                  size="lg"
                  className="w-full mb-[25px]"
                  onClick={goToNextScreen}
                />
              </div>
            </div>
          );
        case 3:
          return (
            <>
              <h4 className="font-dmsans text-neutro-b-400 mb-[10px] text-[19px] sm:text-[25px]">
                <b>
                  Insira o valor encontrado na sua fatura no campo em vermelho
                  abaixo.
                </b>
              </h4>
              <div
                key={`Check-Validating-credit-card-${data.card.id}`}
                className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
              >
                <div className={`relative`}>
                  <input
                    aria-invalid={'false'}
                    value={data.value as string}
                    placeholder="Digite aqui R$ 0,00 "
                    maxLength={8}
                    onChange={(e) =>
                      onChangeInputValidate(index, e.target.value)
                    }
                    className={`placeholder-gray-900 relative appearance-none text-center h-[40px] border border-[red] rounded w-[200px] py-2 px-3 text-input text-base leading-tight focus:outline-none focus:shadow-outline}`}
                  />
                  {data.errorInput && (
                    <p className="text-red-500 text-xs">{data.errorInput}</p>
                  )}
                </div>
              </div>
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 mt-[5px]">
                <img
                  style={{ maxWidth: '36px' }}
                  src={getCardLogo(data.card.cardType)}
                  alt="logo"
                  className="mr-[30px]"
                />
                <label className="ml-[20px]">{data.card.cardNumber}</label>
              </div>
              <p className="font-dmsans text-lg font-normal text-red-600 mt-[30px]">
                Obs : esse valor será estornado logo após, servirá somente como
                validação
              </p>
              <div
                className={`grid ${
                  showConfirm() ? 'grid-cols-2' : 'grid-cols-1'
                } gap-5 mt-[50px]`}
              >
                <Button
                  type="button"
                  title="Cancelar"
                  buttonStyle="outlined"
                  size="lg"
                  className="w-full mb-[25px]"
                  onClick={onCancel}
                />
                {showConfirm() && (
                  <Button
                    type="button"
                    title="Validar"
                    buttonStyle="primary"
                    size="lg"
                    className="w-full mb-[25px]"
                    onClick={onConfirm}
                  />
                )}
              </div>
            </>
          );
        default:
          return null;
      }
    };

    return renderScreen();
  };

  const renderAwaitingPayment = (): ReactNode => (
    <>
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <IconWarning height="30px" className="self-center text-[#FFE249]" />
        <div className="ml-[20px] text-left">
          <p className="text-sm">Aguardando análise do cartão de crédito</p>
        </div>
      </div>
      <Button
        type="button"
        title="Cancelar"
        buttonStyle="outlined"
        size="lg"
        className="w-full mb-[25px] mt-[25px]"
        onClick={onCancel}
      />
    </>
  );

  const renderDisapproved = (): ReactNode => (
    <>
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <IconError height="30px" className="self-center text-[#E64F49]" />
        <div className="ml-[20px] text-left text-[#E64F49]">
          <p className="text-sm">O banco não autorizou o pedido</p>
        </div>
      </div>
      <Button
        type="button"
        title="Cancelar"
        buttonStyle="outlined"
        size="lg"
        className="w-full mb-[25px] mt-[25px]"
        onClick={onCancel}
      />
    </>
  );

  const goToNextScreen = () => {
    setCurrentScreen(currentScreen + 1);
  };

  return (
    <div className="w-full mx-auto text-center">
      <div className="flex flex-col justify-center">
        <IconWarning
          height="50px"
          className="self-center text-[red] mb-[15px]"
        />
        <h4 className="font-dmsans font-bold text-neutro-b-400 text-[28px] mb-[15px]">
          Validação do cartão
        </h4>

        {cardValidationResponses &&
          cardValidationResponses.length > 0 &&
          cardValidationResponses.map((data, index) => (
            <React.Fragment
              key={`Check-Validating-credit-card-${data.card.id}`}
            >
              {data.status === CardValidationResponseStatus.NOT_BELONG &&
                renderNotBelong()}
              {data.status ===
                CardValidationResponseStatus.AWAITING_VALIDATION &&
                renderApproved(index, data, currentScreen, goToNextScreen)}
              {data.status === CardValidationResponseStatus.VALIDATED &&
                renderValidated()}
              {data.status === CardValidationResponseStatus.APPROVED &&
                renderApproved(index, data, currentScreen, goToNextScreen)}
              {data.status === CardValidationResponseStatus.AWAITING_PAYMENT &&
                renderAwaitingPayment()}
              {data.status === CardValidationResponseStatus.DISAPPROVED &&
                renderDisapproved()}
              {data.status === CardValidationResponseStatus.ERROR &&
                renderError(data.messages as string[])}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};
