import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { CartEmpty } from 'src/assets/icons';
import EventSite from 'src/model/EventSite';
import { useEventStore } from 'src/redux/event/eventSlice';

export const CartEmptyContent = () => {
  const { events } = useSelector(useEventStore);
  const allEvents: EventSite[] = [];
  if (events && events.length > 0) {
    events.forEach((eventCagetorySite) => {
      eventCagetorySite.events.forEach((eventSite) => {
        allEvents.push(eventSite);
      });
    });
  }

  const sortedList = allEvents.sort((a, b) => {
    if (a.numberAccesses > b.numberAccesses) {
      return -1;
    } else if (a.numberAccesses < b.numberAccesses) {
      return 1;
    } else {
      return 0;
    }
  });

  const list: EventSite[] = [];
  for (let i = 0; i < sortedList.length; i++) {
    if (i < 4) {
      list.push(sortedList[i]);
    } else {
      break;
    }
  }

  return (
    <>
      <div className="cart-empty flex flex-col items-center border-b border-[#EEEEEE] pt-[20px] pb-[20px] px-[20px]">
        <img src={CartEmpty} alt="Carrinho vazio" className="mb-[20px] h-20" />
        <h4 className="text-[21px] font-bold leading-7 mb-1">
          Seu carrinho ainda está vazio ):
        </h4>
        <p className="text-base font-normal leading-5 text-neutro-b-400">
          Aproveite para olhar os eventos que você pode gostar
        </p>
      </div>
      <div className="event-suggestion pt-[30px]">
        <h5 className="text-base font-normal leading-5 text-neutro-b-400 pb-[30px]">
          Eventos que você pode gostar
        </h5>
        <div className="grid md:grid-cols-2 md:grid-rows-2 px-[20px] md:px-[85px] pb-[100px]">
          {list.map((event) => {
            const partes = event.name.split(' ');
            const partesValidas = partes.filter((parte) => parte.length > 1);
            const novoNome = partesValidas.join('-');

            return (
              <div
                key={event.id}
                className="w-full md:w-[190px] even:justify-self-end mb-[40px]"
              >
                <a
                  href={`/${novoNome}/${event.city}-${event.state}`}
                  className=""
                >
                  <div className="relative">
                    <img
                      className="w-full md:w-[160px] h-[160px] md:h-[96px] object-cover rounded-md"
                      src={event.imageUrl}
                      alt="Event"
                    />
                    <div className="absolute rounded-md top-0 w-full md:w-[160px] h-full bg-card-default hover:bg-card-hover transition-all duration-200 ease-in-out"></div>
                  </div>
                  <div className="mt-[15px] text-left">
                    <h4 className="text-neutral-b-400 font-dmsans font-medium text-base">
                      {event.name}
                    </h4>
                    <div className="flex items-center text-xs mt-[12px]">
                      <span className="font-dmsans font-normal text-neutro-w-600 uppercase">
                        {dayjs(event.startDate)
                          .locale('pt-br')
                          .format('DD MMM')}{' '}
                        {'>'}{' '}
                        {dayjs(event.endDate).locale('pt-br').format('DD MMM')}
                      </span>
                      <span className="w-[6px] h-[6px] mx-2 bg-gray-300 rounded-full"></span>
                      <span className="font-dmsans font-normal text-neutro-w-600">
                        {event.city}/{event.state}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
