import React from 'react';
import { EventDate, EventDateSectionSite, EventGroup } from '../screens/Event';
import Section from 'src/model/Section';
import { Area } from 'src/assets/icons';
import { SliderProductArea } from './SliderTicketProduct';
import { ProductIncrement } from './ProductIncrement';
import { Switch } from 'src/components';
import SliderGroup from './SliderGroup';
import { ShouldShowModalProps } from '../types';

interface StateProps {
  event?: EventDate;
  sectionId: string;
  showProducts: boolean;
  groupId: string;
  showMap: boolean;
}

interface DispathProps {
  onSelectSection: (section: Section) => void;
  onIncrementTicket: (index: number) => void;
  onDecrementTicket: (index: number) => void;
  onShowModal: (props: ShouldShowModalProps) => void;
  getEventKey: (sectionId: string, ticketId: string) => string;
  onCheckShowProducts: (checked: boolean) => void;
  onIncrementProduct: (index: number) => void;
  onDecrementProduct: (index: number) => void;
  onSelectGroup: (group: EventGroup) => void;
  onIncrementCombo: (index: number) => void;
  onDecrementCombo: (index: number) => void;
  onHandleMapSection: (
    sections: EventDateSectionSite[]
  ) => EventDateSectionSite[];
  onModal: (sectionId: string, tickectId: string, hasMap: boolean) => void;
}

type Props = StateProps & DispathProps;

export const EventSelectContent: React.FC<Props> = ({
  event,
  sectionId,
  showProducts,
  groupId,
  showMap,
  onSelectSection,
  onIncrementTicket,
  onDecrementTicket,
  onShowModal,
  getEventKey,
  onCheckShowProducts,
  onIncrementProduct,
  onDecrementProduct,
  onIncrementCombo,
  onDecrementCombo,
  onSelectGroup,
  onHandleMapSection,
  onModal,
}): JSX.Element => {
  return (
    <>
      <section className="event__area mb-10">
        <h2 className="text-heading text-xl font-dmsans font-normal flex">
          <img src={Area} alt="Selecione o dia" className="fill-heading mr-3" />
          <span>Selecione o setor</span>
        </h2>
        <div>
          {event && event.sections && (
            <SliderProductArea
              items={
                showMap ? onHandleMapSection(event.sections) : event.sections
              }
              handleOnClick={onSelectSection}
              showModal={onShowModal}
              getEventKey={getEventKey}
              onModal={onModal}
            />
          )}
        </div>
        {event &&
          event.sections &&
          event.sections.length > 0 &&
          event.sections.map((section) => (
            <>
              {section.section.id === sectionId && (
                <div className="area-result mt-8 space-y-4">
                  {section.tickets &&
                    section.tickets.length > 0 &&
                    section.tickets.map((ticket, index) => (
                      <ProductIncrement
                        key={`${index}-${ticket.id}`}
                        index={index}
                        name={ticket.name}
                        value={ticket.unitValue}
                        fee={ticket.fee}
                        count={ticket.count}
                        quantity={ticket.quantity}
                        hasMap={ticket.hasMap}
                        renameTable={showMap}
                        eventKey={
                          ticket.hasMap
                            ? getEventKey(section.section.id, ticket.id)
                            : undefined
                        }
                        startDate={event.startDate}
                        endDate={event.endDate}
                        increment={onIncrementTicket}
                        decrement={onDecrementTicket}
                        showModal={onShowModal}
                      />
                    ))}
                </div>
              )}
            </>
          ))}
      </section>
      {event && event.groups && event.groups.length > 0 && (
        <section>
          <div className="flex justify-between items-center">
            <div className="text-heading font-dmsans font-normal">
              Deseja adicionar produtos?
            </div>
            <div>
              <Switch
                value={`${showProducts}`}
                checked={showProducts}
                onChange={() => onCheckShowProducts(!showProducts)}
              />
            </div>
          </div>
          {showProducts && event.groups && event.groups.length > 0 && (
            <div className="event__area mb-10">
              <div>
                {event.groups && showProducts && (
                  <SliderGroup
                    groups={event.groups}
                    handleOnClick={onSelectGroup}
                  />
                )}
              </div>
              {event.groups.map((group) => (
                <>
                  {group.id == groupId && (
                    <div className="area-result mt-8 space-y-4">
                      {group.products &&
                        group.products.length > 0 &&
                        group.products.map((product, index) => (
                          <ProductIncrement
                            key={`${index}-${product.id} `}
                            index={index}
                            name={product.name}
                            value={product.unitValue}
                            fee={product.fee}
                            count={product.amount}
                            quantity={product.quantity}
                            hasMap={false}
                            renameTable={false}
                            increment={onIncrementProduct}
                            decrement={onDecrementProduct}
                            showModal={onShowModal}
                          />
                        ))}
                      {group.combos &&
                        group.combos.length > 0 &&
                        group.combos.map((combo, index) => (
                          <ProductIncrement
                            key={`${index}-${combo.id}`}
                            index={index}
                            name={combo.name}
                            value={combo.unitValue}
                            fee={combo.fee}
                            count={combo.amount}
                            quantity={combo.quantity}
                            hasMap={false}
                            renameTable={false}
                            increment={onIncrementCombo}
                            decrement={onDecrementCombo}
                            showModal={onShowModal}
                          />
                        ))}
                    </div>
                  )}
                </>
              ))}
            </div>
          )}
        </section>
      )}
    </>
  );
};
