import React from 'react';
import { Switch } from 'react-router-dom';
import { HomeNavigation } from 'src/features/home/navigation';
import { EventNavigation } from 'src/features/event/navigation';
import { CheckoutNavigation } from 'src/features/checkout/navigation';
import { renderRoutes } from './utils';
import { ProfileNavigation } from 'src/features/profile/navigation';
import { TicketsNavigation } from 'src/features/tickets/navigation';
import { PdvNavigation } from 'src/features/pdv/navigation';
import { OrderNavigation } from 'src/features/order/navigation';
// import { Route } from './Route';
// import { path } from './path';

const Navigation: React.FC = (): JSX.Element => {
  const homeRoutes = renderRoutes(HomeNavigation);
  const eventRoutes = renderRoutes(EventNavigation);
  const pdvRoutes = renderRoutes(PdvNavigation);
  const checkoutRoutes = renderRoutes(CheckoutNavigation);
  const ProfileRoutes = renderRoutes(ProfileNavigation);
  const TicketsRoutes = renderRoutes(TicketsNavigation);
  const OrderRoutes = renderRoutes(OrderNavigation);

  return (
    <Switch>
      {OrderRoutes}
      {eventRoutes}
      {pdvRoutes}
      {checkoutRoutes}
      {ProfileRoutes}
      {TicketsRoutes}
      {homeRoutes}
      {/* <Route path={path.Initial.All} component={Error404} /> */}
    </Switch>
  );
};

export { Navigation };
