import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  value: string;
  type?:
    | 'text'
    | 'password'
    | 'email'
    | 'number'
    | 'tel'
    | 'date'
    | 'time'
    | 'datetime-local';
  label?: string;
  error?: string | null | undefined;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  renderForward?: React.ReactNode;
  id?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputText: React.FC<InputProps> = ({
  name,
  type = 'text',
  value,
  placeholder,
  error,
  label,
  maxLength,
  className,
  renderForward,
  disabled,
  id,
  onBlur,
  onChange,
}) => (
  <div className={`relative ${className}`}>
    {label && (
      <label
        htmlFor={name}
        className="block text-label text-base font-dmsans font-regular mb-2"
      >
        {label}
      </label>
    )}
    <input
      id={id}
      aria-invalid={error ? 'true' : 'false'}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder ?? 'Digite aqui'}
      maxLength={maxLength}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      className={`relative appearance-none h-[60px] border rounded w-full py-2 px-3 text-input text-base leading-tight focus:outline-none focus:shadow-outline ${
        error ? 'border-red-500' : ''
      }`}
    />
    {renderForward}
    {error && <p className="text-red-500 text-xs">{error}</p>}
  </div>
);
