export const TutorialApp = () => {
  return (
    <>
      <div className="cart-content relative w-full pt-[30px] pb-[120px] px-5 md:px-[85px]">
        <div className="flex flex-wrap justify-between items-left">
          <h5 className="text-[23px] font-bold leading-[28px] text-neutro-b-400">
            Seu ingresso estará disponível no nosso app{' '}
            <span className="text-red-400"> 24hs </span> antes do evento para
            visualização.
          </h5>
        </div>
        <hr className="mt-[20px]" />
        <div>
          <h5 className="text-[17px] font-normal leading-[28px] text-neutro-b-400 mt-[20px] text-left">
            Para visualizar é bem simple, é só seguir o passo à passo:
          </h5>
        </div>
        <div>
          <h5 className="text-[15px] font-bold  leading-[28px] text-neutro-b-400 mt-[45px] text-left">
            1 - Acesse a loja de aplicativo do seu celular e baixe o App Banca
            do ingresso;
          </h5>
          <div className="flex mt-[10px] items-center">
            <a href="https://play.google.com/store/apps/details?id=br.com.bancadoingresso.app">
              <img
                data-imagetype="External"
                src="https://bdi-image-email.s3.amazonaws.com/google_play_2.png"
                alt=""
                width="158px"
                height="50px"
                className="pl-[15px] cursor-pointer"
              />
            </a>
            <a href="https://apps.apple.com/br/app/banca-do-ingresso/id6448055016">
              <img
                data-imagetype="External"
                src="https://bdi-image-email.s3.amazonaws.com/app-store2.png"
                alt=""
                width="158px"
                height="50px"
                className="pl-[15px] cursor-pointer"
              />
            </a>
          </div>
        </div>
        <div>
          <h5 className="text-[15px] font-bold leading-[28px] text-neutro-b-400 mt-[45px] text-left">
            2 - Clique na aba Ingressos;
          </h5>
          <div className="flex mt-[10px] items-center">
            <img
              data-imagetype="External"
              src="https://bdi-image-email.s3.amazonaws.com/aba-app.jpg"
              alt=""
              width="414px"
              height="60px"
              className="pl-[15px]"
            />
          </div>
        </div>
        <div>
          <h5 className="text-[15px] font-bold leading-[28px] text-neutro-b-400 mt-[45px] text-left">
            3 - Selecione o evento e clique em Ingressos Disponíveis;
          </h5>
          <div className="flex mt-[10px] items-center">
            <img
              data-imagetype="External"
              src="https://bdi-image-email.s3.amazonaws.com/painel_ticket.jpg"
              alt=""
              width="346px"
              height="398px"
              className="pl-[15px]"
            />
          </div>
        </div>
        <div>
          <h5 className="text-[15px] font-bold leading-[28px] text-neutro-b-400 mt-[45px] text-left">
            {'4 - Clique na opção "Clique aqui para visualizar"'};
          </h5>
          <div className="flex mt-[10px] items-center">
            <img
              data-imagetype="External"
              src="https://bdi-image-email.s3.amazonaws.com/click-to-show.png"
              alt=""
              width="352px"
              height="59px"
              className="pl-[15px]"
            />
          </div>
        </div>
        <div>
          <h5 className="text-[15px] font-bold leading-[28px] text-neutro-b-400 mt-[45px] text-left">
            5 - Pronto! Agora é só aproveitar o seu evento;
          </h5>
        </div>
      </div>
    </>
  );
};
