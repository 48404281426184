import { ControllerCreditCard } from 'src/features/profile/types';
import Payment from 'src/model/Payment';
import { CardCheckout } from '../types';
import { CardCheckoutContent } from './CardCheckoutContent';
import CardDb from 'src/model/CardDb';

interface StateProps {
  id: string;
  index: number;
  payment: Payment;
  card: CardCheckout;
  totalValue: number;
  showValue: boolean;
  controllerCreditCard: ControllerCreditCard;
  cardSelected: CardDb | undefined;
  cardSelectedInt: number;
}

interface DispatchProps {
  onChange: (
    index: number,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  toCurrency: (value: number) => string;
  onCardSelected: (id: string, index: number) => void;
  setCardSelectedInt: (indexList: number, index: number) => void;
  validateCreditCard: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const CreditCardPaymentRequestContent: React.FC<Props> = ({
  id,
  index,
  card,
  payment,
  totalValue,
  showValue,
  onChange,
  toCurrency,
  controllerCreditCard,
  cardSelected,
  onCardSelected,
  cardSelectedInt,
  setCardSelectedInt,
  validateCreditCard,
}): JSX.Element => {
  return (
    <div className="w-full md:w-[411px] mt-[14px]">
      <div className="flex flex-col justify-between">
        <div className="space-y-5 ">
          {controllerCreditCard.list.length === 0 ? (
            <div className="text-base font-dmsans font-normal text-neutro-w-600">
              Você ainda não tem um{' '}
              <strong> {`${index === 0 ? '' : 'segundo'}`}</strong> cartão de
              crédito cadastrado
              <br />
              <br /> Clique no botão <strong>
                “Adicionar novo cartão”
              </strong>{' '}
              para cadastrar um cartão de crédito.
            </div>
          ) : (
            ''
          )}

          <CardCheckoutContent
            id={id}
            index={index}
            payment={payment}
            card={card}
            totalValue={totalValue}
            showValue={showValue}
            controllerCreditCard={controllerCreditCard}
            cardSelected={cardSelected}
            onChange={onChange}
            toCurrency={toCurrency}
            onCardSelected={onCardSelected}
            cardSelectedInt={cardSelectedInt}
            setCardSelectedInt={setCardSelectedInt}
            validateCreditCard={validateCreditCard}
          />
        </div>
      </div>
    </div>
  );
};
