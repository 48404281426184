/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconClose } from 'src/assets/icons';
import { IconInfo } from 'src/assets/icons/IconInfo';
import { IconMenu } from 'src/assets/icons/IconMenu';
import { IconTicket } from 'src/assets/icons/IconTicket';
import { IconTransfer } from 'src/assets/icons/IconTransfer';
import { ColumnImage, DropdownMenu, Modal } from 'src/components';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { ConfirmCancelTicketAfterContent } from 'src/features/tickets/components/ConfirmCancelTicketAfterContent';
//import { ConfirmCancelTicketBeforeContent } from 'src/features/tickets/components/ConfirmCancelTicketBeforeContent';
import {
  //ControllerTicketCancel,
  ShowModalTickets,
  UseModalControl,
} from 'src/features/tickets/types';
import Order from 'src/model/Order';
import OrderEvent from 'src/model/OrderEvent';
import Page from 'src/model/Page';
import PaymentStatus from 'src/model/PaymentStatus';
import { UseFormControl } from 'src/types';

interface ElementStateProps {
  order: Order;
}

interface StatusProps {
  state: boolean;
  orders: Page<Order, Order>;
  order: Order;
  controllerModalTicket: UseModalControl;
  controllerCancelTicketAfter: UseFormControl;
  //controllerCancelTicketBefore: ControllerTicketCancel;
}

interface DispatchProps {
  onScroll: () => void;
  onShowDetailTicket: (item: Order) => void;
  onShowPrintTicket: (item: Order) => void;
  onShowTransferTicket: (item: Order) => void;
  onShowCancelTicket: (item: Order) => void;
  onSubmitCancelTicket: () => void;
}

type TicketsHistoryContainerProps = StatusProps & DispatchProps;

export const TicketsHistoryContainer = ({
  state,
  orders,
  order,
  controllerModalTicket,
  controllerCancelTicketAfter,
  //controllerCancelTicketBefore,
  onScroll,
  onShowDetailTicket,
  onShowPrintTicket,
  onShowTransferTicket,
  onShowCancelTicket,
  onSubmitCancelTicket,
}: TicketsHistoryContainerProps) => {
  const getPaymentStatus = (status: PaymentStatus): string => {
    let s = '';
    switch (status) {
      case PaymentStatus.APPROVED:
        s = 'Aprovado';
        break;
      case PaymentStatus.DISAPPROVED:
        s = 'Reprovado';
        break;
      case PaymentStatus.AWAITING_PAYMENT:
        s = 'Aguardando Pagamento';
        break;
      case PaymentStatus.CANCELED:
        s = 'Cancelado';
        break;
      case PaymentStatus.CANCELLATION_ANALYSIS:
        s = 'Solicitação de cancelamento em analise';
        break;
      default:
        s = '';
        break;
    }
    return s;
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const hasMore = (): boolean => {
    if (orders.total && orders.page && orders.pageSize) {
      if (orders.page * orders.pageSize < orders.total) {
        return true;
      }
      return false;
    }
    return false;
  };

  const getTicketStatus = (order: Order): string => {
    let s = 'Não utilizado';
    if (order.paymentStatus === PaymentStatus.CANCELED) {
      s = 'Cancelado';
    } else if (order.paymentStatus === PaymentStatus.DISAPPROVED) {
      s = 'Cancelado';
    } else if (order.paymentStatus === PaymentStatus.APPROVED) {
      const date = new Date();
      for (let e = 0; e < order.events.length; e++) {
        if (order.events[e].event.startDate >= date) {
          s = 'Utilizado';
          break;
        } else {
          for (let i = 0; i < order.events[e].items.length; i++) {
            if (order.events[e].items[i].useDate) {
              s = 'Utilizado';
              break;
            } else if (order.events[e].items[i].transferDate) {
              s = 'Transferido';
              break;
            }
          }
        }
      }
    }
    return s;
  };

  const ElementStatus: React.FC<ElementStateProps> = ({
    order,
  }): JSX.Element => {
    const test = getTicketStatus(order);
    let classText = 'order-table-tbody-td-child ';

    if (test === 'Não utilizado') {
      classText += 'bg-status-nao-utilizado';
    } else if (test === 'Cancelado') {
      classText += 'bg-status-cancelado';
    } else if (test === 'Transferido') {
      classText += 'bg-status-transferido';
    }

    return (
      <div className="order-table-tbody-td-child">
        <div className={classText}>{test}</div>
      </div>
    );
  };

  const mountEventName = (events: OrderEvent[]): string | JSX.Element => {
    if (events.length === 1) {
      return events[0].event.name;
    } else {
      return (
        <>
          {events.map((data, index) => (
            <>
              <span key={`eventNameSpan-${data.event.id}`}>
                {data.event.name}
              </span>
              {index < events.length - 1 && <br />}
            </>
          ))}
        </>
      );
    }
  };

  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full p-7 md:px-0 container mx-auto py-24">
        <header className="flex flex-wrap items-center mb-[70px]">
          <h1 className="font-dmsans font-medium text-[37px]">
            Histórico de ingressos
          </h1>
          <p className="w-full flex items-center text-[21px] font-dmsans font-normal text-neutro-w-600">
            Verifique ou consulte os seus ingressos
          </p>
        </header>

        <table>
          <thead className="order-table-thead">
            <th className="order-table-thead-th">
              <td
                className="order-table-thead-td"
                style={{
                  minWidth: '100px',
                  maxWidth: '100px',
                }}
                width={'100px'}
              ></td>
              <td
                className="order-table-thead-td"
                style={{
                  minWidth: '200px',
                  maxWidth: '200px',
                }}
                width={'200px'}
              >
                <div className="order-table-thead-td-child">
                  <div className="order-table-thead-td-child-text">
                    Nome do evento
                  </div>
                </div>
              </td>
              <td className="order-table-thead-td">
                <div className="order-table-thead-td-child">
                  <div className="order-table-thead-td-child-text">
                    Data da compra
                  </div>
                </div>
              </td>
              <td className="order-table-thead-td">
                <div className="order-table-thead-td-child">
                  <div className="order-table-thead-td-child-text">
                    Data do evento
                  </div>
                </div>
              </td>
              <td
                className="order-table-thead-td"
                style={{
                  minWidth: '120px',
                  maxWidth: '120px',
                }}
                width={'120px'}
              >
                <div className="order-table-thead-td-child">
                  <div className="order-table-thead-td-child-text">
                    Pagamento
                  </div>
                </div>
              </td>
              <td
                className="order-table-thead-td"
                style={{
                  minWidth: '140px',
                  maxWidth: '140px',
                }}
                width={'140px'}
              >
                <div className="order-table-thead-td-child">
                  <div className="order-table-thead-td-child-text">Valor</div>
                </div>
              </td>
              <td
                className="order-table-thead-td"
                style={{
                  minWidth: '130px',
                  maxWidth: '130px',
                }}
                width={'130px'}
              ></td>
              <td
                className="order-table-thead-td"
                style={{
                  minWidth: '60px',
                  maxWidth: '60px',
                }}
                width={'60px'}
              ></td>
            </th>
          </thead>
          <tbody className="order-table-tbody">
            <InfiniteScroll
              dataLength={
                orders.total && orders.page && orders.pageSize
                  ? orders.total - orders.page * orders.pageSize
                  : 0
              } //This is important field to render the next data
              next={() => onScroll()}
              hasMore={hasMore()}
              loader={
                <p style={{ textAlign: 'center' }}>
                  <b>Loading...</b>
                </p>
              }
            >
              {orders.list &&
                orders.list.length > 0 &&
                orders.list.map((item) => (
                  <tr key={item.id} className="order-table-tbody-tr">
                    <td
                      className="order-table-tbody-td"
                      style={{
                        minWidth: '100px',
                        maxWidth: '100px',
                      }}
                      width={'100px'}
                    >
                      <div className="order-table-tbody-td-child">
                        <ColumnImage orderEvents={item.events} />
                      </div>
                    </td>
                    <td
                      className="order-table-tbody-td"
                      style={{
                        minWidth: '200px',
                        maxWidth: '200px',
                      }}
                      width={'200px'}
                    >
                      <div className="order-table-tbody-td-child">
                        {mountEventName(item.events)}
                      </div>
                    </td>
                    <td className="order-table-tbody-td">
                      <div className="order-table-tbody-td-child">
                        {dayjs(item.orderDate)
                          .locale('pt-br')
                          .format('DD/MM/YYYY')}
                      </div>
                    </td>
                    <td className="order-table-tbody-td">
                      <div className="order-table-tbody-td-child">
                        {dayjs(item.events[0].event.startDate)
                          .locale('pt-br')
                          .format('DD/MM/YYYY')}
                      </div>
                    </td>
                    <td
                      className="order-table-tbody-td"
                      style={{
                        minWidth: '120px',
                        maxWidth: '120px',
                      }}
                      width={'120px'}
                    >
                      <div className="order-table-tbody-td-child">
                        <span title={getPaymentStatus(item.paymentStatus)}>
                          {getPaymentStatus(item.paymentStatus)}
                        </span>
                      </div>
                    </td>
                    <td
                      className="order-table-tbody-td"
                      style={{
                        minWidth: '140px',
                        maxWidth: '140px',
                      }}
                      width={'140px'}
                    >
                      <div className="order-table-tbody-td-child">
                        {toCurrency(item.totalValue)}
                      </div>
                    </td>
                    <td
                      className="order-table-tbody-td"
                      style={{
                        minWidth: '130px',
                        maxWidth: '130px',
                      }}
                      width={'130px'}
                    >
                      <div className="order-table-tbody-td-child">
                        <div
                          className={`p-1 rounded-[5px] bg-status-nao-utilizado`}
                        >
                          <ElementStatus order={item}></ElementStatus>
                        </div>
                      </div>
                    </td>
                    <td
                      className="order-table-tbody-td"
                      style={{
                        minWidth: '60px',
                        maxWidth: '60px',
                      }}
                      width={'60px'}
                    >
                      <div>
                        <div>
                          <DropdownMenu
                            title={<IconMenu className="h-[24px]" />}
                            actions={[
                              {
                                title: 'Ver detalhe',
                                onClick: () => onShowDetailTicket(item),
                                icon: <IconInfo className="h-[19px]" />,
                              },
                              {
                                title: 'Imprimir',
                                onClick: () => onShowPrintTicket(item),
                                icon: <IconTicket className="h-[19px]" />,
                                hidden: !item.showPrint,
                              },
                              {
                                title: 'Transferir Ingressos',
                                onClick: () => onShowTransferTicket(item),
                                icon: <IconTransfer className="h-[22px]" />,
                                hidden: !item.showTransfer,
                              },
                              {
                                className: 'text-primary-600',
                                title: 'Cancelar Ingressos',
                                onClick: () => onShowCancelTicket(item),
                                icon: (
                                  <IconClose className="h-[14px] text-primary-600" />
                                ),
                                hidden: !item.showCancel,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </InfiniteScroll>
          </tbody>
        </table>
      </div>
      <Modal
        visible={controllerModalTicket.visible}
        title={controllerModalTicket.title}
        onSetVisible={controllerModalTicket.onToggleModal}
        actions={[]}
        className="md:w-[1038px]"
      >
        {
          {
            /*
            [ShowModalTickets.TICKET_CANCEL_BEFORE]: (
              <ConfirmCancelTicketBeforeContent
                order={order}
                toCurrency={toCurrency}
                onCancelConfirm={
                  controllerCancelTicketBefore.onCancelTicketCancel
                }
                onOkConfirm={controllerCancelTicketBefore.onSubmitTicketCancel}
              />
            ),
            */
            [ShowModalTickets.TICKET_CANCEL_AFTER]: (
              <ConfirmCancelTicketAfterContent
                order={order}
                toCurrency={toCurrency}
                state={state}
                controllerCancelTicketAfter={controllerCancelTicketAfter}
                onSubmit={onSubmitCancelTicket}
              />
            ),
          }[controllerModalTicket.shouldShowModal]
        }
      </Modal>
    </AuthWrapper>
  );
};
