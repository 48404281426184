import React from 'react';
import { IconClose, IconSearch } from 'src/assets/icons';
import EventSite from 'src/model/EventSite';
import googlePlay from './../../../assets/images/googlePlay.png';
import appStore from './../../../assets/images/appStore.png';

interface HeroSearchContentProps {
  search: string;
  events: EventSite[];
  onChangeInputSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectEvent: (event: EventSite) => void;
  onClearInputSearch: () => void;
}

export const HeroSearchContent: React.FC<HeroSearchContentProps> = ({
  search,
  events,
  onChangeInputSearch,
  onSelectEvent,
  onClearInputSearch,
}) => {
  return (
    <section className="w-full h-[550px] bg-hero">
      <div className="container w-full md:max-w-[1110px] h-full mx-auto flex flex-col items-center pt-20 sm:pt-44">
        <h1 className="text-white mb-2 mt-7 sm:mb-4 text-4xl sm:text-5xl md:text-5xl-bdi font-bold font-dmsans tracking-wide text-center">
          Sua diversão começa aqui
        </h1>
        <p className="text-white text-base sm:text-xl-bdi font-normal font-dmsans text-center">
          Os melhores eventos na palma da sua mão
        </p>
        <div className="w-full md:w-[631px] mt-20 flex flex-col">
          <div className="relative h-[72px]">
            <input
              value={search}
              onChange={(e) => onChangeInputSearch(e)}
              className="absolute w-full h-[72px] pl-[20px] bg-input-blur font-normal
            font-dmsans placeholder:text-white rounded-lg"
              placeholder="Pesquise pelo evento ou filme"
            />
            <button
              style={{
                display: `${search != '' ? 'block' : 'none'}`,
              }}
              className="absolute top-[50%] right-0 transform -translate-x-[75px] -translate-y-[50%]"
              onClick={() => onClearInputSearch()}
            >
              <IconClose className="text-neutro-w-000 h-[12px]" />
            </button>

            <button
              className="absolute top-[50%] right-0 transform -translate-x-[5px] -translate-y-[50%]
                w-[48px] h-[62px] bg-input-blur rounded-[7px] flex justify-center items-center"
            >
              <IconSearch className="text-white h-[22px]" />
            </button>

            <div className="h-full p-0 pr-2 flex justify-center items-center"></div>
          </div>
        </div>
        {search && search.length > 0 ? (
          <div className="w-full md:w-[631px] flex flex-col">
            <ul className="relative z-30 top-[10px] py-7 px-5 md:px-0 bg-result-blur shadow-pop rounded-md">
              {events &&
                events.map((data) => (
                  <li key={data.id} onClick={() => onSelectEvent(data)}>
                    <a
                      href="#"
                      className="flex items-center p-4 text-md text-white font-dmsans font-normal"
                    >
                      <div>
                        <img
                          src={data.imageUrl}
                          alt=""
                          className="w-[40px] h-[30px] rounded-md mr-4"
                        />
                      </div>
                      <div>{data.name}</div>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          <div className="flex mt-8 gap-5 items-center ">
            <a
              href="https://play.google.com/store/apps/details?id=br.com.bancadoingresso.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-auto h-[40px] lg:w-auto lg:h-[50px]"
                src={googlePlay}
                alt="Google Play"
              />
            </a>
            <a
              href="https://apps.apple.com/br/app/banca-do-ingresso/id6448055016"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-auto h-[40px] lg:w-auto lg:h-[50px]"
                src={appStore}
                alt="App Store"
              />
            </a>
          </div>
        )}

        {/* <div className="uppercase text-white">Baixe agora.</div> */}
      </div>
    </section>
  );
};
