export const TermsContent = () => (
  <div>
    <p className="mt-5 mb-3">
      O presente documento estabelece um conjunto de condições gerais e ações
      sobre o uso do Sistema Banca do Ingresso, incluindo aqui o Site de Vendas
      e App, sob marca de propriedade da <b>Banca do Ingresso</b>, denominado
      apenas como <b>Banca do Ingresso</b>.
    </p>
    <p className="mb-3">
      O Usuário encontrará nestes termos deveres e obrigações para utilização do
      App e navegação no site e ou App.
    </p>
    <p className="mb-3">
      <b>DEFINIÇÕES</b>
    </p>
    <p className="mb-3">
      O objeto destes Termos diz respeito à disponibilização aos Usuários do
      site e Sistema Banca do Ingresso, o qual viabiliza que o usuário comprador
      acesse o E-commerce BANCA DO INGRESSO e website, a fim de que o Usuário
      usufrua dos referidos produtos e ingressos.
    </p>
    <p className="mb-3">
      O Sistema Banca do Ingresso é criado e disponibilizado pela Empresa Banca
      do Ingresso
    </p>
    <p className="mb-3">
      <b>ACEITE</b>
    </p>
    <p className="mb-3">
      Ao acessar o site ou o App, o Usuário deve ler o presente termo, bem como
      a política de privacidade, manifestando sua concordância com relação às
      suas disposições, clicando no botão indicado para esta finalidade.
    </p>
    <p className="mb-3">
      O Usuário demonstra sua aceitação livre, informada e expressa, sem
      quaisquer reservas acerca de todo o conteúdo deste documento. Na hipótese
      de não concordar com qualquer parte destes termos, o Usuário deve
      abster-se de utilizar a plataforma e o Sistema Banca do Ingresso.
    </p>
    <p className="mb-3">
      <b>DA CAPACIDADE</b>
    </p>
    <p className="mb-3">
      O Usuário, ao utilizar a plataforma, garante que possui plena capacidade
      civil, além de ser maior de 18 (dezoito) anos de idade, sendo vedado
      qualquer utilização por menores de idade.
    </p>
    <p className="mb-3">
      Ainda, o Usuário tem ciência de que eventuais benefícios poderão variar ou
      ser condicionados à idade do Usuário, a fim de cumprir a legislação sobre
      a matéria.
    </p>
    <p className="mb-3">
      <b>SOBRE A BANCA DO INGRESSO</b>
    </p>
    <p className="mb-3">
      A banca do ingresso é uma empresa criada com o objetivo de proporcionar a
      seus clientes todo conforto, agilidade e a confiabilidade do princípio ao
      fim de cada evento, através de um sistema moderno e avançado de geração de
      ingressos.
    </p>
    <p className="mb-3">
      As vendas são efetivadas através de terminais P.O.S instalados nos pontos
      de vendas determinados pelo cliente, onde toda venda é registrada em tempo
      real possibilitando o acompanhamento através do nosso site.
    </p>
    <p className="mb-3">
      Cada unidade vendida e computada instantaneamente em nosso servidor e o
      cliente acompanha por meio de tabelas e gráficos.
    </p>
    <p className="mb-3">
      O <b>Banca do Ingresso</b> compromete-se a envidar os melhores esforços
      para garantir o pleno funcionamento do App, de modo a evitar, na medida do
      possível, intermitências na plataforma, bem como quaisquer falhas e/ou
      interferências.
    </p>
    <p className="mb-3">
      O Usuário Participante reconhece que o <b>Banca do Ingresso</b> é um
      Aplicativo que está sujeita a falhas, interrupções, atrasos e/ou erros, e
      que não podemos controlar tais fatores, mas vamos agir com diligência para
      manter as soluções e serviços disponibilizados funcionando corretamente.
    </p>
    <p className="mb-3">
      No entanto, não somos responsáveis de nenhuma forma por tais interrupções,
      atrasos e erros, bem como por quaisquer danos, alegadamente ocasionados
      aos Usuários relacionados a tais interrupções, atrasos e erros.
    </p>
    <p className="mb-3">
      Em razão da complexidade e impossibilidade de isenção de falhas nos
      sistemas de informática, por sua própria natureza, o{' '}
      <b>Banca do Ingresso</b> não garante que o serviço será sempre, em todas
      as hipóteses, pontual, seguro, sem erros ou sem quaisquer interrupções.
    </p>
    <p className="mb-3">
      Ainda, caso o Usuário sofra eventuais perdas e danos em virtude de atos
      cometidos por terceiros, o <b>Banca do Ingresso</b> não se responsabiliza
      por qualquer tipo de responsabilidade decorrente desta relação, sejam
      fiscais, trabalhistas, consumeristas ou de qualquer outra natureza, pois
      não há qualquer intermediação do Banca do Ingresso nas negociações
      existentes entre os Usuários.
    </p>
    <p className="mb-3">
      <b>FUNÇÕES DO ECOMMERCE WEB E APP</b>
    </p>
    <p className="mb-3">
      <b>Site Banca do Ingresso</b>
    </p>
    <p className="mb-3">
      Site de venda de ingressos e produtos de eventos com cadastro de usuários
      e gerenciamento dos ingressos comprados e utilizados.
    </p>
    <p className="mb-3">
      Entre os recursos do sistema, segue abaixo a descrição completa:
    </p>
    <p className="mb-3">
      <b>1. HOME</b>
    </p>
    <p className="mb-3">
      - Tela home com o botões (Quem somos/Contato/Políticas/Suporte/Informações
      Extras)
    </p>
    <p className="mb-3">- Telas de visualização e seleção de eventos</p>
    <p className="mb-3">
      - Tela de busca e filtro de eventos por categoria, data, local
    </p>
    <p className="mb-3">
      <b>2. LOGIN</b>
    </p>
    <p className="mb-3">
      - Fluxo de Login com opção de SSO (Gmail/AppleID/FaceID)
    </p>
    <p className="mb-3">- Fluxo esqueci minha senha</p>
    <p className="mb-3">
      <b>3. CADASTRO</b>
    </p>
    <p className="mb-3">
      - Fluxo de cadastro de usuário com opção de SSO (Gmail/AppleID/FaceID)
    </p>
    <p className="mb-3">
      - Fluxo de Confirmação de Celular com envio e reenvio de SMS
    </p>
    <p className="mb-3">
      - Fluxo de Confirmação de E-mail com envio e reenvio de e-mail
    </p>
    <p className="mb-3">- Aceite de termos e políticas de privacidade</p>
    <p className="mb-3">
      <b>4. INGRESSOS E PRODUTOS</b>
    </p>
    <p className="mb-3">- Tela com botões (Sobre/Descontos/Pontos de Venda)</p>
    <p className="mb-3">- Fluxo de seleção de ingressos</p>
    <p className="mb-3">- Fluxo de seleção de produtos</p>
    <p className="mb-3">- Cupom de desconto</p>
    <p className="mb-3">
      <b>5. CARRINHO DE COMPRAS</b>
    </p>
    <p className="mb-3">- Fluxo de conferência de itens</p>
    <p className="mb-3">- Fluxo de Preenchimento de dados pessoais do pedido</p>
    <p className="mb-3">
      - Fluxo resumo do pedido com possibilidade de alterações
    </p>
    <p className="mb-3">- Fechamento da compra com ou sem cupom de desconto</p>
    <p className="mb-3">
      <b>6. PAGAMENTO</b>
    </p>
    <p className="mb-3">- Fluxo de seleção de formas de pagamento</p>
    <p className="mb-3">- Fluxo com opção de guardar dados do cartão</p>
    <p className="mb-3">- Fluxo de pagamento e confirmação</p>
    <p className="mb-3">- Envios de Ingresso via e-mail</p>
    <p className="mb-3">
      <b>7. MEU PERFIL</b>
    </p>
    <p className="mb-3">- Tela de push de avisos diversos</p>
    <p className="mb-3">- Telas de cadastro de cartões</p>
    <p className="mb-3">- Telas de histórico de compras</p>
    <p className="mb-3">- Fluxo de alteração de cadastro</p>
    <p className="mb-3">- Fluxo de cancelamento de cadastro</p>
    <p className="mb-3">
      <b>8. CANCELAMENTO DE INGRESSO</b>
    </p>
    <p className="mb-3">- Fluxo de cancelamento de compra de ingresso</p>
    <p className="mb-3">
      <b>9.TRANSFERÊNCIA DE INGRESSO</b>
    </p>
    <p className="mb-3">- Fluxo de transferir ingresso para outro cpf</p>
    <p className="mb-3">
      <b>DAS OBRIGAÇÕES</b>
    </p>
    <p className="mb-3">São obrigações do Usuário:</p>
    <p className="mb-3">
      • Observar e cumprir todas as leis, regulamentos e instruções aplicáveis;
    </p>
    <p className="mb-3">
      • Não utilizar a plataforma para finalidade diversa da prevista nestes
      termos;
    </p>
    <p className="mb-3">
      • Não divulgar spam; marketing multinível; publicidade irregular ou em
      desacordo às normas; propagadas e com conteúdo potencialmente ofensivo,
      obsceno, pornográfico, que promova o terrorismo; qualquer tipo de
      discriminação, independente de sexo, raça, religião, origem, cor, condição
      física, estado civil, idade, situação familiar, estado gravídico etc; e
      qualquer atentado aos direitos individuais;
    </p>
    <p className="mb-3">
      • Responsabilizar-se por todas as informações, imagens e dados inseridos e
      enviados na plataforma, associados à sua conta, concordando que toda
      integridade, legitimidade, veracidade e adequação dos dados e imagens
      inseridos no Sistema da Banca do Ingresso serão de inteira
      responsabilidade dos Usuários; e{' '}
    </p>
    <p className="mb-3">• Inserir dados e informações atualizadas;</p>
    <p className="mb-3">
      <b>O Usuário não pode:</b>
    </p>
    <p className="mb-3">• Exigir nenhuma compensação em dinheiro;</p>
    <p className="mb-3">• Vender qualquer tipo de produto; </p>
    <p className="mb-3">
      • Inserir qualquer tipo de código malicioso (malware) na plataforma.
    </p>
    <br />
    <p className="mb-3">
      Qualquer violação a estes termos o Usuário resultará em bloqueio de acesso
      ao Sistema.
    </p>
    <p className="mb-3">
      Ainda, você concorda em nos indenizar, e manter nossos diretores(as),
      conselheiros(as), empregados(as) e agentes isentos(as) de responsabilidade
      por todas e quaisquer reclamações, cobranças, prejuízos, responsabilidades
      e despesas (inclusive honorários advocatícios) decorrentes ou
      relacionados: ao uso dos nossos serviço de serviços ou bens obtidos por
      meio do uso dos nossos serviços; descumprimento ou violação de qualquer
      disposição destes termos; ou violação dos direitos de terceiros.
    </p>
    <p className="mb-3">
      <b>SOBRE A PROPRIEDADE INTELECTUAL</b>
    </p>
    <p className="mb-3">
      Ainda, estes termos não transferem para o Usuário qualquer direito de
      propriedade intelectual que o <b>Banca do Ingresso</b> possua sobre os
      seus processos e sistemas e/ou qualquer direito intelectual que venha a
      criar, construir ou adquirir. Assim, o Usuário não deve, a qualquer tempo,
      reivindicar ou adquirir qualquer direito, título ou interesse sobre a
      propriedade intelectual do <b>Banca do Ingresso</b> e
      reconhece/compromete-se a respeitar todos os direitos, títulos e
      interesses do <b>Banca do Ingresso</b>, obrigando-se a não intentar
      qualquer ação que possa prejudicar ou questionar ou anular tais direitos.
    </p>
    <p className="mb-3">
      <b>SOBRE OS DADOS PESSOAIS</b>
    </p>
    <p className="mb-3">
      O <b>Banca do Ingresso</b> trata seus dados conforme disposto em nossa
      Política de Privacidade, sempre em conformidade com a Lei 13.709/2018 –
      Lei Geral de Proteção de Dados Pessoais.
    </p>
    <p className="mb-3">
      Os dados e informações coletados serão armazenados em ambiente seguro e
      somente poderão ser acessadas por pessoas autorizadas e pelos
      Organizadores ao qual o Usuário se integrou por meio do convite e acesso
      disponibilizado.
    </p>
    <p className="mb-3">
      <b>INDEPENDÊNCIA DAS CLÁUSULAS </b>
    </p>
    <p className="mb-3">
      No caso de eventual consideração de qualquer disposição destes Termos como
      ilegal, nula ou ineficaz, tal disposição deve, contudo, ser aplicável até
      ao limite máximo permitido pela lei aplicável, e a porção inexequível será
      considerada separada desses Termos. Porém, tal determinação não prejudica
      a validade e aplicabilidade de quaisquer outras disposições restantes.
    </p>
    <p className="mb-3">
      <b>DISPOSIÇÕES FINAIS E FORO</b>
    </p>
    <p className="mb-3">
      Caso ocorra do <b>Banca do Ingresso</b> deixar de exercer ou executar
      quaisquer direitos ou disposições contidos neste termo, isso não
      constituirá renúncia dos mesmos.
    </p>
    <p className="mb-3">
      Ainda, ao utilizar os serviços disponibilizados, você também concorda com
      nossa POLÍTICA DE PRIVACIDADE.
    </p>
    <p className="mb-3">
      Eventuais dúvidas que possam surgir em virtude destes termos, contrato ou
      privacidade devem ser enviadas para o Contato via e-mail
      suporte@bancadoingresso.com.br
    </p>
    <p className="mb-3">
      As Partes elegem, como único competente para a solução ou interpretação de
      cláusulas ou oriundas do presente termo, as que amigavelmente não puderem
      resolver, o Foro da Comarca de São José do Rio Preto, Estado de São Paulo,
      em prejuízo de qualquer outro por mais privilegiado que seja.
    </p>
    <br />
    <p className="mb-3">
      <b>Última atualização: 24 de abril de 2023</b>
    </p>
  </div>
);
