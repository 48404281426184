import React from 'react';
import { Route } from 'src/navigation/Route';
import { CheckoutScreen } from './screens/Checkout';

export const CHECKOUT_ROUTES = {
  event: '/checkout',
};

export const CheckoutNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={CHECKOUT_ROUTES.event} component={CheckoutScreen} />
  </React.Fragment>
);
