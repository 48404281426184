import dayjs from 'dayjs';
import EventSite from 'src/model/EventSite';

interface SliderEventItemProps {
  event: EventSite;
  className?: string;
  onSelectEvent(event: EventSite): void;
}

export const SliderEventItem: React.FC<SliderEventItemProps> = ({
  event,
  className,
  onSelectEvent,
}) => {
  const mountTextDate = (): string => {
    const startDate = dayjs(event?.startDate).locale('pt-br').format('DD MMM');
    const endDate = dayjs(event?.endDate).locale('pt-br').format('DD MMM');
    if (startDate === endDate) {
      return startDate;
    } else {
      return `${startDate} > ${endDate}`;
    }
  };
  return (
    <a
      className={`${className}`}
      style={{ cursor: 'pointer' }}
      onClick={() => onSelectEvent(event)}
    >
      <div className="relative">
        <img className="rounded-md" src={event.imageUrl} alt="Event" />
        <div className="absolute rounded-md top-0 w-full h-full bg-card-default hover:bg-card-hover transition-all duration-200 ease-in-out"></div>
      </div>
      <div className="mt-4">
        <h4 className="text-heading font-dmsans font-medium text-[1.3125rem]">
          {event.name}
        </h4>
        <div className="flex items-center">
          <span className="font-dmsans font-normal text-paragraph flex-grow-0 flex-shrink-0">
            {mountTextDate()}
          </span>
          <div className="w-[6px] h-[6px] mx-2 bg-gray-300 rounded"></div>
          <span className="font-dmsans font-normal text-paragraph truncate text-ellipsis overflow-hidden">
            {`${event.city}`}
          </span>
          <span className="font-dmsans font-normal text-paragraph">
            {`/${event.state}`}
          </span>
        </div>
      </div>
    </a>
  );
};
