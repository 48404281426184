import React from 'react';
import { Route } from 'src/navigation/Route';
import { PdvScreen } from './screens/Pdv';

export const PDV_ROUTES = {
  pdv: '/pdv/:pdvId/event/:eventId',
};

export const PdvNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={PDV_ROUTES.pdv} component={PdvScreen} />
  </React.Fragment>
);
