interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconInfo = (props: IconProps) => (
  <svg
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M10 0C7.48053 0 5.0643 1.00097 3.28257 2.78257C1.50083 4.56417 0.5 6.98053 0.5 9.5C0.5 12.0195 1.50097 14.4357 3.28257 16.2174C5.06417 17.9992 7.48053 19 10 19C12.5195 19 14.9357 17.999 16.7174 16.2174C18.4992 14.4358 19.5 12.0195 19.5 9.5C19.4974 6.98125 18.4955 4.56656 16.7145 2.78572C14.9336 1.00466 12.5188 0.00283806 10.0002 0.000180102L10 0ZM10 17.8173C7.79412 17.8173 5.67852 16.941 4.1187 15.3813C2.55902 13.8214 1.68266 11.7058 1.68266 9.5C1.68266 7.29416 2.55902 5.17852 4.1187 3.6187C5.67856 2.05902 7.79416 1.18266 10 1.18266C12.2058 1.18266 14.3215 2.05902 15.8813 3.6187C17.441 5.17856 18.3173 7.29416 18.3173 9.5C18.3149 11.7052 17.4378 13.8194 15.8786 15.3786C14.3193 16.9377 12.2051 17.8148 10 17.8173L10 17.8173ZM11.0983 8.48295V13.3565C11.0983 13.461 11.0567 13.5613 10.9828 13.6352C10.9088 13.7091 10.8086 13.7507 10.7041 13.7507H9.29591C9.07823 13.7507 8.90172 13.5742 8.90172 13.3565V8.48205C8.90172 8.26436 9.07823 8.08786 9.29591 8.08786H10.7041C10.8086 8.08786 10.9088 8.12939 10.9828 8.2033C11.0567 8.27721 11.0983 8.37752 11.0983 8.48205V8.48295ZM11.0983 5.64479V6.68076C11.0983 6.78529 11.0567 6.88542 10.9828 6.95933C10.9088 7.03342 10.8086 7.07495 10.7041 7.07495H9.29591C9.07823 7.07495 8.90172 6.89844 8.90172 6.68076V5.64321C8.90172 5.42553 9.07823 5.24902 9.29591 5.24902H10.7041C10.8086 5.24902 10.9088 5.29055 10.9828 5.36446C11.0567 5.43837 11.0983 5.53868 11.0983 5.64321V5.64479Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
