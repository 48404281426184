import React, { useState } from 'react';
import Flickity from 'react-flickity-component';
import { classNames } from 'src/helpers/common';
import { EventGroup } from '../screens/Event';

interface StateProps {
  groups: EventGroup[];
}
interface DispatchProps {
  handleOnClick: (group: EventGroup) => void;
}

type Props = StateProps & DispatchProps;

interface GroupProps {
  item: EventGroup;
  index: number;
  tabActive: number;
  setTabActive: (value: number) => void;
  handleOnClick: (item: EventGroup) => void;
}

const Group = ({
  item,
  index,
  handleOnClick,
  tabActive,
  setTabActive,
}: GroupProps) => (
  <a
    key={item.id}
    onClick={() => {
      handleOnClick(item);
      setTabActive(index + 1);
    }}
    className={classNames(
      tabActive === index + 1
        ? 'bg-[#60B9CD] text-white font-bold border-alert-info-soft'
        : 'bg-[#F1F1F1] text-[#828282] font-normal border-neutro-w-100',
      'carousel-cell w-[135px] mr-5 cursor-pointer p-2 flex flex-col justify-center items-center border hover:border-alert-info-soft'
    )}
  >
    <div
      className={classNames(
        tabActive === index + 1
          ? 'bg-neutro-w-000 text-alert-info-soft'
          : 'bg-neutro-w-400 text-neutro-w-000',
        'w-[14px] h-[14px] rounded-full font-dmsans font-normal text-[10px] flex justify-center items-center absolute top-2 right-2'
      )}
    >
      {item.count}
    </div>
    {item.name && <span className="text-center">{item.name}</span>}
  </a>
);

const SliderGroup: React.FC<Props> = ({
  groups,
  handleOnClick,
}: Props): JSX.Element => {
  const [tabActive, setTabActive] = useState<number>(0);
  const flickityOptions = {
    freeScroll: true,
    percentPosition: true,
    cellAlign: 'left',
  };

  return (
    <Flickity
      options={flickityOptions}
      className={'slider-ticket-product pl-[60px] mt-6'}
    >
      {groups.map((item, index) => (
        <Group
          key={item.id}
          item={item}
          index={index}
          handleOnClick={handleOnClick}
          tabActive={tabActive}
          setTabActive={setTabActive}
        />
      ))}
    </Flickity>
  );
};

export default SliderGroup;
