import { Button, InputText } from 'src/components';
import { UseFormControl } from 'src/types';
import { FormInputNameCreditCard } from '../types';

interface AddCreditCardProps {
  state: boolean;
  controllerAddCreditCard: UseFormControl;
  onSubmit: () => void;
}

export const AddCreditCardContent = ({
  state,
  controllerAddCreditCard,
  onSubmit,
}: AddCreditCardProps) => {
  return (
    <div className="w-full mt-[50px]">
      <div className="mb-[40px]">
        <InputText
          name="number"
          label="Número do cartão"
          placeholder="1234 5678 90123 3456"
          className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
          value={
            controllerAddCreditCard.formData[FormInputNameCreditCard.number]
          }
          onChange={(e) =>
            controllerAddCreditCard.onChangeFormInput(
              FormInputNameCreditCard.number
            )(e.target.value)
          }
          error={
            controllerAddCreditCard.formErrors.number &&
            controllerAddCreditCard.formErrors.number[0]
          }
        />
      </div>
      <div className="mb-[40px] grid grid-cols-2 gap-5">
        <InputText
          name="date"
          label="Data de vencimento"
          placeholder="MM/AA"
          className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
          value={controllerAddCreditCard.formData[FormInputNameCreditCard.date]}
          onChange={(e) =>
            controllerAddCreditCard.onChangeFormInput(
              FormInputNameCreditCard.date
            )(e.target.value)
          }
          maxLength={5}
          error={
            controllerAddCreditCard.formErrors.date &&
            controllerAddCreditCard.formErrors.date[0]
          }
        />
        <InputText
          name="cvv"
          label="CVV"
          placeholder="000"
          className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
          value={controllerAddCreditCard.formData[FormInputNameCreditCard.cvv]}
          onChange={(e) =>
            controllerAddCreditCard.onChangeFormInput(
              FormInputNameCreditCard.cvv
            )(e.target.value)
          }
          error={
            controllerAddCreditCard.formErrors.cvv &&
            controllerAddCreditCard.formErrors.cvv[0]
          }
        />
      </div>
      <div className="mb-[50px]">
        <InputText
          name="name"
          label="Nome do títular"
          placeholder="Maria da Silva"
          className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
          value={controllerAddCreditCard.formData[FormInputNameCreditCard.name]}
          onChange={(e) =>
            controllerAddCreditCard.onChangeFormInput(
              FormInputNameCreditCard.name
            )(e.target.value)
          }
          error={
            controllerAddCreditCard.formErrors.name &&
            controllerAddCreditCard.formErrors.name[0]
          }
        />
      </div>
      <div className="mb-[50px]">
        <InputText
          name="document"
          label="CPF do títular"
          placeholder="123.456.789-00"
          className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
          maxLength={14}
          value={
            controllerAddCreditCard.formData[FormInputNameCreditCard.document]
          }
          onChange={(e) =>
            controllerAddCreditCard.onChangeFormInput(
              FormInputNameCreditCard.document
            )(e.target.value)
          }
          error={
            controllerAddCreditCard.formErrors.document &&
            controllerAddCreditCard.formErrors.document[0]
          }
        />
      </div>
      <div className="mb-[0px]">
        <Button
          type="submit"
          title="Adicionar cartão"
          buttonStyle="primary"
          size="xlg"
          className="w-full"
          disabled={
            state ||
            controllerAddCreditCard.formData[FormInputNameCreditCard.number] ===
              '' ||
            controllerAddCreditCard.formData[FormInputNameCreditCard.date] ===
              '' ||
            controllerAddCreditCard.formData[FormInputNameCreditCard.cvv] ===
              '' ||
            controllerAddCreditCard.formData[FormInputNameCreditCard.name] ===
              '' ||
            controllerAddCreditCard.formData[
              FormInputNameCreditCard.document
            ] === ''
          }
          onClick={() => onSubmit()}
        />
      </div>
    </div>
  );
};
