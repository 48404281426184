/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconTicket } from 'src/assets/icons/IconTicket';
import { Button, InputText, Modal, TableColumn } from 'src/components';
import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import {
  FormInputNameFilter,
  ShowModalTickets,
  UseModalControl,
} from 'src/features/tickets/types';
import { ConfirmCancelTicketAfterContent } from 'src/features/tickets/components/ConfirmCancelTicketAfterContent';
import { UseFormControl } from 'src/types';
import Order from 'src/model/Order';
import Page from 'src/model/Page';
import PaymentStatus from 'src/model/PaymentStatus';
import dayjs from 'dayjs';
import * as Dialog from '@radix-ui/react-dialog';
import React, { useState } from 'react';
import { Close } from 'src/assets/icons';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import classNames from 'classnames';
import OrderEvent from 'src/model/OrderEvent';
import TicketCard from 'src/features/tickets/components/CardTicket';

interface ElementStateProps {
  order: Order;
}

interface StatusProps {
  state: boolean;
  orders: Page<Order, Order>;
  order: Order;
  controllerModalTicket: UseModalControl;
  controllerCancelTicketAfter: UseFormControl;
  controllerFilter: UseFormControl;
}

interface DispatchProps {
  onShowCancelTicket: (item: Order) => void;
  onSubmitCancelTicket: () => void;
  onShowDetailTicket: (item: Order) => void;
  onShowPrintTicket: (item: Order) => void;
  onShowTransferTicket: (item: Order) => void;
  onSubmitFilter: () => void;
  onClearFilter: () => void;
  onPressTutotialApp: () => void;
}

type TicketsOrderProps = StatusProps & DispatchProps;

export interface DataRow {
  id: string;
  imageBase64: string;
  name: string;
  buyDate: string;
  startDate: string;
  statusPayment: string;
  price: number;
  status: string;
  actions: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: '',
    selector: (row: { imageBase64: any }) => row.imageBase64,
    width: '100px',
  },
  {
    name: 'Nome do evento',
    selector: (row: { name: any }) => row.name,
    width: '200px',
  },
  {
    name: 'Data da compra',
    selector: (row: { buyDate: any }) => row.buyDate,
  },
  {
    name: 'Data do evento',
    selector: (row: { startDate: any }) => row.startDate,
  },
  {
    name: 'Pagamento',
    selector: (row: { statusPayment: any }) => row.statusPayment,
    width: '120px',
  },
  {
    name: 'Valor',
    selector: (row: { price: any }) => row.price,
    width: '140px',
  },
  {
    name: '',
    selector: (row: { status: any }) => row.status,
    width: '130px',
  },
  {
    name: '',
    selector: (row: { actions: any }) => row.actions,
    width: '60px',
  },
];

export const TicketsOrder = ({
  state,
  orders,
  order,
  controllerModalTicket,
  controllerCancelTicketAfter,
  onSubmitCancelTicket,
  onShowDetailTicket,
  controllerFilter,
  onSubmitFilter,
  onClearFilter,
  onPressTutotialApp,
}: TicketsOrderProps) => {
  const getPaymentStatus = (status: PaymentStatus): string => {
    let s = '';
    switch (status) {
      case PaymentStatus.APPROVED:
        s = 'Aprovado';
        break;
      case PaymentStatus.DISAPPROVED:
        s = 'Reprovado';
        break;
      case PaymentStatus.AWAITING_PAYMENT:
        s = 'Aguardando Pagamento';
        break;
      case PaymentStatus.CANCELED:
        s = 'Cancelado';
        break;
      case PaymentStatus.CANCELLATION_ANALYSIS:
        s = 'Solicitação de cancelamento em analise';
        break;
      case PaymentStatus.EXPIRED:
        s = 'Expirado';
        break;
      default:
        s = '';
        break;
    }
    return s;
  };
  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const getTicketStatus = (order: Order): string => {
    let s = 'Disponível';
    if (order.paymentStatus === PaymentStatus.CANCELED) {
      s = 'Cancelado';
    } else if (order.paymentStatus === PaymentStatus.DISAPPROVED) {
      s = 'Reprovado';
    } else if (order.paymentStatus === PaymentStatus.EXPIRED) {
      s = 'Expirado';
    } else if (order.paymentStatus === PaymentStatus.AWAITING_PAYMENT) {
      s = 'Aguardando Pagamento';
    } else if (order.paymentStatus === PaymentStatus.APPROVED) {
      for (let e = 0; e < order.events.length; e++) {
        for (let i = 0; i < order.events[e].items.length; i++) {
          if (order.events[e].items[i].useDate) {
            s = 'Utilizado';
            break;
          } else if (order.events[e].items[i].transferDate) {
            s = 'Transferido';
            break;
          }
        }
      }
    }
    return s;
  };

  const ElementStatus: React.FC<ElementStateProps> = ({
    order,
  }): JSX.Element => {
    const test = getTicketStatus(order);
    let classText = 'order-table-tbody-td-child ';

    if (test === 'Disponível') {
      classText += 'bg-status-nao-utilizado ml-2';
    } else if (test === 'Cancelado') {
      classText += 'bg-status-cancelado ml-2';
    } else if (test === 'Transferido') {
      classText += 'bg-status-transferido ml-2';
    } else if (test === 'Aguardando Pagamento') {
      classText += 'bg-status-await ml-2';
    }
    return (
      <div className="order-table-tbody-td-child flex text-center align-middle">
        <span className="font-bold">Status:</span>
        <div className={classText}> {test}</div>
      </div>
    );
  };

  const mountEventName = (events: OrderEvent[]): string | JSX.Element => {
    if (events.length === 1) {
      return events[0].event.name;
    } else {
      return (
        <>
          {events.map((data, index) => (
            <>
              <span key={`eventNameSpan-${data.event.id}`}>
                {data.event.name}
              </span>
              {index < events.length - 1 && <br />}
            </>
          ))}
        </>
      );
    }
  };

  // const actions: ActionProps[] = [
  //   {
  //     title: 'Ver detalhe',
  //     onClick: () => onShowDetailTicket(item),
  //     icon: <IconInfo className="h-[19px]" />,
  //   },
  //   {
  //     title: 'Imprimir',
  //     onClick: () => onShowPrintTicket(item),
  //     icon: <IconTicket className="h-[19px]" />,
  //     hidden: !item.showPrint,
  //   },
  //   {
  //     title: 'Transferir Ingressos',
  //     onClick: () => onShowTransferTicket(item),
  //     icon: <IconTransfer className="h-[22px]" />,
  //     hidden: !item.showTransfer,
  //   },
  //   {
  //     className: 'text-primary-600',
  //     title: 'Cancelar Ingressos',
  //     onClick: () => onShowCancelTicket(item),
  //     icon: <IconClose className="h-[14px] text-primary-600" />,
  //     hidden: !item.showCancel,
  //   },
  // ];

  const dataTicket = orders.list
    ? orders.list.map((item) => {
        // const actions: ActionProps[] = [
        //   {
        //     title: 'Ver detalhe',
        //     onClick: () => onShowDetailTicket(item),
        //     icon: <IconInfo className="h-[19px] mr-1 mt-1" />,
        //   },
        // {
        //   title: 'Imprimir',
        //   onClick: () => onShowPrintTicket(item),
        //   icon: <IconTicket className="h-[19px] ml-1 mr-2  mt-1" />,
        //   hidden: !item.showPrint,
        // },
        // {
        //   title: 'Transferir Ingressos',
        //   onClick: () => onShowTransferTicket(item),
        //   icon: <IconTransfer className="h-[22px] mr-1 mt-1" />,
        //   hidden: !item.showTransfer,
        // },
        // {
        //   className: 'text-primary-600',
        //   title: 'Cancelar Ingressos',
        //   onClick: () => onShowCancelTicket(item),
        //   icon: (
        //     <IconClose className="h-[14px] text-primary-600 ml-1 mr-1 mt-1" />
        //   ),
        //   hidden: !item.showCancel,
        // },
        // ];

        // let showPrintTicket = false;
        // if (item.events && item.events.length > 0) {
        //   const now = new Date();
        //   for (let e = 0; e < item.events.length; e++) {
        //     const startDate = new Date(item.events[e].event.startDate);
        //     const endDate = new Date(item.events[e].event.endDate);
        //     const diffStartDate =
        //       (startDate.getTime() - now.getTime()) / 1000 / 60 / 60;
        //     const diffEndDate =
        //       (endDate.getTime() - now.getTime()) / 1000 / 60 / 60;
        //     if (diffStartDate <= 24 && diffEndDate >= 0) {
        //       showPrintTicket = true;
        //     }
        // }
        //}

        return {
          id: item.id,
          thumbnail: item.events[0].event.imageUrl,
          name: mountEventName(item.events),
          startDate: dayjs(item.events[0].event.startDate)
            .locale('pt-br')
            .format('DD/MM/YYYY'),
          status: <ElementStatus order={item} />,
          statusPayment: (
            <span title={getPaymentStatus(item.paymentStatus)}>
              {getPaymentStatus(item.paymentStatus)}
            </span>
          ),
          option: (
            <React.Fragment>
              {/* {showPrintTicket && (
                <div className="flex justify-center" key={item.id}>
                  {item.showPrint && (
                    <button
                      className="text-18 text-center text-gray-600 bg-red-400 hover:bg-red-700 text-white font-bold py-2 px-3 mr-2 rounded cursor-pointer"
                      onClick={() => onShowPrintTicket(item)}
                    >
                      Imprimir
                    </button>
                  )}

                </div>
              )} */}
              {/* <DropdownButton title="Ver Mais" actions={actions} /> */}
              <button
                className="text-18 text-center text-gray-600 bg-gray-400 hover:bg-gray-700 text-white font-bold py-2 px-3 mr-2 rounded cursor-pointer"
                onClick={() => onShowDetailTicket(item)}
              >
                Ver Detalhes
              </button>
            </React.Fragment>
          ),
        };
      })
    : [];

  // const dataTable = orders.list
  //   ? orders.list.map((item) => ({
  //       id: item.id,
  //       imageBase64: <ColumnImage orderEvents={item.events} />,
  //       name: mountEventName(item.events),
  //       buyDate: dayjs(item.orderDate).locale('pt-br').format('DD/MM/YYYY'),
  //       startDate: dayjs(item.events[0].event.startDate)
  //         .locale('pt-br')
  //         .format('DD/MM/YYYY'),
  //       statusPayment: (
  //         <span title={getPaymentStatus(item.paymentStatus)}>
  //           {getPaymentStatus(item.paymentStatus)}
  //         </span>
  //       ),
  //       price: toCurrency(item.totalValue),
  //       status: <ElementStatus order={item} />,
  //       actions: (
  //         <div>
  //   <DropdownMenu
  //             title={<IconMenu className="h-[24px]" />}
  //             actions={[
  //               {
  //                 title: 'Ver detalhe',
  //                 onClick: () => onShowDetailTicket(item),
  //                 icon: <IconInfo className="h-[19px]" />,
  //               },
  //               {
  //                 title: 'Imprimir',
  //                 onClick: () => onShowPrintTicket(item),
  //                 icon: <IconTicket className="h-[19px]" />,
  //                 hidden: !item.showPrint,
  //               },
  //               {
  //                 title: 'Transferir Ingressos',
  //                 onClick: () => onShowTransferTicket(item),
  //                 icon: <IconTransfer className="h-[22px]" />,
  //                 hidden: !item.showTransfer,
  //               },
  //               {
  //                 className: 'text-primary-600',
  //                 title: 'Cancelar Ingressos',
  //                 onClick: () => onShowCancelTicket(item),
  //                 icon: <IconClose className="h-[14px] text-primary-600" />,
  //                 hidden: !item.showCancel,
  //               },
  //             ]}
  //           />
  //         </div>
  //       ),
  //   }))
  // : [];

  const [openFilter, setOpenFilter] = useState(false);

  const [value, setValue] = useState<string>('Data');

  const radioFilters = [
    { id: 'date', title: 'Data da compra' },
    { id: 'name', title: 'Nome do evento' },
  ];

  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full p-7 md:px-0 container mx-auto py-24">
        <header className="flex flex-wrap items-center mb-[70px]">
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center">
              <IconTicket className="mr-[20px] h-[30px]" />
              <h1 className="font-dmsans font-medium text-[37px]">
                Meus Pedidos
              </h1>
            </div>
            {/*
            <div className="items-center hidden sm:flex">

              <Link to={'/ingressos/historico'}>
                Ver histórico de ingressos
              </Link>
              <IconFilter
                className="ml-[20px] h-[17px] text-neutr  o-w-600"
                onClick={() => setOpenFilter(true)}
              />
            </div>
            */}
          </div>
          {/* <TutorialApp /> */}

          {/* <p className="w-full flex items-center text-[21px] font-dmsans font-normal text-neutro-w-600">
            Acompanhe seu ingressos aqui:
          </p> */}

          <p
            className="w-full ml-[25px] items-center text-[18px] font-dmsans font-normal text-blue-600 cursor-pointer"
            onClick={() => onPressTutotialApp()}
          >
            - Como visualizar seu ingresso.
          </p>
          {/* <div className="w-full flex justify-between items-center sm:hidden mt-10">
            <Link to={'/ingressos/historico'}>Ver histórico de ingressos</Link>
            <IconFilter
              className="ml-[20px] h-[17px] text-neutro-w-600"
              onClick={() => setOpenFilter(true)}
            />
          </div> */}
        </header>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
          {dataTicket.map((item, index) => (
            <TicketCard key={index} ticketData={item} />
          ))}
        </div>
        {/* <div className="font-dmsans">
          <CustomTable
            columns={columns}
            data={dataTable}
            numberRowsPerPage={2}
            progressPending={state}
            pagination
          />
        </div> */}
      </div>

      <Modal
        visible={controllerModalTicket.visible}
        title={controllerModalTicket.title}
        onSetVisible={controllerModalTicket.onToggleModal}
        actions={[]}
        className="md:w-[1038px]"
      >
        {
          {
            /*
            [ShowModalTickets.TICKET_CANCEL_BEFORE]: (
              <ConfirmCancelTicketBeforeContent
                order={order}
                toCurrency={toCurrency}
                onCancelConfirm={
                  controllerCancelTicketBefore.onCancelTicketCancel
                }
                onOkConfirm={controllerCancelTicketBefore.onSubmitTicketCancel}
              />
            ),
            */
            [ShowModalTickets.TICKET_CANCEL_AFTER]: (
              <ConfirmCancelTicketAfterContent
                order={order}
                toCurrency={toCurrency}
                state={state}
                controllerCancelTicketAfter={controllerCancelTicketAfter}
                onSubmit={onSubmitCancelTicket}
              />
            ),
          }[controllerModalTicket.shouldShowModal]
        }
      </Modal>
      <Dialog.Root open={openFilter} onOpenChange={setOpenFilter}>
        <Dialog.Portal className="relative z-10">
          <Dialog.Overlay className="fixed inset-0 bg-modal-overlay">
            <Dialog.Content className="fixed inset-0 z-10">
              <div
                className="flex h-full items-start justify-end text-left sm:items-center sm:p-0
            md:radix-state-closed:translate-x-[415px]
            md:radix-state-open:translate-x-0 animate-enter-from-right"
              >
                <div
                  className="relative z-50 pt-[46px] w-full h-full md:w-[415px]
                bg-white font-dmsans shadow-xl rounded-lg"
                >
                  <div className="flex flex-col justify-between items-center font-dmsans">
                    <div className="self-end p-0 pr-[40px]">
                      <Dialog.Close asChild>
                        <img
                          src={Close}
                          className="cursor-pointer"
                          alt="button closer"
                        />
                      </Dialog.Close>
                    </div>
                  </div>
                  <div className="mx-[30px]">
                    <form>
                      <legend className="text-[21px] text-neutro-b-400">
                        Pesquisar por:
                      </legend>
                      <div className="mb-[50px]">
                        <RadioGroupPrimitive.Root
                          aria-label="filtra eventos"
                          defaultValue={'Data da compra'}
                          onValueChange={setValue}
                        >
                          <div className="mt-[20px] space-y-5">
                            {radioFilters.map((filter) => (
                              <div
                                key={filter.id}
                                className="flex items-center"
                              >
                                <RadioGroupPrimitive.Item
                                  id={filter.id}
                                  value={filter.title}
                                  className={classNames(
                                    'peer relative w-[22px] h-[22px] rounded-full',
                                    // Setting the background in dark properly requires a workaround (see css/tailwind.css)
                                    'border border-primary-400 text-primary-400',
                                    'radix-state-checked:bg-white radix-state-checked:border-primary-400',
                                    'radix-state-unchecked:bg-white dark:radix-state-unchecked:border-neutro-w-400',
                                    'focus:outline-none focus:ring-2 focus:ring-offset-0 focus-visible:ring focus-visible:ring-primary-400 focus-visible:ring-opacity-75 focus-visible:ring-offset-0'
                                  )}
                                >
                                  <RadioGroupPrimitive.Indicator className="absolute inset-0 flex items-center justify-center leading-0">
                                    <div className="w-[14px] h-[14px] rounded-full bg-primary-400"></div>
                                  </RadioGroupPrimitive.Indicator>
                                </RadioGroupPrimitive.Item>
                                <label
                                  htmlFor={filter.id}
                                  className="ml-[18px] block text-base font-normal text-neutro-b-400"
                                >
                                  {filter.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </RadioGroupPrimitive.Root>
                      </div>
                      {value === 'Data da compra' && (
                        <div className="mb-[50px]">
                          <InputText
                            name="date"
                            label="Data da compra"
                            placeholder="DD/MM/AAAA"
                            className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                            value={
                              controllerFilter.formData[
                                FormInputNameFilter.date
                              ]
                            }
                            onChange={(e) =>
                              controllerFilter.onChangeFormInput(
                                FormInputNameFilter.date
                              )(e.target.value)
                            }
                            error={
                              controllerFilter.formErrors.date &&
                              controllerFilter.formErrors.date[0]
                            }
                          />
                        </div>
                      )}
                      {value === 'Nome do evento' && (
                        <div className="mb-[50px]">
                          <InputText
                            name="name"
                            label="Nome do evento"
                            placeholder="Nome do evento"
                            className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                            value={
                              controllerFilter.formData[
                                FormInputNameFilter.name
                              ]
                            }
                            onChange={(e) =>
                              controllerFilter.onChangeFormInput(
                                FormInputNameFilter.name
                              )(e.target.value)
                            }
                            error={
                              controllerFilter.formErrors.name &&
                              controllerFilter.formErrors.name[0]
                            }
                          />
                        </div>
                      )}
                      <div className="flex justify-end items-center space-x-5">
                        <Button
                          type="button"
                          title="Limpar"
                          buttonStyle="text"
                          size="xlg"
                          onClick={onClearFilter}
                        />
                        <Button
                          type="button"
                          title="Aplicar"
                          buttonStyle="primary"
                          size="lg"
                          onClick={() => {
                            onSubmitFilter();
                            setOpenFilter(false);
                          }}
                          disabled={
                            controllerFilter.formData[
                              FormInputNameFilter.date
                            ] === '' &&
                            controllerFilter.formData[
                              FormInputNameFilter.name
                            ] === ''
                          }
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </AuthWrapper>
  );
};
