import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import Img1 from 'src/assets/images/img-1.png';
import Img2 from 'src/assets/images/img-2.png';

export const AboutContainer = () => {
  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full container mx-auto pt-[54px] pb-[100px] font-dmsans">
        <h1 className="text-primary-500 text-[37px] font-bold mb-[70px]">
          Quem somos
        </h1>
        <div>
          <div className="md:flex justify-center items-center mb-40 lg:mb-0">
            <img
              src={Img2}
              alt="quem somos 2"
              className="h-[300px] w-full object-cover mb-10 lg:h-auto"
            />
            <div className="text-neutro-b-400 lg:leading-[55.5px] sm:text-[27px] lg:text-[37px] font-bold sm:ml-5 lg:ml-[65px]">
              Queremos revolucionar o universo de compra online de ingressos por
              um modo mais simples e descomplicado.
            </div>
          </div>
          <div className="md:flex flex-row-reverse justify-center items-center lg:relative lg:-top-[100px]">
            <img
              src={Img1}
              alt="quem somos 1"
              className="h-[300px] w-full object-cover mb-10 lg:h-auto lg:relative lg:-top-20 lg:-left-[60px]"
            />
            <div className="text-neutro-w-600 lg:leading-[42px] sm:text-[22px] lg:text-[28px] font-normal sm:mr-5 lg:mr-[110px]">
              A Banca do Ingresso é uma empresa criada em 2009 com o objetivo de
              proporcionar a seus clientes todo{' '}
              <strong>conforto, agilidade e confiabilidade</strong> do princípio
              ao fim de cada evento. O nosso maior evento é ver você aproveitar
              a vida.
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
