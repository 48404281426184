import { TicketPrintContainer } from './ui';
import { useHistory, useParams } from 'react-router-dom';
import Order from 'src/model/Order';
import { useEffect, useState } from 'react';
import { api } from 'src/services/api';
import { toast } from 'react-toastify';
import OrderItem from 'src/model/OrderItem';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import OrderItemTicket from 'src/model/OrderItemTicket';
import StatusType from 'src/model/StatusType';

interface EventParam {
  id: string;
}

export const TicketPrintScreen = () => {
  const { id } = useParams<EventParam>();
  const [order, setOrder] = useState<Order>({} as Order);
  const [countTickets, setCountTickets] = useState<number>(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const getOrder = async (orderId: string) => {
    dispatch(setLoading(true));
    const response = await api.get<Order>(`/client/order/${orderId}`);
    setOrder(response.data);
    let found = false;
    let amount = 0;
    response.data.events.forEach((event) => {
      event.items.forEach((item) => {
        if (item.statusType === StatusType.ACTIVE) {
          amount++;
          found = true;
        }
      });
    });
    if (!found) {
      toast.warn('Você não possui ingressos para transferir!');
      setTimeout(() => {
        history.goBack();
      }, 1000);
    } else {
      setCountTickets(amount);
    }
    dispatch(setLoading(false));
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const handleOnPrint = async (orderItem: OrderItem): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<OrderItemTicket>(
      `/client/order/${order.id}/ticket/${orderItem.id}`
    );
    window.open(response.data.itemUrl);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (id && id.trim().length > 0) {
      getOrder(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      {order && order.id && (
        <TicketPrintContainer
          order={order}
          countTickets={countTickets}
          toCurrency={toCurrency}
          onPrint={handleOnPrint}
        />
      )}
    </>
  );
};
