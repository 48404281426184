import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateMask } from 'src/helpers/masks/cpf';
import { updateMask as updatePhoneMask } from 'src/helpers/masks/mobilePhone';
import Order from 'src/model/Order';
import OrderItemType from 'src/model/OrderItemType';
import OrderPayment from 'src/model/OrderPayment';
import PaymentType from 'src/model/PaymentType';
import { api } from 'src/services/api';
import { TicketDetailContainer } from './ui';

interface EventParam {
  id: string;
}

export const TicketDetailScreen = () => {
  const { id } = useParams<EventParam>();
  const [order, setOrder] = useState<Order>({} as Order);
  const [showTickets, setShowTickets] = useState<boolean>(false);
  const [showProducts, setShowProducts] = useState<boolean>(false);

  const getOrder = async (orderId: string) => {
    const response = await api.get<Order>(`/client/order/${orderId}`);
    setOrder(response.data);
    let hasTickets = false;
    let hasProducts = false;
    response.data.events.forEach((data) => {
      data.items.forEach((item) => {
        if (item.itemType === OrderItemType.TICKET) {
          hasTickets = true;
        } else if (item.itemType === OrderItemType.PRODUCT) {
          hasProducts = true;
        }
      });
    });
    setShowTickets(hasTickets);
    setShowProducts(hasProducts);
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const toPaymentType = (paymentType: PaymentType): string => {
    let s = '';
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        s = 'Cartão de crédito';
        break;
      case PaymentType.TWO_CREDIT_CARDS:
        s = 'Cartão de crédito';
        break;
      case PaymentType.DEBIT_CARD:
        s = 'Cartão de débito';
        break;
      case PaymentType.PIX:
        s = 'PIX';
        break;
      case PaymentType.BANK_SLIP:
        s = 'Boleto Bancário';
        break;
      case PaymentType.MONEY:
        s = 'Dinheiro';
        break;
      default:
        break;
    }
    return s;
  };

  const mountCreditCardDescription = (orderPayment: OrderPayment): string => {
    let s = '';
    if (
      orderPayment.paymentType === PaymentType.CREDIT_CARD ||
      orderPayment.paymentType === PaymentType.TWO_CREDIT_CARDS
    ) {
      s = `**** **** **** ${orderPayment.clientCardNumber} - ${orderPayment.creditCardBrand} `;
    }
    return s;
  };

  const toCPF = (value: string): string => {
    if (value) {
      return updateMask(value);
    } else {
      return '';
    }
  };

  const toPhone = (value: string): string => {
    if (value) {
      return updatePhoneMask(value);
    } else {
      return undefined as unknown as string;
    }
  };

  useEffect(() => {
    if (id && id.trim().length > 0) {
      getOrder(id);
    }
  }, [id]);
  return (
    <>
      {order && order.id && (
        <TicketDetailContainer
          order={order}
          showTickets={showTickets}
          showProducts={showProducts}
          toCurrency={toCurrency}
          toPaymentType={toPaymentType}
          mountCreditCardDescription={mountCreditCardDescription}
          toCPF={toCPF}
          toPhone={toPhone}
        />
      )}
    </>
  );
};
