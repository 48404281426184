import React from 'react';
import { Route } from 'src/navigation/Route';
import { ProfileScreen } from './screens/Profile';

export const PROFILE_ROUTES = {
  profile: '/profile',
};

export const ProfileNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={PROFILE_ROUTES.profile} component={ProfileScreen} />
  </React.Fragment>
);
