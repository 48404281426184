import React, { FC, InputHTMLAttributes, LegacyRef } from 'react';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  value: string;
  label?: React.ReactNode | string;
  error?: string | null | undefined;
  rows?: number;
  wrapperClass?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  renderForward?: React.ReactNode;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  refTextArea?: LegacyRef<HTMLTextAreaElement>;
}

export const TextArea: FC<TextAreaProps> = ({
  name,
  value,
  placeholder,
  error,
  label,
  maxLength,
  rows,
  onBlur,
  onChange,
  wrapperClass,
  renderForward,
  disabled,
  refTextArea,
}) => (
  <div
    className={`flex-column input-component ${wrapperClass} ${
      disabled ? 'input-disabled' : ''
    } ${error ? 'input-error' : ''}`}
  >
    {label && (
      <label
        htmlFor={name}
        className="block text-label text-base font-dmsans font-regular mb-2"
      >
        {label}
      </label>
    )}
    <textarea
      aria-invalid={error ? 'true' : 'false'}
      value={value}
      placeholder={placeholder ?? 'Digite aqui'}
      maxLength={maxLength}
      rows={rows}
      onBlur={onBlur}
      onChange={onChange}
      className={`appearance-none border rounded w-full py-9 px-[30px] text-input bg-white text-base leading-tight focus:outline-none focus:shadow-outline ${
        error ? 'border-red-500' : ''
      }`}
      disabled={disabled}
      ref={refTextArea}
    />
    {renderForward}
    {error && (
      <span role="alert" className="alert-error position-relative text-sm mb-2">
        {error}
      </span>
    )}
  </div>
);
