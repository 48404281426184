interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconTrash = (props: IconProps) => (
  <svg
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M11.4159 4.86523H0.720363C0.32325 4.86523 0 4.54223 0 4.14524V2.61708C0 2.22009 0.323124 1.8971 0.720363 1.8971L11.4159 1.89697C11.813 1.89697 12.1361 2.21997 12.1361 2.61696V4.14512C12.136 4.54223 11.813 4.86523 11.4159 4.86523V4.86523ZM0.720363 2.5734C0.696933 2.5734 0.676447 2.59388 0.676447 2.61695V4.1451C0.676447 4.16817 0.696934 4.18865 0.720363 4.18865L11.4159 4.18878C11.4392 4.18878 11.4597 4.16829 11.4597 4.14523V2.61707C11.4597 2.59401 11.4392 2.57352 11.4159 2.57352L0.720363 2.5734Z"
      fill="currentColor"
    />
    <path
      d="M8.19048 2.57348H3.9389L3.88591 2.2993C3.87916 2.26458 3.87695 2.22336 3.87695 2.18926C3.87695 0.982248 4.85908 0 6.06609 0C7.27322 0 8.25535 0.982248 8.25535 2.18939C8.25535 2.2122 8.25535 2.25514 8.24541 2.30298L8.19048 2.57348ZM4.58207 1.89705H7.5508C7.41438 1.20222 6.80051 0.676441 6.0663 0.676441C5.33208 0.676441 4.71847 1.20222 4.58204 1.89705H4.58207Z"
      fill="currentColor"
    />
    <path
      d="M9.00081 16.2339H3.13471C2.35646 16.2339 1.70591 15.6256 1.65403 14.849L0.9375 4.18896H11.1978L10.4814 14.8493C10.4293 15.6259 9.77877 16.2338 9.00065 16.2338L9.00081 16.2339ZM1.66088 4.86536L2.32886 14.8037C2.3572 15.2266 2.71112 15.5574 3.13471 15.5574H9.00081C9.42439 15.5574 9.77845 15.2265 9.8069 14.8037L10.4748 4.86536H1.66088Z"
      fill="currentColor"
    />
    <path
      d="M6.0687 14.436C5.88186 14.436 5.73047 14.2847 5.73047 14.0977V6.32505C5.73047 6.13809 5.88185 5.98682 6.0687 5.98682C6.25555 5.98682 6.40693 6.13807 6.40693 6.32505V14.0977C6.40693 14.2847 6.25555 14.436 6.0687 14.436Z"
      fill="currentColor"
    />
    <path
      d="M4.0394 13.3422C3.85257 13.3422 3.70117 13.1909 3.70117 13.004V7.41831C3.70117 7.23135 3.85255 7.08008 4.0394 7.08008C4.22624 7.08008 4.37763 7.23134 4.37763 7.41831V13.004C4.37763 13.1909 4.22625 13.3422 4.0394 13.3422Z"
      fill="currentColor"
    />
    <path
      d="M8.09799 13.3422C7.91116 13.3422 7.75977 13.1909 7.75977 13.004V7.41831C7.75977 7.23135 7.91115 7.08008 8.09799 7.08008C8.28483 7.08008 8.43623 7.23134 8.43623 7.41831V13.004C8.4361 13.1909 8.28472 13.3422 8.09799 13.3422Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
