import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import EventCategorySite from 'src/model/EventCategorySite';
import EventSite from 'src/model/EventSite';
import { useEventStore } from 'src/redux/event/eventSlice';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { EventByCategoryContainer } from './ui';

interface EventCategoryParam {
  id: string;
  name: string;
}

export const EventByCategoryScreen = () => {
  const { id } = useParams<EventCategoryParam>();
  const [search, setSearch] = useState('');
  const [eventCategory, setEventCategory] = useState<EventCategorySite>(
    {} as EventCategorySite
  );
  const [eventsFiltred, setEventsFiltred] = useState<EventSite[]>([]);
  const { events } = useSelector(useEventStore);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  const getEventByCategory = async (idCategory: string): Promise<void> => {
    try {
      dispatch(setLoading(true));

      if (idCategory === 'numberAccesses') {
        //const list: EventCategorySite[] = [];
        const listEventSite: EventSite[] = [];
        events.forEach((data) => {
          //list.push(data);
          data.events.forEach((event) => {
            listEventSite.push(event);
          });
        });

        const sortedList = listEventSite.sort((a, b) => {
          if (a.numberAccesses > b.numberAccesses) {
            return -1;
          } else if (a.numberAccesses < b.numberAccesses) {
            return 1;
          } else {
            return 0;
          }
        });

        const listNew: EventSite[] = [];
        for (let i = 0; i < sortedList.length; i++) {
          if (i < 10) {
            listNew.push(sortedList[i]);
          } else {
            break;
          }
        }
        setEventsFiltred(listNew);
        const newCategory: EventCategorySite = {
          id: 'numberAccesses',
          name: 'Festivais mais acessados',
          count: listNew.length,
          events: listNew,
        };
        //list.push(newCategory);
        setEventCategory(newCategory);
      } else {
        const eventByCategory: EventCategorySite[] = events.filter(
          (eventCategory: EventCategorySite) => eventCategory.id === idCategory
        );
        eventByCategory.length > 0
          ? setEventCategory(eventByCategory[0])
          : setEventCategory({} as EventCategorySite);
        if (eventByCategory.length > 0) {
          const listEvents: EventSite[] = [];
          eventByCategory[0].events.forEach((data) => {
            listEvents.push(data);
          });
          setEventsFiltred(listEvents);
        }
      }

      dispatch(setLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setLoading(false));
    }
  };

  const handleClickSearch = (): void => {
    const listEvents: EventSite[] = [];
    eventCategory.events.forEach((data) => {
      if (data.name.toLowerCase().includes(search.toLowerCase())) {
        listEvents.push(data);
      }
    });
    setEventsFiltred(listEvents);
  };

  const handleChangeInputSearch = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearch(e.target.value);
    const listEvents: EventSite[] = [];
    eventCategory.events.forEach((data) => {
      if (data.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        listEvents.push(data);
      }
    });
    setEventsFiltred(listEvents);
  };

  const handleSelectEvent = (event: EventSite): void => {
    history.replace(`/event/${event.id}`);
  };

  const handleClearInputSearch = (): void => {
    setSearch('');
    if (
      eventCategory &&
      eventCategory.events &&
      eventCategory.events.length > 0
    ) {
      const listEvents: EventSite[] = [];
      eventCategory.events.forEach((data) => {
        listEvents.push(data);
      });
      setEventsFiltred(listEvents);
    }
  };

  useEffect(() => {
    getEventByCategory(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EventByCategoryContainer
      state={loading}
      search={search}
      eventCategory={eventCategory}
      events={eventsFiltred}
      onClickSearch={handleClickSearch}
      onChangeInputSearch={handleChangeInputSearch}
      onSelectEvent={handleSelectEvent}
      onClearInputSearch={handleClearInputSearch}
    />
  );
};
