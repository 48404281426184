import React from 'react';
import { useState } from 'react';
import { classNames } from 'src/helpers/common';

interface TabLinksProps {
  tabs: string[];
  contents: React.ReactNode[];
}

export const TabLinks = ({ tabs, contents }: TabLinksProps) => {
  const [tabActive, setTabActive] = useState(1);

  return (
    <div className="tabs">
      <nav className="tabs__nav space-x-5 md:space-x-10">
        {tabs.map((tab: string, index: number) => (
          <a
            key={index + 1}
            onClick={() => setTabActive(index + 1)}
            className={classNames(
              tabActive === index + 1
                ? 'text-link-active after:w-4/5 after:h-1 after:bg-link-active after:rounded after:absolute after:bottom-0 after:left-0'
                : 'text-placeholder  hover:text-link-active',
              'relative font-dmsans font-medium py-3 cursor-pointer leading-10 md:leading-0'
            )}
          >
            {tab}
          </a>
        ))}
      </nav>
      <div className="tabs__content mt-8">
        {contents.map((content: React.ReactNode, index: number) => (
          <div
            key={index + 1}
            className={classNames(
              tabActive === index + 1 ? 'block' : 'hidden',
              'w-full'
            )}
          >
            {content}
          </div>
        ))}
      </div>
    </div>
  );
};
