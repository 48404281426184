import React from 'react';
import { Route } from 'src/navigation/Route';
import { TicketDetailScreen } from './screens/TicketDetail';
import { TicketsScreen } from './screens/Tickets';
import { TicketsHistoryScreen } from './screens/TicketsHistory';
import { TicketPrintScreen } from './screens/TicketPrint';
import { TicketTransferScreen } from './screens/TicketTransfer';
import { TicketsOrderScreen } from './screens/TicketOrder';

export const TICKETS_ROUTES = {
  tickets: '/ingressos',
  ticketsHistory: '/ingressos/historico',
  ticketPrint: '/ingressos/imprimir/:id',
  ticketTransfer: '/ingressos/tranferir/:id',
  ticketDetail: '/ingressos/detalhe/:id',
  ticketOrder: '/ingressos/pedidos',
};

export const TicketsNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={TICKETS_ROUTES.ticketDetail} component={TicketDetailScreen} />
    <Route path={TICKETS_ROUTES.ticketPrint} component={TicketPrintScreen} />
    <Route
      path={TICKETS_ROUTES.ticketTransfer}
      component={TicketTransferScreen}
    />
    <Route
      path={TICKETS_ROUTES.ticketsHistory}
      component={TicketsHistoryScreen}
    />
    <Route path={TICKETS_ROUTES.ticketOrder} component={TicketsOrderScreen} />
    <Route path={TICKETS_ROUTES.tickets} component={TicketsScreen} />
  </React.Fragment>
);
