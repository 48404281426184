import Barcode from 'react-barcode';
import { IconTicketPayment } from 'src/assets/icons';
import { Button } from 'src/components';

interface StateProps {
  boleto: string;
  barcode: string;
}

interface DispatchProps {
  onCopyText: (text: string) => void;
}

type Props = StateProps & DispatchProps;

export const TicketPaymentContent: React.FC<Props> = ({
  boleto,
  barcode,
  onCopyText,
}): JSX.Element => {
  return (
    <div className="flex flex-col justify-center text-center">
      <IconTicketPayment
        height="86px"
        className="self-center text-alert-info-soft mb-[20px]"
      />
      <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] ">
        Pague o boleto para concluir sua compra
      </h4>
      <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[20px]">
        Para pagar o boleto no seu internet banking escaneie o código de barras
        usando a câmera do seu celular, ou copie e cole o código de barras no
        seu banco ou aplicativo de pagamento
      </p>
      <div className="mb-[20px] mx-auto">
        <Barcode value={barcode} width={1.8} />
      </div>
      <div className="grid grid-cols-2 gap-5">
        <Button
          type="button"
          title="Imprimir boleto"
          buttonStyle="outlined"
          size="xlg"
          className="w-full mb-[20px]"
          onClick={() => window.open(boleto)}
        />
        <Button
          type="button"
          title="Copiar código de barras"
          buttonStyle="primary"
          size="xlg"
          className="w-full mb-[20px]"
          onClick={() => {
            onCopyText(barcode);
          }}
        />
      </div>
    </div>
  );
};
