import { Button } from 'src/components';

interface Props {
  onCancelConfirm: () => void;
  onOkConfirm: () => void;
}

export const ConfirmDeleteCreditCardContent = ({
  onCancelConfirm,
  onOkConfirm,
}: Props) => {
  return (
    <div className="w-full mx-auto text-center">
      <div className="flex flex-col justify-center">
        <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
          Você tem certeza que quer excluir seu cartão?
        </h4>
        <div className="grid grid-cols-2 gap-5">
          <Button
            type="button"
            title="Não"
            buttonStyle="outlined"
            size="md"
            className="w-full mb-[25px]"
            onClick={onCancelConfirm}
          />
          <Button
            type="button"
            title="Sim"
            buttonStyle="primary"
            size="md"
            className="w-full mb-[25px]"
            onClick={() => onOkConfirm()}
          />
        </div>
      </div>
    </div>
  );
};
