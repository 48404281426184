import { Trash } from 'src/assets/icons';
import { EventTicketSite } from 'src/features/event/screens/Event';

interface TicketIncrementResumeProps {
  item: EventTicketSite;
  cameFromAdm?: boolean;
  increment(item: EventTicketSite): void;
  decrement(item: EventTicketSite): void;
}

export function TicketIncrementResume({
  item,
  cameFromAdm,
  increment,
  decrement,
}: TicketIncrementResumeProps) {
  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const mountText = (quantity: number, value: number, fee: number): string => {
    let s = '';
    if (fee && fee > 0) {
      s = ` + Taxa: ${toCurrency(quantity * value * fee - quantity * value)}`;
    }
    return s;
  };

  return (
    <div className="flex justify-between py-4">
      <div className="text-left">
        <div className="font-dmsans font-normal text-[21px] leading-[27px] text-neutro-b-400">
          {item.name}
        </div>
        <div className="font-dmsans font-normal text-base text-neutro-w-600">
          <span>
            {`${toCurrency(item.quantity * item.unitValue)}${mountText(
              item.quantity,
              item.unitValue,
              item.fee
            )}`}
          </span>
        </div>
      </div>
      <div className="flex justify-center items-center">
        {!cameFromAdm && (
          <>
            {item.quantity >= 2 ? (
              <button
                onClick={() => decrement(item)}
                disabled={item.quantity == 0}
                className="disabled:border-[#ECECEC] disabled:text-[#ECECEC] disabled:cursor-not-allowed border border-[#C4C4C4] rounded-[5px] w-[18px] h-[18px] flex justify-center items-center text-sm leading-[18px] text-placeholder"
              >
                -
              </button>
            ) : (
              ''
            )}
          </>
        )}
        {!cameFromAdm && (
          <>
            {item.quantity <= 1 ? (
              <button
                onClick={() => decrement(item)}
                disabled={item.quantity == 0}
                className="w-[18px] h-[18px] flex justify-center items-center"
              >
                <img
                  src={Trash}
                  className="fill-cyan-500"
                  alt="Remover produto"
                />
              </button>
            ) : (
              ''
            )}
          </>
        )}

        <div className="font-dmsans text-base text-heading px-[15px]">
          {item.quantity}
        </div>
        {!cameFromAdm && (
          <button
            onClick={() => increment(item)}
            disabled={item.count === item.quantity}
            className="disabled:border-[#ECECEC] disabled:text-[#ECECEC] disabled:cursor-not-allowed border border-[#C4C4C4] rounded-[5px] w-[18px] h-[18px] flex justify-center items-center text-sm leading-[18px] text-placeholder"
          >
            +
          </button>
        )}
      </div>
    </div>
  );
}
