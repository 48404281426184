import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
import { IconCalendar } from 'src/assets/icons';
import { IconPin } from 'src/assets/icons/IconPin';
import { IconInfo } from 'src/assets/icons/IconInfo';
import { Button, CollapsibleCustom, InputText } from 'src/components';
import { UseFormControl } from 'src/types';
import { FormInputNameRegister } from 'src/features/tickets/types';
import Order from 'src/model/Order';
import dayjs from 'dayjs';
import OrderItemType from 'src/model/OrderItemType';
import OrderItem from 'src/model/OrderItem';
import StatusType from 'src/model/StatusType';

interface StateProps {
  state: boolean;
  order: Order;
  countTickets: number;
  open: boolean;
  itemIndex: string;
  controllerTransferTicket: UseFormControl;
}

interface DispatchProps {
  toCurrency: (value: number) => string;
  onCheckCpf: (cpf: string) => void;
  setOpen: (open: boolean, item: OrderItem, index: string) => void;
  onSubmit: (orderItem: OrderItem) => void;
}

type TicketTransferProps = StateProps & DispatchProps;

export const TicketTransferContainer = ({
  state,
  order,
  open,
  itemIndex,
  controllerTransferTicket,
  toCurrency,
  setOpen,
  onCheckCpf,
  onSubmit,
}: TicketTransferProps) => {
  const countTransferTickets = (eventId: string): number => {
    let count = 0;
    order.events.forEach((event) => {
      if (event.event.id === eventId) {
        event.items.forEach((item) => {
          if (item.statusType === StatusType.ACTIVE) {
            count += 1;
          }
        });
      }
    });
    return count;
  };
  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full p-7 md:px-0 container mx-auto py-24 font-dmsans">
        <header className="flex flex-wrap items-center mb-[20px]">
          <div className="w-full flex justify-between items-center mb-[20px]">
            <div className="flex items-center">
              <h1 className="font-dmsans font-medium text-[37px]">
                Transferir ingressos
              </h1>
            </div>
          </div>
          {order.events &&
            order.events.length > 0 &&
            order.events.map((data) => (
              <>
                <div
                  key={data.event.id}
                  className="relative md:w-[1110px] h-[227px] overflow-hidden rounded-[5px]"
                >
                  <div className="absolute z-[1] w-full h-full flex flex-col justify-center items-center text-white">
                    <h2 className="font-bold text-[37px] leading-[48px] mb-[20px]">
                      {data.event.name}
                    </h2>
                    <div className="flex justify-center items-center">
                      <div className="font-normal text-[21px] inline-flex items-center">
                        <IconCalendar className="h-[18px] mr-[15px] text-white" />{' '}
                        {dayjs(data.event.startDate)
                          .locale('pt-br')
                          .format('DD/MM/YYYY')}
                      </div>
                      <div className="font-normal text-[21px] ml-10 inline-flex items-center">
                        <IconPin className="h-[18px] mr-[15px] text-white" />{' '}
                        {`${data.event.city}/${data.event.state}`}
                      </div>
                    </div>
                  </div>
                  <div className="absolute z-auto w-full h-full bg-ticket-detail"></div>
                  <img
                    src={
                      data.event.imageDetailUrl
                        ? data.event.imageDetailUrl
                        : data.event.imageUrl
                    }
                    alt="Ingresso"
                  />
                </div>
                <div>
                  <h3 className="font-dmsans font-medium text-[28px] mb-[20px]">
                    Selecione o ingresso que você quer transferir
                  </h3>
                  <p className="text-[21px] text-neutro-w-600 mb-[20px] flex items-center">
                    <IconInfo className="h-[20px] rotate-180 mr-[15px]" />
                    <span>
                      Você possui{' '}
                      <strong>
                        {countTransferTickets(data.event.id)} ingressos
                      </strong>{' '}
                      que podem ser transferidos
                    </span>
                  </p>
                  <div className="tickets mb-[20px]">
                    {data.items &&
                      data.items.length > 0 &&
                      data.items.map((item, index) => (
                        <>
                          {item.itemType === OrderItemType.TICKET &&
                            item.statusType === StatusType.ACTIVE && (
                              <div
                                key={`${item.id}${index}`}
                                className="ticket mb-[20px]"
                              >
                                <CollapsibleCustom
                                  item={item}
                                  index={`${data.event.id}-${index}`}
                                  selectedIndex={itemIndex}
                                  open={open}
                                  setOpen={setOpen}
                                  label={`${item.name} - ${dayjs(
                                    data.event.startDate
                                  )
                                    .locale('pt-br')
                                    .format('DD/MM/YYYY')} - ${toCurrency(
                                    item.unitValue
                                  )}`}
                                >
                                  <div className="lg:w-[567px] py-[20px]">
                                    <p className="text-base text-neutro-b-500 mb-[20px]">
                                      Digite os dados do novo(a) utilizador(a)
                                    </p>

                                    <div className="mb-[20px]">
                                      <InputText
                                        name="document"
                                        label="CPF"
                                        placeholder="123.456.789-00"
                                        className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                                        maxLength={14}
                                        value={
                                          controllerTransferTicket.formData[
                                            FormInputNameRegister.document
                                          ]
                                        }
                                        onChange={(e) =>
                                          controllerTransferTicket.onChangeFormInput(
                                            FormInputNameRegister.document
                                          )(e.target.value)
                                        }
                                        onBlur={(e) =>
                                          onCheckCpf(e.target.value)
                                        }
                                        error={
                                          controllerTransferTicket.formErrors
                                            .document &&
                                          controllerTransferTicket.formErrors
                                            .document[0]
                                        }
                                      />
                                    </div>

                                    <div className="mb-[20px]">
                                      <InputText
                                        name="name"
                                        label="Nome completo"
                                        placeholder="Maria da Silva"
                                        className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                                        value={
                                          controllerTransferTicket.formData[
                                            FormInputNameRegister.name
                                          ]
                                        }
                                        onChange={(e) =>
                                          controllerTransferTicket.onChangeFormInput(
                                            FormInputNameRegister.name
                                          )(e.target.value)
                                        }
                                        error={
                                          controllerTransferTicket.formErrors
                                            .name &&
                                          controllerTransferTicket.formErrors
                                            .name[0]
                                        }
                                      />
                                    </div>

                                    <div className="mb-[20px]">
                                      <InputText
                                        name="email"
                                        label="E-mail"
                                        placeholder="meuemail@email.com"
                                        className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                                        value={
                                          controllerTransferTicket.formData[
                                            FormInputNameRegister.email
                                          ]
                                        }
                                        onChange={(e) =>
                                          controllerTransferTicket.onChangeFormInput(
                                            FormInputNameRegister.email
                                          )(e.target.value)
                                        }
                                        error={
                                          controllerTransferTicket.formErrors
                                            .email &&
                                          controllerTransferTicket.formErrors
                                            .email[0]
                                        }
                                      />
                                    </div>

                                    <div className="mb-[20px]">
                                      <InputText
                                        name="phone"
                                        label="Telefone"
                                        placeholder="(00) 0 0000-0000"
                                        className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                                        value={
                                          controllerTransferTicket.formData[
                                            FormInputNameRegister.phone
                                          ]
                                        }
                                        onChange={(e) =>
                                          controllerTransferTicket.onChangeFormInput(
                                            FormInputNameRegister.phone
                                          )(e.target.value)
                                        }
                                        error={
                                          controllerTransferTicket.formErrors
                                            .phone &&
                                          controllerTransferTicket.formErrors
                                            .phone[0]
                                        }
                                      />
                                    </div>

                                    <div className="mb-[20px]">
                                      <InputText
                                        name="birthDate"
                                        label="Data de nascimento"
                                        placeholder="DD/MM/AAAA"
                                        className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                                        value={
                                          controllerTransferTicket.formData[
                                            FormInputNameRegister.birthDate
                                          ]
                                        }
                                        onChange={(e) =>
                                          controllerTransferTicket.onChangeFormInput(
                                            FormInputNameRegister.birthDate
                                          )(e.target.value)
                                        }
                                        error={
                                          controllerTransferTicket.formErrors
                                            .birthDate &&
                                          controllerTransferTicket.formErrors
                                            .birthDate[0]
                                        }
                                      />
                                    </div>

                                    <div className="mb-[20px]">
                                      <InputText
                                        name="motherName"
                                        label="Nome da mãe"
                                        placeholder="Ex: Maria da Silva"
                                        className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                                        value={
                                          controllerTransferTicket.formData[
                                            FormInputNameRegister.motherName
                                          ]
                                        }
                                        onChange={(e) =>
                                          controllerTransferTicket.onChangeFormInput(
                                            FormInputNameRegister.motherName
                                          )(e.target.value)
                                        }
                                        error={
                                          controllerTransferTicket.formErrors
                                            .motherName &&
                                          controllerTransferTicket.formErrors
                                            .motherName[0]
                                        }
                                      />
                                    </div>
                                    <Button
                                      onClick={() => onSubmit(item)}
                                      title="Transferir ingresso"
                                      buttonStyle="primary"
                                      size="xlg"
                                      disabled={
                                        controllerTransferTicket.formData[
                                          FormInputNameRegister.name
                                        ] === '' ||
                                        controllerTransferTicket.formData[
                                          FormInputNameRegister.document
                                        ] === '' ||
                                        controllerTransferTicket.formData[
                                          FormInputNameRegister.email
                                        ] === '' ||
                                        controllerTransferTicket.formData[
                                          FormInputNameRegister.phone
                                        ] === '' ||
                                        state
                                      }
                                    />
                                  </div>
                                </CollapsibleCustom>
                              </div>
                            )}
                        </>
                      ))}
                  </div>
                </div>
              </>
            ))}
        </header>
      </div>
    </AuthWrapper>
  );
};
