import React from 'react';
import { Route } from 'src/navigation/Route';
import { EventScreen } from './screens/Event';

export const EVENT_ROUTES = {
  event: '/event/:nameEvent/:city/:state',
};

export const EventNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={EVENT_ROUTES.event} component={EventScreen} />
  </React.Fragment>
);
