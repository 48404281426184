import { AuthWrapper } from 'src/features/core/auth/screens/AuthWrapper';
// import { IconCalendar } from 'src/assets/icons';
// import { IconPin } from 'src/assets/icons/IconPin';
import { IconTicket } from 'src/assets/icons/IconTicket';
import Order from 'src/model/Order';
import dayjs from 'dayjs';
import OrderItem from 'src/model/OrderItem';
import StatusType from 'src/model/StatusType';
import { isMobile } from 'react-device-detect';

interface StateProps {
  order: Order;
  countTickets: number;
}

interface DispatchProps {
  toCurrency: (value: number) => string;
  onPrint: (item: OrderItem) => void;
}

type TicketPrintProps = StateProps & DispatchProps;

export const TicketPrintContainer = ({
  order,
  // toCurrency,
  onPrint,
}: TicketPrintProps) => {
  return (
    <AuthWrapper isHomepage={false}>
      <div className="event w-full container mx-auto mb-36 lg:pt-[100px]">
        {order.events &&
          order.events.length > 0 &&
          order.events.map((data) => (
            <>
              <div key={data.event.id}></div>
              <div className="event__content grid grid-cols-[1fr] w-full md:grid-cols-[1fr] lg:grid-cols-[1fr,_535px] xl:grid-cols-[1fr,_635px] gap-20 mb-2">
                <div className="event__detail">
                  <div className="md:p-0">
                    <h1 className="text-heading font-dmsans font-medium text-2xl md:text-4xl uppercase">
                      {data.event.name}
                    </h1>
                    <div className="flex flex-wrap items-center mt-2">
                      <span className="font-dmsans font-normal text-paragraph text-xl">
                        {dayjs(data.event.startDate)
                          .locale('pt-br')
                          .format('DD/MM/YYYY')}
                      </span>
                      <span className="w-[6px] h-[6px] bg-gray-300 rounded-full"></span>
                      <span className="font-dmsans font-normal text-paragraph text-xl">
                        {`${data.event.city}/${data.event.state}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="event__image w-full lg:h-[381px] object-cover rounded-md before:absolute before:w-full before:h-full
                before:top-0 before:left-0 before:bg-opacity-20 before:bg-black"
                src={isMobile ? data.event.imageUrl : data.event.imageDetailUrl}
                alt="Ingresso"
              />
              <div>
                <hr />
                <br />
                <div className="md:flex md:grid md:grid-cols-3 gap-3 tickets mb-[20px] ">
                  {data.items &&
                    data.items.length > 0 &&
                    data.items.map((item, index) => (
                      <>
                        {item.statusType === StatusType.ACTIVE && (
                          <div
                            key={`${item.id}${index}`}
                            className="ticket mb-[10px] border-dotted  border-4 rounded-lg p-2  border-white "
                          >
                            <p
                              className="text-[21px] text-gray-500  flex items-center cursor-pointer"
                              onClick={() => onPrint(item)}
                            >
                              <IconTicket className="h-[50px] rotate-180 ml-[3px] mr-[12px]" />
                              <span>
                                <strong className="uppercase md:text-lg">
                                  {'Clique aqui para imprimir'}

                                  {/* IMPRIMIR INGRESSO PARA {data.event.name} */}
                                </strong>
                                <br></br>
                                <span className="uppercase text-[18px]">
                                  - {item.section.name}
                                </span>
                              </span>
                            </p>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </>
          ))}
      </div>

      {/* <div className="w-full p-7 md:px-0 container mx-auto py-24 font-dmsans">
        <header className="flex flex-wrap items-center mb-[20px]"> */}
      {/*
          <div className="w-full flex justify-between items-center mb-[20px]">
            <div className="flex items-center">
              <h1 className="font-dmsans font-medium text-[37px]">
                Imprimir ingressos do pedido {order.id}
              </h1>
            </div>
          </div>
           */}
      {/* {order.events &&
            order.events.length > 0 &&
            order.events.map((data) => (
              <>
                <div
                  key={data.event.id}
                  className="relative w-[400px] md:w-[1110px] h-[227px] overflow-hidden rounded-[5px]"
                >
                  <div className="absolute z-[1] w-full h-full flex flex-col justify-center items-center text-white">
                    <h2 className="font-bold text-[37px] leading-[48px] mb-[20px]">
                      {data.event.name}
                    </h2>
                    <div className="sm:flex sm:justify-center sm:items-center">
                      <div className="font-normal text-[21px] flex-col items-center sm:align-items-center">
                        <div className="sm:inline-flex">
                          <div className="flex items-center">
                            <IconCalendar className="h-[18px] mr-[15px] text-white" />
                            {dayjs(data.event.startDate)
                              .locale('pt-br')
                              .format('DD/MM/YYYY')}
                          </div>
                        </div>
                        <div className="sm:inline-flex">
                          <div className="mt-4 sm:ml-10 flex items-center">
                            <IconPin className="h-[18px] mr-[15px] text-white" />
                            {`${data.event.city}/${data.event.state}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute z-auto w-full h-full bg-ticket-detail"></div>
                  <img
                    className="w-full h-auto object-fit contain filter brightness-50"
                    src={
                      isMobile ? data.event.imageUrl : data.event.imageDetailUrl
                    }
                    alt="Ingresso"
                  />
                </div>
                <div>
                  <h3 className="font-dmsans font-medium text-[28px] mb-[20px]">
                    Clique no ingresso que você deseja imprimir
                  </h3>
                  <br />
                  <div className="tickets mb-[20px]">
                    {data.items &&
                      data.items.length > 0 &&
                      data.items.map((item, index) => (
                        <>
                          {item.statusType === StatusType.ACTIVE && (
                            <div
                              key={`${item.id}${index}`}
                              className="ticket mb-[10px]"
                            >
                              <p
                                className="text-[21px] text-blue-600  flex items-center cursor-pointer"
                                onClick={() => onPrint(item)}
                              >
                                <IconTicket className="h-[20px] rotate-180 mr-[15px]" />
                                <span>
                                  <strong className="uppercase">
                                    {'Clique aqui para imprimir'} */}

      {/* IMPRIMIR INGRESSO PARA {data.event.name} */}
      {/* </strong>
                                  <br></br>
                                  <span className="uppercase">
                                    {' '}
                                    - Ingresso {index + 1}{' '}
                                  </span> */}
      {/* {`- ${dayjs(data.event.startDate)
                                    .locale('pt-br')
                                    .format('DD/MM/YYYY')} - ${toCurrency(
                                    item.unitValue
                                  )}`} */}
      {/* </span>
                              </p>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              </>
            ))}
        </header>
      </div> */}
    </AuthWrapper>
  );
};
