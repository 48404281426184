import { FormEvent, useEffect } from 'react';
import { gapi } from 'gapi-script';
import {
  /*GoogleLogin,*/ GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {
  /*FacebookLogin,*/ ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import {
  CalendarGrey,
  //Google,
  IconEye,
  IconEyeClose,
  //IconFacebook,
} from 'src/assets/icons';
import { Button, Checkbox, InputText, Select } from 'src/components';
import { classNames } from 'src/helpers/common';
import { UseFormReturn } from 'src/hooks';
import {
  ShouldShowModalProps,
  FormInputNameRegister,
  ShouldShowModal,
} from '../types';
import { OptionProps } from 'src/components/Select';

interface RegisterContentProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  onChangeCEP: (value: string) => void;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
  onTogglePasswordToText: () => void;
  onTogglePasswordToText2: () => void;
  onSubmit: (e: FormEvent) => void;
  onShouldShowModal: ({ value, title }: ShouldShowModalProps) => void;
  onGoToGoogle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmitGoogle: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGoogleError: (error: any) => void;
  onGoToFacebook: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmitFacebook: (
    userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
  ) => void;
  onGoToAppleId: () => void;
}

export const RegisterContent: React.FC<RegisterContentProps> = ({
  state,
  shouldShowPasswordToText,
  shouldShowPasswordToText2,
  formData,
  formErrors,
  onChangeFormInput,
  onChangeCEP,
  onSubmit,
  onTogglePasswordToText,
  onTogglePasswordToText2,
  onShouldShowModal,
  //onGoToGoogle,
  //onSubmitGoogle,
  //onGoogleError,
  //onGoToFacebook,
  //onSubmitFacebook,
  // eslint-disable-next-line
  //onGoToAppleId,
}) => {
  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapa' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espirito Santo' },
    { value: 'GO', label: 'Goias' },
    { value: 'MA', label: 'Maranhao' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Para' },
    { value: 'PB', label: 'Paraiba' },
    { value: 'PR', label: 'Parana' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piaui' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondonia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'Sao Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];
  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId:
          '784726485697-uutejg2oolluqsfdm73n1nm6acdr0ntg.apps.googleusercontent.com',
        plugin_name: 'chat',
      });
    });
  }, []);

  return (
    <div>
      <div className="mt-8 w-full">
        <form onSubmit={onSubmit}>
          <div className="mb-[20px]">
            <InputText
              name="name"
              label="Nome completo"
              placeholder="Maria da Silva"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.name]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.name)(e.target.value)
              }
              error={formErrors.name && formErrors.name[0]}
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="document"
              label="CPF"
              placeholder="123.456.789-00"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              maxLength={14}
              value={formData[FormInputNameRegister.document]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.document)(
                  e.target.value
                )
              }
              error={formErrors.document && formErrors.document[0]}
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="email"
              label="E-mail"
              placeholder="meuemail@email.com"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.email]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.email)(e.target.value)
              }
              error={formErrors.email && formErrors.email[0]}
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="phone"
              label="Telefone"
              placeholder="(00) 0 0000-0000"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.phone]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.phone)(e.target.value)
              }
              error={formErrors.phone && formErrors.phone[0]}
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="date"
              label="Data de nascimento"
              placeholder="DD/MM/AAAA"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.date]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.date)(e.target.value)
              }
              error={formErrors.date && formErrors.date[0]}
              renderForward={
                <button
                  className={classNames(
                    formErrors.date && formErrors.date[0]
                      ? 'right-0 bottom-7'
                      : 'right-0 bottom-3',
                    'absolute cursor-pointer mr-4'
                  )}
                  type="button"
                >
                  <div className="h-8 flex flex-col justify-center items-center">
                    <img src={CalendarGrey} style={{ cursor: 'none' }} />
                  </div>
                </button>
              }
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="motherName"
              label="Nome da mãe"
              placeholder="Ex: Maria da Silva"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.motherName]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.motherName)(
                  e.target.value
                )
              }
              error={formErrors.motherName && formErrors.motherName[0]}
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="zipCode"
              label="CEP"
              placeholder="00000-000"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.zipCode]}
              maxLength={9}
              onChange={(e) => {
                onChangeFormInput(FormInputNameRegister.zipCode)(
                  e.target.value
                );
                onChangeCEP(e.target.value);
              }}
              error={formErrors.zipCode && formErrors.zipCode[0]}
            />
          </div>
          <div className="mb-[20px]">
            <Select
              name="state"
              label="Estado"
              placeholder="Selecione..."
              value={formData[FormInputNameRegister.state]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.state)(
                  e?.target.value as string
                )
              }
              className="col-span-2"
              error={formErrors.state && formErrors.state[0]}
              options={stateOptions}
            ></Select>
          </div>
          <div className="mb-[20px]">
            <InputText
              name="city"
              label="Cidade"
              placeholder=""
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
              value={formData[FormInputNameRegister.city]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.city)(e.target.value)
              }
              error={formErrors.city && formErrors.city[0]}
            />
          </div>
          <div className="mb-[20px]">
            <InputText
              name="district"
              label="Bairro"
              placeholder=""
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
              value={formData[FormInputNameRegister.district]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.district)(
                  e.target.value
                )
              }
              error={formErrors.district && formErrors.district[0]}
            />
          </div>
          <div className="mb-[20px]">
            <InputText
              name="street"
              label="Rua/Avenida"
              placeholder=""
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
              value={formData[FormInputNameRegister.street]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.street)(e.target.value)
              }
              error={formErrors.street && formErrors.street[0]}
            />
          </div>
          <div className="mb-[20px]">
            <InputText
              name="addressnumber"
              label="Número"
              placeholder=""
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.addressnumber]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.addressnumber)(
                  e.target.value
                )
              }
              error={formErrors.addressnumber && formErrors.addressnumber[0]}
            />
          </div>
          <div className="mb-[20px]">
            <InputText
              name="complement"
              label="Complemento (opcional)"
              placeholder=""
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              value={formData[FormInputNameRegister.complement]}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.complement)(
                  e.target.value
                )
              }
              error={formErrors.complement && formErrors.complement[0]}
            />
          </div>

          <div className="mb-[20px]">
            <InputText
              name="password"
              label="Senha"
              type={shouldShowPasswordToText ? 'text' : 'password'}
              placeholder="**********"
              value={formData[FormInputNameRegister.password]}
              maxLength={15}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.password)(
                  e.target.value
                )
              }
              error={formErrors.password && formErrors.password[0]}
              renderForward={
                <button
                  className={classNames(
                    formErrors.password && formErrors.password[0]
                      ? 'right-0 bottom-7'
                      : 'right-0 bottom-3',
                    'absolute cursor-pointer mr-4'
                  )}
                  onClick={onTogglePasswordToText}
                  type="button"
                >
                  <div className="h-8 flex flex-col justify-center items-center">
                    {shouldShowPasswordToText ? (
                      <img src={IconEye} alt="Mostrar senha" />
                    ) : (
                      <img src={IconEyeClose} alt="Ocultar senha" />
                    )}
                  </div>
                </button>
              }
            />
          </div>

          <div className="mb-[20px] text-neutro-w-600 text-base font-dmsans font-normal">
            <p className="mb-5">Sua senha deve conter:</p>
            <ul className="text-neutro-w-500 list-disc list-item ml-6">
              <li>No mínimo 8 caracteres;</li>
              <li>Pelo menos um número;</li>
              <li>Pelo menos um caractere maiúsculo.</li>
            </ul>
          </div>

          <div className="mb-[20px]">
            <InputText
              name="password"
              label="Confirme a sua senha"
              type={shouldShowPasswordToText2 ? 'text' : 'password'}
              placeholder="**********"
              value={formData[FormInputNameRegister.confirmPassword]}
              maxLength={15}
              onChange={(e) =>
                onChangeFormInput(FormInputNameRegister.confirmPassword)(
                  e.target.value
                )
              }
              error={
                formErrors.confirmPassword && formErrors.confirmPassword[0]
              }
              renderForward={
                <button
                  className={classNames(
                    formErrors.confirmPassword && formErrors.confirmPassword[0]
                      ? 'right-0 bottom-7'
                      : 'right-0 bottom-3',
                    'absolute cursor-pointer mr-4'
                  )}
                  onClick={onTogglePasswordToText2}
                  type="button"
                >
                  <div className="h-8 flex flex-col justify-center items-center">
                    {shouldShowPasswordToText2 ? (
                      <img src={IconEye} alt="Mostrar senha" />
                    ) : (
                      <img src={IconEyeClose} alt="Ocultar senha" />
                    )}
                  </div>
                </button>
              }
            />
          </div>

          <div
            className="mb-[20px] flex flex-col items-start justify-between"
            id="termsAndConditions"
          >
            <Checkbox
              name="terms"
              value={formData[FormInputNameRegister.terms]}
              className="mb-[20px]"
              onChange={(checked) =>
                onChangeFormInput(FormInputNameRegister.terms)(checked)
              }
              error={formErrors.terms && formErrors.terms[0]}
              renderForward={
                <div className="ml-3 text-label text-base font-dmsans font-regular mr-2">
                  Eu aceito
                  <a
                    className="underline underline-offset-2 md:inline-block
              align-baseline text-label text-base font-normal font-dmsans mx-1"
                    href="#"
                    onClick={() =>
                      onShouldShowModal({
                        value: ShouldShowModal.TERMS,
                        title: 'Termos e Política de Privacidade',
                      })
                    }
                  >
                    Termos e Política de Privacidade
                  </a>
                  do site
                </div>
              }
            />

            <Button
              type="submit"
              title="Criar a minha conta"
              buttonStyle="primary"
              size="xlg"
              className="w-full"
              disabled={
                formData[FormInputNameRegister.name] === '' ||
                formData[FormInputNameRegister.document] === '' ||
                formData[FormInputNameRegister.email] === '' ||
                formData[FormInputNameRegister.phone] === '' ||
                formData[FormInputNameRegister.date] === '' ||
                formData[FormInputNameRegister.password] === '' ||
                formData[FormInputNameRegister.confirmPassword] === '' ||
                formData[FormInputNameRegister.terms] === '' ||
                formData[FormInputNameRegister.terms] === 'false' ||
                state
              }
            />
          </div>
          {/*
          <div className="flex mb-[20px] items-center justify-between">
            <hr className="block w-full h-px bg-label" />
            <span className="mx-2 text-neutro-b-400 font-dmsans">ou</span>
            <hr className="block w-full h-px bg-label" />
          </div>
          <div className="flex flex-col items-center justify-between">
            <Button
              type="button"
              title="Continuar com o Google"
              buttonStyle="outlined"
              size="xlg"
              className="w-full mb-[20px]"
              disabled={state}
              iconLeft={<img src={Google} alt="logo do google" />}
              onClick={onGoToGoogle}
            />
            <div id="googleLogin" style={{ display: 'none' }}>
              <GoogleLogin
                clientId="726656258444-662p1ueuqrs9k6j86dq9k772jla17am9.apps.googleusercontent.com"
                buttonText="LOGIN WITH GOOGLE"
                onSuccess={(response) => onSubmitGoogle(response)}
                onFailure={(error) => onGoogleError(error)}
              />
            </div>
            
            <Button
              type="button"
              title="Continuar com o Facebook"
              buttonStyle="outlined"
              size="xlg"
              className="w-full mb-[20px]"
              disabled={state}
              iconLeft={<img src={IconFacebook} alt="logo do facebook" />}
              onClick={onGoToFacebook}
            />
            <FacebookLogin
              appId="3401894420124137"
              fields="name,email,picture"
              callback={(userInfo) => onSubmitFacebook(userInfo)}
            />
            <Button
              type="button"
              title="Continuar com a Apple"
              buttonStyle="outlined"
              size="xlg"
              className="w-full mb-[30px]"
              disabled={state === States.loading}
              iconLeft={<img src={Apple} alt="logo da apple id" />}
              onClick={onGoToAppleId}
            />
          </div>
          */}
          <div className="flex items-baseline justify-center">
            <span className=" text-neutro-w-500 text-base font-normal font-dmsans">
              Já tem uma conta?
            </span>
            <a
              className="mx-2 underline underline-offset-2 inline-block
              align-baseline text-neutro-w-600 text-base font-bold font-dmsans"
              href="#"
              onClick={() =>
                onShouldShowModal({
                  value: ShouldShowModal.LOGIN,
                  title: '',
                })
              }
            >
              Entrar
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};
