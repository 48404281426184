import React, { useEffect } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Navigation } from 'src/navigation';

import 'dayjs';
import 'dayjs/locale/pt-br';
import 'react-toastify/dist/ReactToastify.min.css';
import 'src/styles/global.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import { SidebarProvider } from './hooks/useSidebar';
import Loading from './components/Loading';

const App: React.FC = (): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    const lastLocation = localStorage.getItem('lastLocation');
    if (lastLocation) {
      history.push(lastLocation);
    }
  }, [history]);

  return (
    <Provider store={store}>
      <Loading />
      <BrowserRouter>
        <SidebarProvider>
          <Navigation />
        </SidebarProvider>
      </BrowserRouter>
      <ToastContainer />
    </Provider>
  );
};

export default App;
