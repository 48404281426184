interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconTicket = (props: IconProps) => (
  <svg
    viewBox="0 0 16 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M15.9277 0L11.019 -2.08282e-07L11.019 0.701208C11.019 2.25872 9.77133 3.50636 8.21382 3.50635C6.65631 3.50635 5.40867 2.25872 5.40867 0.701208L5.40867 -4.4633e-07L0.499899 -6.54612e-07L0.499898 29.4534L5.40867 29.4534L5.40867 28.7522C5.40867 27.1947 6.65631 25.947 8.21382 25.947C9.77133 25.947 11.019 27.1947 11.019 28.7522L11.019 29.4534L15.9277 29.4534L15.9277 0ZM14.5253 1.40242L14.5253 20.3367L13.1229 20.3367L13.1229 21.7392L14.5253 21.7392L14.5253 28.0506L12.2791 28.0506C11.9313 26.0802 10.2806 24.5442 8.21382 24.5442C6.14657 24.5442 4.49589 26.0797 4.14857 28.0506L1.90231 28.0506L1.90232 21.7392L3.30473 21.7392L3.30473 20.3367L1.90232 20.3367L1.90232 1.40242L4.14857 1.40242C4.49588 3.3733 6.14657 4.90877 8.21382 4.90877C10.2806 4.90877 11.9312 3.3728 12.2791 1.40242L14.5253 1.40242ZM11.7202 20.3367L8.91502 20.3367L8.91502 21.7392L11.7202 21.7392L11.7202 20.3367ZM7.51261 20.3367L4.70746 20.3367L4.70746 21.7392L7.51261 21.7392L7.51261 20.3367Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
