interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconCreditCard = (props: IconProps) => (
  <svg
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M16.2 0H1.8C0.801 0 0.00899999 0.834375 0.00899999 1.875L0 13.125C0 14.1656 0.801 15 1.8 15H16.2C17.199 15 18 14.1656 18 13.125V1.875C18 0.834375 17.199 0 16.2 0ZM16.2 13.125H1.8V7.5H16.2V13.125ZM16.2 3.75H1.8V1.875H16.2V3.75Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);

export const IconCreditCard1 = (props: IconProps) => (
  <svg
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M17 2.48776C17 1.82791 16.7378 1.19524 16.2713 0.728559C15.8048 0.262029 15.172 0 14.5121 0H2.48791C1.82806 0 1.19524 0.262033 0.728707 0.728559C0.262178 1.1952 0 1.82788 0 2.48776V9.53642C0 10.1963 0.262178 10.8289 0.728707 11.2956C1.19524 11.7622 1.82802 12.0242 2.48791 12.0242H14.5121C15.1719 12.0242 15.8048 11.7621 16.2713 11.2956C16.7378 10.829 17 10.1963 17 9.53642V2.48776ZM2.48806 0.829217H14.5122C14.9522 0.829217 15.374 1.00391 15.685 1.31496C15.9961 1.62601 16.1708 2.04785 16.1708 2.48776V3.31694H0.829587V2.48776C0.829587 2.04785 1.00428 1.62604 1.31533 1.31496C1.62638 1.00388 2.04822 0.829217 2.48813 0.829217H2.48806ZM14.5122 11.1949H2.48806C2.04815 11.1949 1.62634 11.0202 1.31526 10.7091C1.00417 10.3981 0.829513 9.97626 0.829513 9.53635V4.1462H16.1707V9.53635C16.1707 9.97626 15.996 10.3981 15.685 10.7091C15.3739 11.0202 14.9521 11.1949 14.5122 11.1949H14.5122Z"
      fill="currentColor"
    />
    <path
      d="M8.22318 7.04891H14.0735C14.3026 7.04891 14.4881 6.86323 14.4881 6.63432C14.4881 6.40525 14.3026 6.21973 14.0735 6.21973H8.22318C7.99412 6.21973 7.80859 6.40526 7.80859 6.63432C7.80859 6.86324 7.99413 7.04891 8.22318 7.04891Z"
      fill="currentColor"
    />
    <path
      d="M14.0983 8.29248H8.22318C7.99412 8.29248 7.80859 8.47802 7.80859 8.70707C7.80859 8.93599 7.99413 9.12166 8.22318 9.12166H14.0983C14.3274 9.12166 14.5129 8.93598 14.5129 8.70707C14.5129 8.47801 14.3274 8.29248 14.0983 8.29248Z"
      fill="currentColor"
    />
    <path
      d="M5.12802 6.01221H4.09146C3.7616 6.01221 3.44519 6.14323 3.21193 6.37648C2.97867 6.60974 2.84766 6.92616 2.84766 7.25615V8.08533C2.84766 8.41519 2.97867 8.7316 3.21193 8.96486C3.44519 9.19812 3.7616 9.32928 4.09146 9.32928H5.12802C5.45803 9.32928 5.77429 9.19812 6.0077 8.96486C6.24096 8.7316 6.37197 8.41518 6.37197 8.08533V7.25615C6.37197 6.92615 6.24095 6.60974 6.0077 6.37648C5.7743 6.14322 5.45802 6.01221 5.12802 6.01221ZM5.54261 8.08534H5.54276C5.54276 8.19525 5.49894 8.30081 5.42129 8.37847C5.34348 8.45627 5.23807 8.49994 5.12802 8.49994H4.09145C3.86253 8.49994 3.67686 8.31441 3.67686 8.08535V7.25617C3.67686 7.02711 3.86254 6.84143 4.09145 6.84143H5.12802C5.23807 6.84143 5.34348 6.8851 5.42129 6.9629C5.49894 7.0407 5.54276 7.14612 5.54276 7.25617L5.54261 8.08534Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
