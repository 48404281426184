import React from 'react';
import { useParams } from 'react-router-dom';
import { EventComponent } from 'src/features/event/screens/Event';
import EventProductsAndCombos from 'src/model/EventProductsAndCombos';
import EventSectionProductsAndCombos from 'src/model/EventSectionProductsAndCombos';
import EventSiteGet from 'src/model/EventSiteGet';
import EventSiteHeader from 'src/model/EventSiteHeader';
import { api } from 'src/services/api';

interface PdvParam {
  pdvId: string;
  eventId: string;
}
export const PdvScreen: React.FC = (): JSX.Element => {
  const { pdvId, eventId } = useParams<PdvParam>();

  const getEvent = async (): Promise<EventSiteHeader[]> => {
    const { data } = await api.get<EventSiteHeader[]>(
      `/event/${eventId}/pdv/${pdvId}`
    );
    return data;
  };

  const handleGetEvent = async (eventId: string): Promise<EventSiteGet> => {
    const { data } = await api.get<EventSiteGet>(
      `/event/${eventId}/pdv/${pdvId}/detail`
    );
    return data;
  };

  const handleProductsAndCombos = async (
    eventId: string
  ): Promise<EventProductsAndCombos> => {
    const { data } = await api.get<EventProductsAndCombos>(
      `/event/product-combo/${eventId}`
    );
    return data;
  };

  const handleGetSectionProductsAndCombos = async (
    eventId: string,
    sectionId: string
  ): Promise<EventSectionProductsAndCombos> => {
    const { data } = await api.get<EventSectionProductsAndCombos>(
      `/event/${eventId}/pdv/${pdvId}/section/${sectionId}/product-combo`
    );
    return data;
  };

  const handleOnGetPDV = (): string => {
    return pdvId;
  };

  return (
    <EventComponent
      getEvents={getEvent}
      getEvent={handleGetEvent}
      getProductsAndCombos={handleProductsAndCombos}
      getSectionProductsAndCombos={handleGetSectionProductsAndCombos}
      getPdv={handleOnGetPDV}
    />
  );
};
