import { IconCheckSuccess } from 'src/assets/icons';
import { Button } from 'src/components';

interface CreditCardPaymentContentProps {
  onGoToTickets: () => void;
}

export const CreditCardPaymentContent: React.FC<
  CreditCardPaymentContentProps
> = ({ onGoToTickets }): JSX.Element => {
  return (
    <div className="w-[411px] mx-auto">
      <div className="flex flex-col justify-center justify-between items-center">
        <IconCheckSuccess height="80px" className="text-[#7AD81B] mb-[30px]" />
        <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
          A sua compra foi realizada com sucesso!
        </h4>
        <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[70px]">
          Para acessar todos os seus ingressos, vá até o ícone com as suas
          iniciais e clique em <strong>“Meus ingressos”</strong> ou clique no
          botão abaixo.
        </p>
        <Button
          type="button"
          title="Ir para Meus ingressos"
          buttonStyle="primary"
          size="xlg"
          className="w-full mb-[25px]"
          onClick={() => onGoToTickets()}
        />
      </div>
    </div>
  );
};
