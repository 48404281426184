import { HOME_ROUTES } from 'src/features/home/navigation';
import { EVENT_ROUTES } from 'src/features/event/navigation';
import { CHECKOUT_ROUTES } from 'src/features/checkout/navigation';
import { PROFILE_ROUTES } from 'src/features/profile/navigation';
import { TICKETS_ROUTES } from 'src/features/tickets/navigation';
import { PDV_ROUTES } from 'src/features/pdv/navigation';
import { ORDER_ROUTES } from 'src/features/order/navigation';

const path = {
  Initial: {
    ...HOME_ROUTES,
    ...EVENT_ROUTES,
    ...PDV_ROUTES,
    ...CHECKOUT_ROUTES,
    ...PROFILE_ROUTES,
    ...TICKETS_ROUTES,
    ...ORDER_ROUTES,
    All: '*',
  },
} as const;

export { path };
