enum CardValidationResponseStatus {
  NOT_BELONG,
  AWAITING_VALIDATION,
  VALIDATED,
  APPROVED,
  AWAITING_PAYMENT,
  DISAPPROVED,
  ERROR,
}
export default CardValidationResponseStatus;
