import styled, { keyframes } from 'styled-components';
import * as PopoverPrimitive from '@radix-ui/react-popover';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const StyledContent = styled(PopoverPrimitive.Content)`
  box-shadow: 0px 10px 38px -10px rgba(0, 0, 0, 0.2),
  animation: ${slideUpAndFade} 100ms ease-out;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  &[data-state="open"] {
    &[data-side="top"] { animation: ${slideDownAndFade} 100ms ease-out; }
    &[data-side="right"] { animation: ${slideLeftAndFade} 100ms ease-out; }
    &[data-side="bottom"] { animation: ${slideUpAndFade} 100ms ease-out; }
    &[data-side="left"] { animation: ${slideRightAndFade} 100ms ease-out; }
 }

  &:focus {
    box-shadow: 0px 10px 38px -10px rgba(0, 0, 0, 0.2),
      0px 10px 20px -15px, 0 0 0 2px #ffc700;
  }
`;

export const StyledArrow = styled(PopoverPrimitive.Arrow)`
  fill: #efefef;
`;

export const StyledClose = styled(PopoverPrimitive.Close)`
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 3px;
  right: 3px;
  &:hover {
    background-color: #ffc700;
  }
`;
