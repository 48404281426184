import React from 'react';
import { classNames } from 'src/helpers/common';
import PaymentType from 'src/model/PaymentType';

interface Tab {
  text: string;
  icon?: React.ReactNode;
  type: PaymentType;
}

interface TabContent {
  content: React.ReactNode;
  type: PaymentType;
}
interface TabTypePaymentsProps {
  tabs: Tab[];
  contents: TabContent[];
  typePayment?: PaymentType;
  onSetTypePayment: (type: PaymentType) => void;
}

export const TabTypePayments = ({
  tabs,
  contents,
  typePayment,
  onSetTypePayment,
}: TabTypePaymentsProps) => {
  return (
    <div className="tabs">
      <nav className="tabs__nav md:w-[500px] grid grid-cols-3 gap-3">
        {tabs.map((tab: Tab, index: number) => (
          <a
            key={index + 1}
            onClick={() => onSetTypePayment(tab.type)}
            className={classNames(
              typePayment === tab.type
                ? 'bg-alert-info-soft text-white'
                : 'bg-neutro-w-100 text-neutro-w-600',
              'md:w-[150px] md:h-[100px] pt-[15px] md:pt-0 rounded-[5px] flex flex-col items-center justify-center font-dmsans font-medium cursor-pointer'
            )}
          >
            {tab.icon}
            <span className="hidden md:inline">{tab.text}</span>
          </a>
        ))}
      </nav>
      <div className="tabs__content mt-8">
        {contents.map((content: TabContent, index: number) => (
          <div
            key={index + 1}
            className={classNames(
              typePayment === content.type ? 'block' : 'hidden',
              'w-full'
            )}
          >
            {content.content}
          </div>
        ))}
      </div>
    </div>
  );
};
