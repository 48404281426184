import React from 'react';
import { Route } from 'src/navigation/Route';
import { AboutScreen } from './screens/About';
import { ContactsScreen } from './screens/Contacts';
import { EventByCategoryScreen } from './screens/EventByCategory';
import { HomeScreen } from './screens/Home';
import { PoliticsScreen } from './screens/Politics';
import { TermsContent } from '../core/auth/components';
import { PoliticsFacebookContent } from '../core/auth/components/PoliticsFacebookContent';

// eslint-disable-next-line no-var
export var HOME_ROUTES = {
  itself: '/',
  about: '/quemsomos',
  contact: '/contato-suporte',
  politics: '/politicas',
  politicsFacebook: '/politics',
  terms: '/termos',
  category: '/categoria/:name/:id',
};

export const HomeNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={HOME_ROUTES.category} component={EventByCategoryScreen} />
    <Route path={HOME_ROUTES.about} component={AboutScreen} />
    <Route path={HOME_ROUTES.contact} component={ContactsScreen} />
    <Route path={HOME_ROUTES.politics} component={PoliticsScreen} />
    <Route
      path={HOME_ROUTES.politicsFacebook}
      component={PoliticsFacebookContent}
    />
    <Route path={HOME_ROUTES.terms} component={TermsContent} />
    <Route path={HOME_ROUTES.itself} component={HomeScreen} />
  </React.Fragment>
);
