import React, { useState } from 'react';
import {
  CalendarBlank,
  CaretDown,
  MagnifyingGlass,
  MapPin,
  XCircle,
} from 'phosphor-react';
import { IconClose, Sizes } from 'src/assets/icons';
import { colors } from 'src/styles/colors';
import { DateRangePicker, Popover, Range } from 'src/components';
import { Address } from 'src/model/Address';
import dayjs from 'dayjs';
interface FilterEventContentProps {
  inputFilter: string;
  cities: Address[];
  selectedCity: Address;
  rangeDateSelected: Range[] | undefined;
  onClearRangeDateSelected: () => void;
  onChangeInputFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearInputFilter: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onChangeCity: (address: Address) => void;
  dateRange: Range[] | undefined;
  onChangeDateRange: (dateRange: Range[]) => void;
}

export const FilterEventContent: React.FC<FilterEventContentProps> = ({
  inputFilter,
  cities,
  selectedCity,
  dateRange,
  onChangeInputFilter,
  onClearInputFilter,
  onChangeCity,
  onChangeDateRange,
  rangeDateSelected,
  onClearRangeDateSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);

  const handleOnToggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="w-full border-b-14 border-gray-10">
      <div className="container mx-auto py-[3.7rem] text-center md:text-left">
        <p className="text-paragraph text-base font-dmsans font-normal tracking-wider">
          Filtre os eventos
        </p>
        {selectedCity && selectedCity.city && (
          <h2 className="text-heading text-3xl md:text-4xl font-dmsans font-medium leading-[48.17px] mt-2 ">
            {selectedCity.city}
            {selectedCity.state ? `, ${selectedCity.state}` : ''}
          </h2>
        )}
        <div className="grid grid-cols-1 md:grid-cols-[300px_360px] justify-center md:justify-start">
          <Popover
            open={isOpen}
            setOpen={setIsOpen}
            className="localization bg-white shadow-pop rounded-md block"
            trigger={
              <div
                className="flex items-center justify-center md:justify-start space-x-3 mt-7"
                onClick={(): void => {
                  handleOnToggle();
                }}
              >
                <MapPin size={Sizes.xmedium} color={colors.graylight} />
                <span className="font-normal font-dmsans text-xl text-paragraph">
                  Escolha a localização
                </span>
                <CaretDown
                  style={{
                    transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                  }}
                  size={Sizes.xmedium}
                  color={colors.graylight}
                />
              </div>
            }
          >
            <form>
              <div className="flex flex-col w-72">
                <div className="p-4">
                  {/* InputSearch */}
                  <div className="w-full flex items-center border rounded-md focus:outline-none focus:shadow-outline">
                    <div className="h-full p-0 pl-2 flex justify-center items-center">
                      <button>
                        <MagnifyingGlass
                          color={colors.graylight}
                          size={Sizes.xsmall}
                          className="group-radix-state-open:rotate-180"
                        />
                      </button>
                    </div>
                    <div className="flex flex-1 items-center">
                      <input
                        type="text"
                        id="inputFilter"
                        name="inputFilter"
                        value={inputFilter}
                        onChange={(e) => onChangeInputFilter(e)}
                        className={`appearance-none w-full p-2 h-[42px]
                      rounded-md text-heading leading-tight focus:outline-none
                      focus:shadow-outline text-xs font-dmsans font-normal`}
                        placeholder="Pesquise pela cidade"
                      />
                    </div>
                    <div className="h-full p-0 pr-2 flex justify-center items-center">
                      <button onClick={(e) => onClearInputFilter(e)}>
                        <XCircle size={Sizes.xsmall} color={colors.graylight} />
                      </button>
                    </div>
                  </div>
                </div>

                <hr className="bg-gray-50" />
                {cities.map((data, index) => (
                  <ul key={data.city}>
                    {index === 0 && (
                      <li
                        onClick={() => {
                          onChangeCity(data);
                          handleOnToggle();
                        }}
                      >
                        <a
                          href="#"
                          className="block p-4 first:border-0 text-xs text-heading font-dmsans font-normal"
                        >
                          {data.city}
                          {data.state ? `, ${data.state}` : ''}
                        </a>
                      </li>
                    )}
                    {index > 0 && (
                      <li
                        onClick={() => {
                          onChangeCity(data);
                          handleOnToggle();
                        }}
                      >
                        <a
                          href="#"
                          className="block p-4 border-t border-gray-50 text-xs text-heading font-dmsans font-normal"
                        >
                          {data.city}
                          {data.state ? `, ${data.state}` : ''}
                        </a>
                      </li>
                    )}
                  </ul>
                ))}
              </div>
            </form>
          </Popover>

          <DateRangePicker
            className="relative"
            open={isOpenDate}
            setOpen={setIsOpenDate}
            trigger={
              <div className="flex items-center justify-center md:justify-start space-x-3 mt-7">
                {rangeDateSelected ? (
                  <div></div>
                ) : (
                  <CalendarBlank
                    size={Sizes.xmedium}
                    color={colors.graylight}
                  />
                )}
                <span className="font-normal font-dmsans text-xl text-paragraph">
                  {rangeDateSelected ? (
                    <div className="flex justify-between items-center bg-neutro-b-400 text-white px-[15px] py-[6px] rounded-full">
                      <div className="mr-[20px]">
                        {rangeDateSelected[0] &&
                          `${dayjs(rangeDateSelected[0]?.startDate)
                            .locale('pt-br')
                            .format('DD/MM/YYYY')}
                        - ${dayjs(rangeDateSelected[0]?.endDate)
                          .locale('pt-br')
                          .format('DD/MM/YYYY')}
                        `}
                      </div>

                      <button
                        onClick={onClearRangeDateSelected}
                        title="Limpar data"
                      >
                        <IconClose className="h-[10px] text-white" />
                      </button>
                    </div>
                  ) : (
                    'Escolha a data'
                  )}
                </span>
                {rangeDateSelected ? (
                  <div></div>
                ) : (
                  <CaretDown
                    size={Sizes.xmedium}
                    color={colors.graylight}
                    style={{
                      transform: isOpenDate
                        ? 'rotate(-180deg)'
                        : 'rotate(0deg)',
                    }}
                  />
                )}
              </div>
            }
            dateRange={dateRange}
            onChangeDateRange={onChangeDateRange}
          />
        </div>
      </div>
    </section>
  );
};
