import React from 'react';
import { Route } from 'src/navigation/Route';
import { OrderScreen } from './screens/Order';

export const ORDER_ROUTES = {
  order: '/order/:orderId',
};

export const OrderNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route path={ORDER_ROUTES.order} component={OrderScreen} />
  </React.Fragment>
);
