import { InputText, Select } from 'src/components';
import { UseFormControl } from 'src/types';
import { FormInputNameAddress } from '../types';
import { OptionProps } from 'src/components/Select';

interface StateProps {
  controllerAddress: UseFormControl;
}
interface DispatchProps {
  onChangeCEP: (value: string) => void;
  onSubmitAddress: () => void;
}

type AddressContentProps = StateProps & DispatchProps;

export const AddressContent = ({
  controllerAddress,
  onChangeCEP,
}: AddressContentProps) => {
  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapa' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espirito Santo' },
    { value: 'GO', label: 'Goias' },
    { value: 'MA', label: 'Maranhao' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Para' },
    { value: 'PB', label: 'Paraiba' },
    { value: 'PR', label: 'Parana' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piaui' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondonia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'Sao Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  return (
    <>
      <div className="mb-[20px]">
        <InputText
          name="zipCode"
          label="CEP"
          placeholder="00000-000"
          className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
          value={controllerAddress.formData[FormInputNameAddress.zipCode]}
          maxLength={9}
          onChange={(e) => {
            controllerAddress.onChangeFormInput(FormInputNameAddress.zipCode)(
              e.target.value
            );
            onChangeCEP(e.target.value);
          }}
          error={
            controllerAddress.formErrors.zipCode &&
            controllerAddress.formErrors.zipCode[0]
          }
        />
        <a
          href="#"
          className="underline text-base text-alert-info mt-[15px] block"
          onClick={() =>
            window.open(
              'https://buscacepinter.correios.com.br/app/endereco/index.php'
            )
          }
        >
          Não sei o meu CEP
        </a>
      </div>
      {controllerAddress.formData[FormInputNameAddress.zipCode] ? (
        <>
          <div className="mb-[20px] grid grid-cols-5 gap-5">
            <Select
              name="state"
              label="Estado"
              placeholder="Selecione..."
              value={controllerAddress.formData[FormInputNameAddress.state]}
              onChange={(e) =>
                controllerAddress.onChangeFormInput(FormInputNameAddress.state)(
                  e?.target.value as string
                )
              }
              className="col-span-2"
              error={
                controllerAddress.formErrors.state &&
                controllerAddress.formErrors.state[0]
              }
              options={stateOptions}
            ></Select>
            <InputText
              name="city"
              label="Cidade"
              placeholder=""
              className={`appearance-none w-full
        rounded-md text-heading leading-tight focus:outline-none
        focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
              value={controllerAddress.formData[FormInputNameAddress.city]}
              onChange={(e) =>
                controllerAddress.onChangeFormInput(FormInputNameAddress.city)(
                  e.target.value
                )
              }
              error={
                controllerAddress.formErrors.city &&
                controllerAddress.formErrors.city[0]
              }
            />
          </div>
          <div className="mb-[20px]">
            <InputText
              name="district"
              label="Bairro"
              placeholder=""
              className={`appearance-none w-full
        rounded-md text-heading leading-tight focus:outline-none
        focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
              value={controllerAddress.formData[FormInputNameAddress.district]}
              onChange={(e) =>
                controllerAddress.onChangeFormInput(
                  FormInputNameAddress.district
                )(e.target.value)
              }
              error={
                controllerAddress.formErrors.district &&
                controllerAddress.formErrors.district[0]
              }
            />
          </div>
          <div className="mb-[20px] grid grid-cols-4 gap-5">
            <InputText
              name="street"
              label="Rua/Avenida"
              placeholder=""
              className={`appearance-none w-full
        rounded-md text-heading leading-tight focus:outline-none
        focus:shadow-outline text-xs font-dmsans font-normal col-span-3`}
              value={controllerAddress.formData[FormInputNameAddress.street]}
              onChange={(e) =>
                controllerAddress.onChangeFormInput(
                  FormInputNameAddress.street
                )(e.target.value)
              }
              error={
                controllerAddress.formErrors.street &&
                controllerAddress.formErrors.street[0]
              }
            />
            <InputText
              name="number"
              label="Número"
              placeholder=""
              className={`appearance-none w-full
        rounded-md text-heading leading-tight focus:outline-none
        focus:shadow-outline text-xs font-dmsans font-normal`}
              value={controllerAddress.formData[FormInputNameAddress.number]}
              onChange={(e) =>
                controllerAddress.onChangeFormInput(
                  FormInputNameAddress.number
                )(e.target.value)
              }
              error={
                controllerAddress.formErrors.number &&
                controllerAddress.formErrors.number[0]
              }
            />
          </div>
          <div className="mb-[20px]">
            <InputText
              name="complement"
              label="Complemento (opcional)"
              placeholder=""
              className={`appearance-none w-full
        rounded-md text-heading leading-tight focus:outline-none
        focus:shadow-outline text-xs font-dmsans font-normal`}
              value={
                controllerAddress.formData[FormInputNameAddress.complement]
              }
              onChange={(e) =>
                controllerAddress.onChangeFormInput(
                  FormInputNameAddress.complement
                )(e.target.value)
              }
              error={
                controllerAddress.formErrors.complement &&
                controllerAddress.formErrors.complement[0]
              }
            />
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};
