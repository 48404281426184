import { IconExclamation } from 'src/assets/icons';
import { Button } from 'src/components';

interface Props {
  onOkConfirm: () => void;
  messages: string[];
}

export const PaymentErroContent = ({ messages, onOkConfirm }: Props) => {
  return (
    <div className="w-full mx-auto text-center">
      <div className="flex flex-col justify-center">
        <IconExclamation
          height="89px"
          className="self-center text-[#E64F49] mb-[30px]"
        />
        <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
          Ops! Ocorreu um erro
        </h4>
        {messages.map((data, index) => (
          <p
            key={`${index}-MessageError`}
            className="font-dmsans text-base font-normal text-neutro-w-600 mb-[70px]"
          >
            {data}
          </p>
        ))}

        <div className="grid grid-cols-1 gap-5">
          <Button
            type="button"
            title="Tentar novamente"
            buttonStyle="primary"
            size="lg"
            className="w-full mb-[25px]"
            onClick={() => onOkConfirm()}
          />
        </div>
      </div>
    </div>
  );
};
