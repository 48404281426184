import { IconPix } from 'src/assets/icons';
import { Button } from 'src/components';

interface StateProps {
  qrCodeImage: string;
  qrCodeText: string;
}

interface DispatchProps {
  onCopyText: (text: string) => void;
}

type Props = StateProps & DispatchProps;

export const PixPaymentContent: React.FC<Props> = ({
  qrCodeImage,
  qrCodeText,
  onCopyText,
}): JSX.Element => {
  return (
    <div className="flex flex-col justify-center text-center font-dmsans">
      <IconPix
        height="78px"
        className="self-center text-alert-info-soft mb-[30px]"
      />
      <h2 className="font-dmsans font-bold text-neutro-b-400 text-[21px]">
        Pague via PIX para concluir a sua compra
      </h2>
      <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[20px]">
        Acesse o seu internet banking ou aplicativo de pagamento e escolha pagar
        via PIX Ler QR Code, em seguida aponte a sua câmera para finalizar o
        processo
      </p>
      <div className="text-center self-center">
        <img src={qrCodeImage} alt="qrcode" className="h-40" />
      </div>
      <div className="flex my-5 items-center justify-between">
        <hr className="block w-full h-px bg-label" />
        <span className="mx-2 text-neutro-b-400 font-dmsans">ou</span>
        <hr className="block w-full h-px bg-label" />
      </div>
      <h3 className="font-dmsans font-bold text-neutro-b-400 text-[21px]">
        Pague com o código PIX Copia e Cola
      </h3>
      <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[20px]">
        Acesse o seu internet banking ou aplicativo de pagamento e escolha pagar
        via PIX Copia e Cola, em seguida copie e cole o seguinte código de
        pagamento:
      </p>
      <p className="font-dmsans text-xs font-normal text-neutro-b-600 mb-[20px] break-all">
        {qrCodeText}
      </p>
      <div className="grid grid-cols-1 gap-5">
        <Button
          type="button"
          title="Copiar código PIX"
          buttonStyle="primary"
          size="xlg"
          className="w-full mb-[25px]"
          onClick={() => onCopyText(qrCodeText)}
        />
      </div>
    </div>
  );
};
