import { useState, useEffect } from 'react';
import { TicketsHistoryContainer } from './ui';
import { api } from 'src/services/api';
import Order from 'src/model/Order';
import Page from 'src/model/Page';
import {
  //ControllerTicketCancel,
  FormInputName,
  ShouldShowModalProps,
  ShowModalTickets,
  UseModalControl,
} from '../../types';
import { useDialog } from 'src/hooks/useDialog';
import useForm from 'src/hooks/useForm';
import validators from 'src/helpers/validators';
import { UseFormControl } from 'src/types';
import CancelOrderReason from 'src/model/CancelOrderReason';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';

export const TicketsHistoryScreen = () => {
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShowModalTickets>(
    ShowModalTickets.TICKET_CANCEL_AFTER
  );
  const [orders, setOrders] = useState<Page<Order, Order>>(
    {} as Page<Order, Order>
  );
  const [order, setOrder] = useState<Order>({} as Order);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleScroll = (): void => {
    const total = orders.total as number;
    if (orders.page * orders.pageSize < total) {
      getOrders(orders.page + 1, orders.pageSize, false);
    }
  };

  const handleOnShowDetailTicket = (item: Order): void => {
    history.push(`/ingressos/detalhe/${item.id}`);
  };

  const handleOnShowPrintTicket = (item: Order): void => {
    history.push(`/ingressos/imprimir/${item.id}`);
  };

  const handleOnShowTranferTicket = (item: Order): void => {
    history.push(`/ingressos/tranferir/${item.id}`);
  };

  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(title);
    onSetVisible(true);
  };

  const controllerModalTicket: UseModalControl = {
    visible: visible,
    title: title,
    shouldShowModal: shouldShowModal,
    onShouldShowModal: showModal,
    onToggleModal: onToggle,
    onSetVisible: onSetVisible,
  };

  /*
  const handleOnShowCancelTicketBefore = () => {
    showModal({
      value: ShowModalTickets.TICKET_CANCEL_BEFORE,
      title: '',
    });
  };

  const handleOnCancelCancelTicketBefore = () => {
    onSetVisible(false);
  };

  const handleOnSubmitCancelTicketBefore = async (): Promise<void> => {
    dispatch(setLoading(true));
    await api.patch(`/client/order/cancel/${order.id}`);
    await getOrders(1, 10, true);
    dispatch(setLoading(false));
    onSetVisible(false);
  };

  const controllerCancelTicketBefore: ControllerTicketCancel = {
    onShowTicketCancel: handleOnShowCancelTicketBefore,
    onCancelTicketCancel: handleOnCancelCancelTicketBefore,
    onSubmitTicketCancel: handleOnSubmitCancelTicketBefore,
  };
  */

  const {
    formData: formDataCancelTicket,
    formErrors: formErrorsCancelTicket,
    setErrors: setErrorsCancelTicket,
    onChangeFormInput: onChangeFormInputCancelTicket,
    isFormValid: isFormValidCancelTicket,
    resetForm: resetFormCancelTicket,
  } = useForm({
    initialData: {
      reason: '',
    },
    validators: {
      reason: [validators.required],
    },
    formatters: {},
  });

  const handleSubmitCancelTicket = async (): Promise<void> => {
    if (isFormValidCancelTicket()) {
      const cancelOrderReason: CancelOrderReason = {
        reason: formDataCancelTicket[FormInputName.reason],
      };
      dispatch(setLoading(true));
      await api.patch(
        `/client/order/cancel/${order.id}/reason`,
        cancelOrderReason
      );
      await getOrders(1, 10, true);
      dispatch(setLoading(false));
      onSetVisible(false);
    }
  };

  const controllerCancelTicketAfter: UseFormControl = {
    formData: formDataCancelTicket,
    formErrors: formErrorsCancelTicket,
    setErrors: setErrorsCancelTicket,
    onChangeFormInput: onChangeFormInputCancelTicket,
    isFormValid: isFormValidCancelTicket,
    resetForm: resetFormCancelTicket,
  };

  const getOrders = async (
    page: number,
    pageSize: number,
    clear: boolean
  ): Promise<void> => {
    dispatch(setLoading(true));
    const pageOrder: Page<Order, Order> = {
      page,
      pageSize,
      sort: 'orderDate',
      order: 'DESC',
    };
    const response = await api.post<Page<Order, Order>>(
      '/client/order',
      pageOrder
    );
    if (clear) {
      setOrders(response.data);
    } else {
      if (orders.total && orders.total > 0) {
        const list =
          orders.list && orders.list.length > 0 ? orders.list : ([] as Order[]);
        if (response.data.list && response.data.list.length > 0) {
          response.data.list.forEach((d) => {
            list.push(d);
          });
        }

        const newOrders = {
          ...orders,
          page,
          pageSize,
          total: response.data.total,
          list,
        };
        setOrders(newOrders);
      }
    }
    dispatch(setLoading(false));
  };

  const handleOnShowCancelTicket = (item: Order): void => {
    setOrder(item);
    /*
    const date = dayjs(item.orderDate).add(7, 'day').toDate();
    const now = new Date();
    if (date > now) {
      showModal({
        value: ShowModalTickets.TICKET_CANCEL_BEFORE,
        title: '',
      });
    } else {
      */
    showModal({
      value: ShowModalTickets.TICKET_CANCEL_AFTER,
      title: '',
    });
    /*
    }
    */
  };

  useEffect(() => {
    getOrders(1, 10, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TicketsHistoryContainer
      state={loading}
      orders={orders}
      controllerModalTicket={controllerModalTicket}
      //controllerCancelTicketBefore={controllerCancelTicketBefore}
      controllerCancelTicketAfter={controllerCancelTicketAfter}
      order={order}
      onScroll={handleScroll}
      onShowDetailTicket={handleOnShowDetailTicket}
      onShowPrintTicket={handleOnShowPrintTicket}
      onShowTransferTicket={handleOnShowTranferTicket}
      onShowCancelTicket={handleOnShowCancelTicket}
      onSubmitCancelTicket={handleSubmitCancelTicket}
    />
  );
};
