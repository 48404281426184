import {
  FlagBrazil,
  FlagEua,
  FlagSpanish,
  IconFacebook2,
  IconInstagram,
} from 'src/assets/icons';
import { NavigationMenu } from 'src/types';

interface FooterProps {
  handleOnSignIn: () => void;
  handleOnSignUp: () => void;
  goTo: (href: string) => void;
}

export const Footer = ({
  handleOnSignIn,
  handleOnSignUp,
  goTo,
}: FooterProps) => {
  const navigation: NavigationMenu[] = [
    { name: 'Início', href: '/', current: true },
    { name: 'Quem somos', href: '/quemsomos', current: false },
    {
      name: 'Contato e suporte',
      href: '/contato-suporte',
      current: false,
    },
    { name: 'Políticas', href: '/politicas', current: false },
  ];

  return (
    <div className="w-full py-10 md:h-[319px] shadow-xsr text-[12px] md:text-base">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 h-full">
        <div className="col-span-1 md:col-span-2 text-left">
          <p className="text-paragraph-footer font-normal font-dmsans">
            2023 Banca do Ingresso - Todos os direitos reservados.
          </p>
          <div className="grid grid-cols-[2fr_1fr_1fr] md:grid-cols-3 mt-7">
            <div className="flex flex-col order-2 mr-5 md:order-1 md:mr-0">
              <h5 className="text-neutro-b-500 mb-2 font-medium font-dmsans leading-[21px]">
                Pagamento
              </h5>
              <nav className="flex flex-col space-y-1">
                <a
                  target={'_blank'}
                  rel="noreferrer"
                  href="https://pagseguro.uol.com.br/"
                  className="text-paragraph-footer font-normal font-dmsans"
                >
                  PagSeguro
                </a>
              </nav>
            </div>
            <div className="flex flex-col justify-start order-3 md:order-2">
              <h5 className="text-neutro-b-500 mb-2 font-medium font-dmsans leading-[21px]">
                Minha conta
              </h5>
              <nav className="flex flex-col space-y-1">
                <a
                  href="#"
                  onClick={handleOnSignUp}
                  className="text-paragraph-footer font-normal font-dmsans"
                >
                  Criar conta
                </a>
                <a
                  href="#"
                  onClick={handleOnSignIn}
                  className="text-paragraph-footer font-normal font-dmsans"
                >
                  Entrar
                </a>
              </nav>
            </div>
            <div className="flex flex-col order-1 md:order-3">
              <h5 className="text-neutro-b-500 mb-2 font-medium font-dmsans leading-[21px]">
                Site
              </h5>
              <nav className="flex flex-col space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href="#"
                    className="text-paragraph-footer font-normal font-dmsans"
                    onClick={() => goTo(item.href)}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col items-start md:items-center">
          <p className="text-heading-footer font-normal font-dmsans">
            Nos siga nas redes sociais
          </p>
          <div className="flex flex-row md:flex-col items-center justify-between w-full md:w-auto md:h-full">
            <div className="flex justify-center space-x-5 md:space-x-10 mt-5 flex-1">
              <a
                href="#"
                className="bg-social rounded-full flex justify-center items-center w-[25px] md:w-[36px] h-[25px] md:h-[36px]"
                onClick={() =>
                  window.open('https://www.instagram.com/bancadoingresso/')
                }
              >
                <IconInstagram className="h-[13.89px] md:h-[20px] text-primary-500" />
              </a>
              <a
                href="#"
                className="bg-social rounded-full flex justify-center items-center w-[25px] md:w-[36px] h-[25px] md:h-[36px]"
                onClick={() =>
                  window.open('https://www.facebook.com/bancadoingresso.com.br')
                }
              >
                <IconFacebook2 className="h-[16.67px] md:h-[24px] text-primary-500" />
              </a>
            </div>
            <div className="flex justify-between space-x-3 md:space-x-5 mt-5 md:mt-0">
              <a href="#" className="p-2 bg-white rounded-full justify-between">
                <img src={FlagBrazil} alt="Português Brasil" />
              </a>
              <a href="#" className="p-2 bg-white rounded-full justify-between">
                <img src={FlagSpanish} alt="Espanhol" />
              </a>
              <a href="#" className="p-2 bg-white rounded-full justify-between">
                <img src={FlagEua} alt="Inglês americano" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
