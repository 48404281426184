import { useEffect, useState } from 'react';
import { TicketsContainer } from './ui';
import {
  //ControllerTicketCancel,
  FormInputName,
  ShouldShowModalProps,
  ShowModalTickets,
  UseModalControl,
} from '../../types';
import { useDialog } from 'src/hooks/useDialog';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { UseFormControl } from 'src/types';
import Order from 'src/model/Order';
import Page from 'src/model/Page';
import { api } from 'src/services/api';
import CancelOrderReason from 'src/model/CancelOrderReason';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { updateMask as updateMaskDate } from 'src/helpers/masks/generalDate';
import { useSidebar } from 'src/hooks/useSidebar';
import { SidebarProps } from 'src/components/Sidebar';
import { TutorialApp } from '../../components/TutorialApp';

export const TicketsScreen = () => {
  const { onShowSidebar } = useSidebar();
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShowModalTickets>(
    ShowModalTickets.TICKET_CANCEL_AFTER
  );
  const [orders, setOrders] = useState<Page<Order, Order>>(
    {} as Page<Order, Order>
  );
  const [order, setOrder] = useState<Order>({} as Order);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [ordersTemp, setOrdersTemp] = useState<Page<Order, Order>>(
    {} as Page<Order, Order>
  );

  const showModal = ({ value, title }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(title);
    onSetVisible(true);
  };

  const controllerModalTicket: UseModalControl = {
    visible: visible,
    title: title,
    shouldShowModal: shouldShowModal,
    onShouldShowModal: showModal,
    onToggleModal: onToggle,
    onSetVisible: onSetVisible,
  };

  const handleTutotialApp = () => {
    onShowSidebar({
      content: <TutorialApp />,
      title: 'Visualizar ingresso',
    } as SidebarProps);
  };

  /*
  const handleOnShowCancelTicketBefore = () => {
    showModal({
      value: ShowModalTickets.TICKET_CANCEL_BEFORE,
      title: '',
    });
  };


  const handleOnCancelCancelTicketBefore = () => {
    onSetVisible(false);
  };

  const handleOnSubmitCancelTicketBefore = async (): Promise<void> => {
    dispatch(setLoading(true));
    await api.patch(`/client/order/cancel/${order.id}`);
    await getOrders();
    dispatch(setLoading(false));
    onSetVisible(false);
  };

  const controllerCancelTicketBefore: ControllerTicketCancel = {
    onShowTicketCancel: handleOnShowCancelTicketBefore,
    onCancelTicketCancel: handleOnCancelCancelTicketBefore,
    onSubmitTicketCancel: handleOnSubmitCancelTicketBefore,
  };
  */

  const {
    formData: formDataCancelTicket,
    formErrors: formErrorsCancelTicket,
    setErrors: setErrorsCancelTicket,
    onChangeFormInput: onChangeFormInputCancelTicket,
    isFormValid: isFormValidCancelTicket,
    resetForm: resetFormCancelTicket,
  } = useForm({
    initialData: {
      reason: '',
    },
    validators: {
      reason: [validators.required],
    },
    formatters: {},
  });

  const handleSubmitCancelTicket = async (): Promise<void> => {
    if (isFormValidCancelTicket()) {
      const cancelOrderReason: CancelOrderReason = {
        reason: formDataCancelTicket[FormInputName.reason],
      };
      dispatch(setLoading(true));
      await api.patch(
        `/client/order/cancel/${order.id}/reason`,
        cancelOrderReason
      );
      await getOrders();
      dispatch(setLoading(false));
      onSetVisible(false);
    }
  };

  const controllerCancelTicketAfter: UseFormControl = {
    formData: formDataCancelTicket,
    formErrors: formErrorsCancelTicket,
    setErrors: setErrorsCancelTicket,
    onChangeFormInput: onChangeFormInputCancelTicket,
    isFormValid: isFormValidCancelTicket,
    resetForm: resetFormCancelTicket,
  };

  const getOrders = async (): Promise<void> => {
    dispatch(setLoading(true));
    const pageOrder: Page<Order, Order> = {
      page: 1,
      pageSize: 10,
      sort: 'orderDate',
      order: 'DESC',
    };
    const response = await api.post<Page<Order, Order>>(
      '/client/order',
      pageOrder
    );
    setOrders(response.data);
    setOrdersTemp(response.data);
    dispatch(setLoading(false));
  };

  const handleOnShowCancelTicket = (item: Order): void => {
    setOrder(item);
    /*
    const date = dayjs(item.orderDate).add(7, 'day').toDate();
    const now = new Date();
    if (date > now) {
      showModal({
        value: ShowModalTickets.TICKET_CANCEL_BEFORE,
        title: '',
      });
    } else {
      */
    showModal({
      value: ShowModalTickets.TICKET_CANCEL_AFTER,
      title: '',
    });
    /*
    }
    */
  };

  const handleOnShowDetailTicket = (item: Order): void => {
    history.push(`/ingressos/detalhe/${item.id}`);
  };

  const handleOnShowPrintTicket = (item: Order): void => {
    history.push(`/ingressos/imprimir/${item.id}`);
  };

  const handleOnShowTranferTicket = (item: Order): void => {
    history.push(`/ingressos/tranferir/${item.id}`);
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    setErrors: setErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      name: '',
      date: '',
    },
    validators: {
      name: [],
      date: [validators.maxLength(10)],
    },
    formatters: {
      date: updateMaskDate,
    },
  });

  const handleOnSubmitFilter = () => {
    try {
      if (isFormValidFilter()) {
        let ordersFilter: Order[] | undefined = [];
        ordersFilter =
          orders.list &&
          orders.list.filter((filter) => {
            if (
              controllerFilter.formData.date &&
              controllerFilter.formData.date ===
                dayjs(filter.orderDate).locale('pt-br').format('DD/MM/YYYY')
            ) {
              return filter;
            }

            if (
              controllerFilter.formData.name &&
              filter.events[0].event.name
                .toLocaleLowerCase()
                .includes(controllerFilter.formData.name.toLocaleLowerCase())
            ) {
              return filter;
            }
          });
        setOrders({ ...orders, list: ordersFilter });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClearFilter = () => {
    resetFormFilter();
    setOrders(ordersTemp);
  };

  const controllerFilter: UseFormControl = {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    setErrors: setErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  };

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TicketsContainer
      state={loading}
      orders={orders}
      controllerModalTicket={controllerModalTicket}
      //controllerCancelTicketBefore={controllerCancelTicketBefore}
      controllerCancelTicketAfter={controllerCancelTicketAfter}
      order={order}
      onShowCancelTicket={handleOnShowCancelTicket}
      onSubmitCancelTicket={handleSubmitCancelTicket}
      onShowDetailTicket={handleOnShowDetailTicket}
      onShowPrintTicket={handleOnShowPrintTicket}
      onShowTransferTicket={handleOnShowTranferTicket}
      onPressTutotialApp={handleTutotialApp}
      controllerFilter={controllerFilter}
      onSubmitFilter={handleOnSubmitFilter}
      onClearFilter={handleOnClearFilter}
    />
  );
};
