import dayjs from 'dayjs';
import { useState } from 'react';
import Flickity from 'react-flickity-component';
import { classNames } from 'src/helpers/common';
import EventSiteHeader from 'src/model/EventSiteHeader';

interface StateProps {
  eventDates: EventSiteHeader[];
}
interface DispatchProps {
  handleOnClick: (eventDate: EventSiteHeader) => void;
}

interface TicketProps {
  item: EventSiteHeader;
  index: number;
  handleOnClick: (item: EventSiteHeader) => void;
  tabActive: number;
  setTabActive: (value: number) => void;
}

type Props = StateProps & DispatchProps;

const TicketPassport = ({
  item,
  index,
  handleOnClick,
  tabActive,
  setTabActive,
}: TicketProps) => (
  <a
    key={item.id}
    onClick={() => {
      handleOnClick(item);
      setTabActive(index + 1);
    }}
    className={classNames(
      tabActive === index + 1
        ? 'bg-[#60B9CD] text-white font-bold border-alert-info-soft'
        : 'bg-neutro-w-100 text-[#828282] font-normal border-neutro-w-100',
      'carousel-cell w-[135px] mr-5 cursor-pointer p-2 flex flex-col justify-center items-center border hover:border-alert-info-soft'
    )}
  >
    {item.count > 0 && (
      <div
        className={classNames(
          tabActive === index + 1
            ? 'bg-neutro-w-000 text-alert-info-soft'
            : 'bg-neutro-w-400 text-neutro-w-000',
          'w-[14px] h-[14px] rounded-full font-dmsans font-normal text-[10px] flex justify-center items-center absolute top-[5px] right-[5px]'
        )}
      >
        {item.count}
      </div>
    )}
    {item.passportName && item.passportName.trim().length > 0 ? (
      <span className="text-center">{item.passportName}</span>
    ) : (
      <>{item.name && <span className="text-center">{item.name}</span>}</>
    )}

    <span
      className={classNames(
        tabActive === index + 1 ? 'text-white' : 'text-[#C4C4C4]',
        'font-normal text-center text-xs'
      )}
    >
      {`${dayjs(tzDate(item.startDate)).format('DD/MM')} até ${dayjs(
        tzDate(item.endDate)
      ).format('DD/MM')}`}
    </span>
  </a>
);

const tzDate = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 3);
  return newDate;
};

const TicketUnit = ({
  item,
  index,
  handleOnClick,
  tabActive,
  setTabActive,
}: TicketProps) => {
  return (
    <a
      key={item.id}
      onClick={() => {
        handleOnClick(item);
        setTabActive(index + 1);
      }}
      className={classNames(
        tabActive === index + 1
          ? 'bg-[#60B9CD] text-white font-bold border-alert-info-soft'
          : 'bg-[#F1F1F1] text-[#828282] font-normal border-neutro-w-100',
        'relative carousel-cell w-[59px] mr-5 cursor-pointer p-[14px] flex flex-col justify-center items-center border hover:border-alert-info-soft'
      )}
    >
      {item.count > 0 && (
        <div
          className={classNames(
            tabActive === index + 1
              ? 'bg-neutro-w-000 text-alert-info-soft'
              : 'bg-neutro-w-400 text-neutro-w-000',
            'w-[14px] h-[14px] rounded-full font-dmsans font-normal text-[10px] flex justify-center items-center absolute top-[5px] right-[5px]'
          )}
        >
          {item.count}
        </div>
      )}

      <div className="m-0 font-dmsans leading-[21px]">{`${dayjs(
        tzDate(item.startDate)
      ).format('DD')}`}</div>
      <div className="m-0 font-dmsans leading-[21px] uppercase">{`${dayjs(
        tzDate(item.startDate)
      )
        .locale('pt-br')
        .format('MMM')}`}</div>
      <div className="font-dmsans font-normal text-xs">{`${dayjs(
        tzDate(item.startDate)
      ).format('HH:mm')}`}</div>
      <div className="font-dmsans font-normal text-xs">hrs</div>
    </a>
  );
};

const SliderDate: React.FC<Props> = (props: Props): JSX.Element => {
  const [tabActive, setTabActive] = useState<number>(0);
  const flickityOptions = {
    freeScroll: true,
    percentPosition: true,
    cellAlign: 'left',
  };
  return (
    <Flickity
      options={flickityOptions}
      className={'slider-ticket-product pl-[60px] mt-6'}
    >
      {props.eventDates.map((data, index) =>
        data.isPassport ? (
          <TicketPassport
            key={index}
            item={data}
            index={index}
            handleOnClick={props.handleOnClick}
            tabActive={tabActive}
            setTabActive={setTabActive}
          />
        ) : (
          <TicketUnit
            key={index}
            item={data}
            index={index}
            handleOnClick={props.handleOnClick}
            tabActive={props.eventDates.length === 1 ? 1 : tabActive}
            setTabActive={setTabActive}
          />
        )
      )}
    </Flickity>
  );
};

export default SliderDate;
